/**
 * По сути просто повторение логики useBank, так что изменения лучше синхронизировать
 */

import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { BankBranchType, useBranchesLazyQuery } from 'store/graphql';
import { FormInputSelectOption } from 'common/components/inputs';
import { useDebounce, useEffectOnce } from 'react-use';
import { useIntl } from 'react-intl';
import { INPUT_DELAY } from '../constants';
import { handleDefaultError } from '../../../../common/utils/handleDefaultError';

export interface UseBranchProps {
  /**
   * Значение отделения по умолчанию
   */
  initialBranch?: BankBranchType;
  /**
   * Название отделения, выбранное в форме из доступных опций для поиска объекта отделения
   */
  branchName?: string;
  /**
   * Код отделения, выбранное в форме из доступных опций для поиска объекта отделения
   */
  branchCode?: string;
  /**
   * Название отделения (хиригана), выбранное в форме из доступных опций для поиска объекта отделения
   */
  branchNameHiragana?: string;
  /**
   * Код банка, выбранное в форме из доступных опций для поиска отделений
   */
  bankCode?: string;
}

export const useBranch = (props: UseBranchProps = {}) => {
  const { initialBranch, branchName, branchCode, branchNameHiragana, bankCode } = props;
  const intl = useIntl();

  const [branchNameInput, setBranchNameInput] = useState('');
  const [branchCodeInput, setBranchCodeInput] = useState('');
  const [branchNameHiraganaInput, setBranchNameHiraganaInput] = useState('');

  const [getBranches, { data: branchesData, loading: branchesLoading, error: branchFetchError }] = useBranchesLazyQuery(
    {
      nextFetchPolicy: 'cache-first',
      onError: (e) => {
        handleDefaultError(intl.formatMessage({ id: 'withdraw.fetchError' }), e);
      }
    }
  );

  const [searchError, setSearchError] = useState(false);

  /**
   * Состояние "измененности" инпутов для отображения лоадера от момента изменения до вызова запроса
   */
  const [inputChanged, setInputChanged] = useState(false);

  useEffect(() => {
    if (branchNameInput || branchCodeInput || branchNameHiraganaInput) {
      setInputChanged(true);
    }
  }, [branchNameInput, branchCodeInput, branchNameHiraganaInput]);

  /**
   * Запрашиваем первый раз при передаче заполненного отделения
   */
  useEffectOnce(() => {
    if (initialBranch && bankCode) {
      getBranches({
        variables: {
          input: {
            branch_name: initialBranch.branch_name || undefined,
            branch_code: initialBranch.branch_code || undefined,
            branch_hiragana: initialBranch.branch_hiragana || undefined,
            organization_code: bankCode
          }
        }
      });
    }
  });

  useDebounce(
    async () => {
      setInputChanged(false);

      if (bankCode && (branchNameInput || branchCodeInput || branchNameHiraganaInput) && !branch) {
        try {
          setSearchError(false);

          const branchData = await getBranches({
            variables: {
              input: {
                branch_name: branchNameInput || undefined,
                branch_code: branchCodeInput || undefined,
                branch_hiragana: branchNameHiraganaInput || undefined,
                organization_code: bankCode
              }
            }
          });

          if (branchData.data?.branches.bankBranches && branchData.data.branches.bankBranches.length === 0) {
            setSearchError(true);
          }
        } catch (e) {
          // todo все равно нет нормальной обработки ошибок
        }
      }
    },
    INPUT_DELAY,
    [branchNameInput, branchCodeInput, branchNameHiraganaInput, setInputChanged, setSearchError]
  );

  const isBranchInput = !!(branchNameInput || branchCodeInput || branchNameHiraganaInput);

  const branches = useMemo(() => branchesData?.branches.bankBranches || [], [branchesData?.branches]);

  const branchNameOptions: FormInputSelectOption[] = useMemo(
    () =>
      isBranchInput
        ? branches?.map((branch) => ({
            title: branch.branch_name,
            value: branch.branch_name
          }))
        : initialBranch
        ? [
            {
              title: initialBranch.branch_name,
              value: initialBranch.branch_name
            }
          ]
        : [],
    [branches, initialBranch, isBranchInput]
  );

  const branchCodeOptions: FormInputSelectOption[] = useMemo(
    () =>
      isBranchInput
        ? branches?.map((branch) => ({
            title: branch.branch_code,
            value: branch.branch_code
          }))
        : initialBranch
        ? [
            {
              title: initialBranch.branch_code,
              value: initialBranch.branch_code
            }
          ]
        : [],
    [branches, initialBranch, isBranchInput]
  );

  const branchNameHiraganaOptions: FormInputSelectOption[] = useMemo(
    () =>
      isBranchInput
        ? branches?.map((branch) => ({
            title: branch.branch_hiragana,
            value: branch.branch_hiragana
          }))
        : initialBranch
        ? [
            {
              title: initialBranch.branch_hiragana,
              value: initialBranch.branch_hiragana
            }
          ]
        : [],
    [branches, initialBranch, isBranchInput]
  );

  useLayoutEffect(() => {
    if (initialBranch) {
      setBranchNameInput(initialBranch.branch_name);
      setBranchCodeInput(initialBranch.branch_code);
      setBranchNameHiraganaInput(initialBranch.branch_hiragana);
    }
  }, [initialBranch]);

  const branch = useMemo(
    () =>
      (branchName && branches.find((i) => i.branch_name === branchName)) ||
      (branchCode && branches.find((i) => i.branch_code === branchCode)) ||
      (branchNameHiragana && branches.find((i) => i.branch_hiragana === branchNameHiragana)) ||
      undefined,
    [branchCode, branchName, branchNameHiragana, branches]
  );

  return {
    branchNameInput,
    setBranchNameInput,
    branchCodeInput,
    setBranchCodeInput,
    branchNameHiraganaInput,
    setBranchNameHiraganaInput,
    branchNameOptions,
    branchCodeOptions,
    branchNameHiraganaOptions,
    branch,
    branchesLoading: !branch ? branchesLoading || inputChanged : false,
    branchSearchError: searchError,
    branchFetchError
  };
};
