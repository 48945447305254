import { InferType, object, string } from 'yup';
import { validateErrors, validateNickname } from '../../../../common/utils/validate';
import { maxNameLength } from './constants';

const validateObject = {
  name: string().max(maxNameLength, 'Must be maximum 500 characters long').defined(validateErrors.required),
  secondName: string().max(maxNameLength, 'Must be maximum 500 characters long').defined(validateErrors.required),
  nickname: string()
    .max(maxNameLength, 'Must be maximum 500 characters long')
    .required(validateErrors.required)
    .test('is-nickname', validateErrors.wrongNickname, (value) => validateNickname(value || ''))
    .defined(validateErrors.required),
  password: string()
    .optional()
    .min(8, 'Password must be at least 8 characters long')
    .max(20, 'Password cannot be longer than 20 characters')
    .matches(/(?=.*[A-Z])/, 'Password must contain at least one uppercase letter')
    .matches(/(?=.*[a-z])/, 'Password must contain at least one lowercase letter')
    .matches(/(?=.*\d)/, 'Password must contain at least one number')
    .matches(/(?=.*[@$!%*?&])/, 'Password must contain at least one special character')
};

export const UserDataModalSchema = object(validateObject);

export type TUserDataModalValues = InferType<typeof UserDataModalSchema>;

export const UserDataModalKeys = Object.fromEntries(Object.keys(validateObject).map((key) => [key, key])) as Record<
  keyof TUserDataModalValues,
  string
>;
