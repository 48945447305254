import React, { useState } from 'react';

import clsx from 'clsx';

import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { BsEmojiFrown, BsEmojiNeutral, BsEmojiSmile } from 'react-icons/bs';

import { FormattedMessage } from 'react-intl';
import { ReactFCC } from '../../../../common/utils/helperTypes';
import { isKeyDownEnter } from '../../../../common/utils/isKeyDownEnter';
import { useToggle } from '../../../../common/hooks/useToggle';
import { AnchorVariant } from '../../../../common/components/Anchor';
import { RatingValueEnum, ReviewsUserRoleEnum } from '../../../../store/graphql';
import { Link } from '../../../../common/components/Link';
import { PathBuilder } from '../../../../common/utils/routes';
import { Badge } from '../../../../common/components/Badge';
import { useTimeAgo } from '../../../../common/hooks/useTimeAgo';
import s from './UserReview.module.scss';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export type TUserReview = {
  className?: string;
  role: ReviewsUserRoleEnum;
  isOwnReview?: boolean;
  comment: {
    authorId: number;
    author: string;
    date: Date;
    text: string;
    value: RatingValueEnum;
  };
  orderPath?: string;
};

export const UserReview: ReactFCC<TUserReview> = (props) => {
  const { className, comment, role, isOwnReview } = props;
  const { timeAgo } = useTimeAgo();

  const [commentOverflow, setCommentOverflow] = useState(false);
  const [isExpanded, { toggle }] = useToggle(false);

  return (
    <div className={clsx(s.UserReview, className)}>
      {comment.author && !isOwnReview ? (
        <Link
          to={PathBuilder.getPublicPath(comment.authorId, role === ReviewsUserRoleEnum.Seller)}
          className={s.UserReview__name}
          variant={AnchorVariant.BODY}
        >
          {comment.author}
        </Link>
      ) : (
        <span className={s.UserReview__name}>{comment.author}</span>
      )}

      <span className={s.UserReview__date}>{timeAgo(comment.date)}</span>
      <Badge className={s.UserReview__badge}>
        <span>
          {role === ReviewsUserRoleEnum.Customer ? (
            isOwnReview ? (
              <FormattedMessage id={'reviews.toCustomer'} />
            ) : (
              <FormattedMessage id={'reviews.customer'} />
            )
          ) : isOwnReview ? (
            <FormattedMessage id={'reviews.toSeller'} />
          ) : (
            <FormattedMessage id={'reviews.seller'} />
          )}
        </span>
      </Badge>

      <div className={clsx(s.UserReview__rating, s[`UserReview__rating_${comment.value.toLowerCase()}`])}>
        {comment.value === RatingValueEnum.Positive ? (
          <>
            <BsEmojiSmile /> <FormattedMessage id={'reviews.positive'} />
          </>
        ) : comment.value === RatingValueEnum.Neutral ? (
          <>
            <BsEmojiNeutral /> <FormattedMessage id={'reviews.neutral'} />
          </>
        ) : (
          <>
            <BsEmojiFrown /> <FormattedMessage id={'reviews.negative'} />
          </>
        )}
      </div>

      <ResponsiveEllipsis
        className={s.UserReview__text}
        text={comment.text}
        maxLine={!isExpanded ? 3 : 1000}
        ellipsis={'...'}
        basedOn={'words'}
        onReflow={({ clamped }) => setCommentOverflow(clamped)}
      />

      {(commentOverflow || isExpanded) && (
        <div
          className={s.UserReview__button}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            toggle();
          }}
          onKeyDownCapture={(e: React.KeyboardEvent) => {
            e.preventDefault();
            if (isKeyDownEnter(e)) {
              toggle();
            }
          }}
        >
          {isExpanded ? <FormattedMessage id={'reviews.showLess'} /> : <FormattedMessage id={'reviews.showMore'} />}
        </div>
      )}
    </div>
  );
};
