export enum UploadStage {
  idle = 'idle',
  preparing = 'preparing',
  mutating = 'mutating',
  fetching = 'fetching'
}

export enum ArisoraBuckets {
  Shipments = 'arisora-shipments',
  Sellers = 'arisora-sellers',
  Orders = 'arisora-orders'
}
