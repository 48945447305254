import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useUrlParam } from '../../../common/hooks/useUrlParam';
import { TICKET_PAGE_PARAM, TICKETS_ROUTE } from '../../../common/utils/routes';
import { useTicketQuery } from '../../../store/graphql';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import { Head } from '../../../common/components/Head';
import { LinkBack } from '../../../common/components/LinkBack';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { TicketChat } from './components/TicketChat/TicketChat';
import { TicketChatSkeletonSkeleton } from './components/TicketChatSkeleton/TicketChatSkeleton';
import s from './TicketPage.module.scss';

export const TicketPage = () => {
  const ticketId = useUrlParam(TICKET_PAGE_PARAM);
  const intl = useIntl();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const { data: ticketQuery, loading: ticketLoading } = useTicketQuery({
    skip: !ticketId,
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        ticketId: ticketId ? +ticketId : 0
      }
    }
  });

  const ticket = useMemo(() => {
    return ticketQuery?.result;
  }, [ticketQuery]);

  return (
    <div className={s.TicketPage}>
      {!isMobile && (
        <>
          <Head title={intl.formatMessage({ id: 'profile.tickets' })} />
          <LinkBack onClick={() => navigate(TICKETS_ROUTE)}>Back</LinkBack>
          <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />
        </>
      )}
      {ticketLoading ? (
        <TicketChatSkeletonSkeleton />
      ) : (
        <>
          {ticket && (
            <TicketChat
              initialMessages={ticket.messages || []}
              status={ticket.status}
              topic={ticket.topic}
              ticketId={ticket.ticketId}
              ticketName={ticket.name}
            />
          )}
        </>
      )}
    </div>
  );
};
