import React, { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useUpdateEffect } from 'react-use';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { Modal } from '../../../../common/components/Modal';
import { FormInput } from '../../../../common/components/inputs';
import { ESpaceSize, Space } from '../../../../common/components/Space/Space';
import { ReactFCC } from '../../../../common/utils/helperTypes';
import { getGraphqlErrorMessage } from '../../../../common/utils/graphqlErrors';
import { useChangeUserPasswordMutation, useUserHasPasswordQuery } from '../../../../store/graphql';
import { LoaderBox } from '../../../../common/components/Loader';
import { maxPasswordLength } from '../UserDataModal';
import { Heading, HeadingSize } from '../../../../common/components/Heading';
import { handleDefaultError } from '../../../../common/utils/handleDefaultError';
import { ChangePasswordKeys, ChangePasswordSchema, TChangePasswordValues } from './changePasswordSchema';
import s from './ChangePasswordModal.module.scss';

export interface TUserDataModal {
  isOpen: boolean;
  onClose: () => void;
}

export const ChangePasswordModal: ReactFCC<TUserDataModal> = (props) => {
  const { isOpen, onClose } = props;

  const intl = useIntl();

  const [error, setError] = useState<string | null>(null);
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [passwordConfirmationError, setPasswordConfirmationError] = useState<string>('');

  const {
    data: hasPasswordQuery,
    loading: hasPasswordLoading,
    error: hasPasswordError
  } = useUserHasPasswordQuery({
    skip: !isOpen,
    fetchPolicy: 'network-only'
  });

  const [changeUserPassword, { loading: changeUserPasswordLoading }] = useChangeUserPasswordMutation();

  const userHasPassword = useMemo(() => hasPasswordQuery?.result, [hasPasswordQuery]);

  const validationSchema = useMemo(() => ChangePasswordSchema(intl), [intl]);

  const formik = useFormik<TChangePasswordValues>({
    initialValues: {
      currentPassword: '',
      newPassword: ''
    },
    validationSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, helpers) => {
      if (userHasPassword && !values.currentPassword) {
        return;
      }

      setError(null);
      setPasswordConfirmationError('');

      if (values.newPassword && values.newPassword !== passwordConfirmation) {
        setPasswordConfirmationError(intl.formatMessage({ id: 'validation.passwordMatch' }));
        return;
      }

      try {
        const { data: changePasswordResult } = await changeUserPassword({
          variables: {
            input: {
              currentPassword: values.currentPassword ? values.currentPassword : undefined,
              newPassword: values.newPassword
            }
          }
        });

        if (!changePasswordResult?.result) {
          helpers.setErrors({ currentPassword: 'Wrong password' });
          return;
        }

        onClose();
        helpers.setErrors({});
        helpers.resetForm();

        toast.success('Password has been changed');
      } catch (e: any) {
        const errorText = getGraphqlErrorMessage(e);
        if (errorText) {
          handleDefaultError('Something went wrong');
        }
        console.error(e);
      }
    }
  });

  useUpdateEffect(() => {
    formik.setErrors({});
    formik.resetForm();
  }, [isOpen]);

  return (
    <Modal
      title={intl.formatMessage({ id: 'profile.changePassword' })}
      className={s.FillUserData}
      isOpen={isOpen}
      onClose={onClose}
    >
      {hasPasswordLoading ? (
        <>
          <Space size={ESpaceSize.PIXEL_16} />
          <LoaderBox />
          <Space size={ESpaceSize.PIXEL_16} />
        </>
      ) : hasPasswordError ? (
        <Heading size={HeadingSize.H3}>Try again later</Heading>
      ) : (
        <>
          <Modal.Body>
            {userHasPassword && (
              <FormInput
                type="password"
                name={ChangePasswordKeys.currentPassword}
                label={intl.formatMessage({ id: 'auth.previousPassword' })}
                placeholder={intl.formatMessage({ id: 'auth.previousPassword' })}
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                error={formik.errors.currentPassword}
                space={{ bottom: ESpaceSize.PIXEL_24 }}
                required
                maxLength={maxPasswordLength}
              />
            )}
            <FormInput
              type="password"
              name={ChangePasswordKeys.newPassword}
              label={intl.formatMessage({ id: 'auth.password' })}
              placeholder={intl.formatMessage({ id: 'auth.password' })}
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={formik.errors.newPassword}
              space={{ bottom: ESpaceSize.PIXEL_24 }}
              required
              maxLength={maxPasswordLength}
            />
            <FormInput
              type="password"
              label={intl.formatMessage({ id: 'auth.passwordConfirmation' })}
              placeholder={intl.formatMessage({ id: 'auth.passwordConfirmation' })}
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              space={{ bottom: ESpaceSize.PIXEL_24 }}
              error={passwordConfirmationError}
              required
              maxLength={maxPasswordLength}
            />
          </Modal.Body>
          <Modal.Footer className={s.ChangePasswordModal__footer}>
            <Modal.Button
              onClick={formik.submitForm}
              loading={changeUserPasswordLoading}
              disabled={!passwordConfirmation}
            >
              {intl.formatMessage({ id: 'auth.editSubmitButton' })}
            </Modal.Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};
