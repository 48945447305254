import { boolean, InferType, number, object } from 'yup';
import { validateErrors } from 'common/utils/validate';

const validateObject = {
  // country: string().defined(validateErrors.required),
  // city: string().defined(validateErrors.required),
  // postalIndex: string().defined(validateErrors.required),
  // province: string(),
  // address: string().defined(validateErrors.required),
  shippingAddressId: number().defined(validateErrors.required),
  billingAddressId: number().defined(validateErrors.required),
  hasPaymentMethod: boolean().defined(validateErrors.required),
  hasDeliveryData: boolean()
};

export const OrderingSchema = object(validateObject);

export type TOrderingValues = InferType<typeof OrderingSchema>;

export const OrderingKeys = Object.fromEntries(Object.keys(validateObject).map((key) => [key, key])) as Record<
  keyof TOrderingValues,
  string
>;
