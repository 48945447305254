import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useReconfirmIntentMutation, useUpsertPaymentIntentMutation } from '../graphql';
import { handleDefaultError } from '../../common/utils/handleDefaultError';
import { getGraphqlErrorMessage } from '../../common/utils/graphqlErrors';
import { useUser } from '../../entities/user';
import { clearCache } from '../../app/providers/auth-apollo';
import { PaymentContext } from './PaymentContext';
import { usePaymentSession } from './usePaymentSession';

export const PaymentProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { user } = useUser();
  const paymentCardLastNumbers = user?.last4numbers || '';
  const paymentSession = usePaymentSession();
  const intl = useIntl();

  const [paymentIntentRun, { loading: paymentIntentLoading }] = useUpsertPaymentIntentMutation();
  const [reconfirmRun, { loading: reconfirmLoading }] = useReconfirmIntentMutation({
    refetchQueries: ['CustomerOrders', 'OrderContent'],
    update: clearCache(['getCustomerOrders', 'user'])
  });

  const intentPayment = useCallback(
    async (debtId?: string): Promise<string | null> => {
      try {
        const result = await paymentIntentRun({ variables: { debtId } });
        const paymentIntent = result.data?.paymentIntent || null;

        if (!paymentIntent) {
          handleDefaultError(intl.formatMessage({ id: 'general.somethingWrong' }));
        }

        return paymentIntent;
      } catch (e) {
        const errMessage = getGraphqlErrorMessage(e);
        handleDefaultError(intl.formatMessage({ id: 'general.somethingWrong' }), errMessage);
        return null;
      }
    },
    [paymentIntentRun]
  );

  const paypalPayment = useCallback(
    async (debtId: string): Promise<string | null> => {
      try {
        const result = await paymentIntentRun({ variables: { debtId } });
        const paymentIntent = result.data?.paymentIntent || null;

        if (!paymentIntent) {
          handleDefaultError('Something went wrong! Please try again.');
        }

        return paymentIntent;
      } catch (e) {
        const errMessage = getGraphqlErrorMessage(e);
        handleDefaultError('Something went wrong! Please try again.', errMessage);
        return null;
      }
    },
    [paymentIntentRun]
  );

  const reconfirm = useCallback(
    async (orderId: number): Promise<void> => {
      try {
        const result = await reconfirmRun({ variables: { orderId } });
        const returnUrl = result.data?.result.redirect_url;
        const success = result.data?.result.success;
        if (returnUrl) {
          return openService(returnUrl);
        }
        if (!success) {
          handleDefaultError('Something went wrong! Please try again.');
        }
        toast.success('Payment is processed');
      } catch (e) {
        const errMessage = getGraphqlErrorMessage(e);
        handleDefaultError('Something went wrong! Please try again.', errMessage);
      }
    },
    [reconfirmRun]
  );

  const openService = (url: string) => {
    window.location.replace(url);
  };

  return (
    <PaymentContext.Provider
      value={{
        ...paymentSession,
        hasPaymentMethod: !!paymentCardLastNumbers,
        paymentCardLastNumbers,
        intentPayment,
        intentPaymentLoading: paymentIntentLoading,
        openService,
        reconfirmLoading,
        reconfirm,
        paypalPayment
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
