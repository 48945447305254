function env<T = string | undefined>(variable: string | undefined, defaultValue?: T) {
  if (typeof variable === 'undefined') {
    return defaultValue;
  }
  return variable;
}

function requireEnv(variable: string | undefined, name: string) {
  if (typeof variable === 'undefined') {
    throw new Error(`Variable ${name} is required`);
  }
  return variable;
}

export const apiUrl = env(process.env.REACT_APP_API_URL, '/api/graphql') as string;

export const appEnv = env(process.env.REACT_APP_APP_ENV, 'dev') as string;

export const wsUrl = env(process.env.REACT_APP_WS_URL, '/') as string;

export const sentryDsn =
  'https://00ac5562e2f9cbdd5c6073126d6cc8b0@o4507928426774528.ingest.us.sentry.io/4507931964342272';

export const sentryTunnel = env(process.env.REACT_APP_SENTRY_TUNNEL, '/sentry-dsn');

export const msFilesApiUrl = env(process.env.REACT_APP_MSFILES_URL, '/uploader/storage') as string;

export const siteTitle = 'ARISORA';

export const messagesExtLink = 'https://t.me/Arisora';

export const mobileAppTriggerUrl = 'arisora://';

export const externalApiUrl = env(process.env.REACT_APP_EXTERNAL_API_URL, '/core') as string;

export const isProduction = process.env.NODE_ENV === 'production';

export const isStaging = window.location.hostname === 'nanognomes.com';

export const AUTH_CACHE_PREFIX = env(process.env.REACT_APP_AUTH_CACHE_PREFIX, 'arisora:') as string;
