import { useIntl } from 'react-intl';
import { StripePathsInput, useCreateStripeSetupSessionMutation, useDetachPaymentMethodMutation } from '../graphql';
import { handleDefaultError } from '../../common/utils/handleDefaultError';
import { getGraphqlErrorMessage } from '../../common/utils/graphqlErrors';
import { URL_KEY_COOKIE, URL_KEY_IS_MOBILE_APP, URL_KEY_LANGUAGE } from '../urlKeys';
import { useGeneratePathWithQuery } from '../../common/hooks/useGeneratePathWithQuery';
import { defaultLanguage } from '../intl';
import { useCookies } from '../../common/hooks/useCookies';
import { useWebview } from '../webview/useWebview';
import { useUser } from '../../entities/user';

const refetchQueries = ['User'];

export type UseStripeSessionReturnType = {
  addPaymentMethod: (input: StripePathsInput) => void;
  addPaymentLoading: boolean;
  detachPaymentMethod: () => void;
  detachPaymentLoading: boolean;
  paymentMethodDetached: boolean;
};

export function usePaymentSession(): UseStripeSessionReturnType {
  const generatePathWithQuery = useGeneratePathWithQuery();
  const { isWebview } = useWebview();
  const { user } = useUser();
  const intl = useIntl();
  const { cookieAccepted } = useCookies();
  const language = user?.selectedLanguage;

  const [runCreateStripeSetupSession, createStripeSetupSessionData] = useCreateStripeSetupSessionMutation({
    onCompleted: (data) => {
      if (data.stripeSessionUrl) {
        window.location.href = data.stripeSessionUrl;
      }
    },
    onError: (err) => {
      const graphqlErrorMessage = getGraphqlErrorMessage(err);
      handleDefaultError(intl.formatMessage({ id: 'payment.addMethodError' }), graphqlErrorMessage);
    }
  });
  const stripeSessionLoading = createStripeSetupSessionData.loading;

  const [runDetachPayment, detachPaymentData] = useDetachPaymentMethodMutation({
    refetchQueries,
    onError: (err) => {
      const graphqlErrorMessage = getGraphqlErrorMessage(err);
      handleDefaultError(intl.formatMessage({ id: 'payment.deleteMethodError' }), graphqlErrorMessage);
    }
  });
  const paymentMethodDetached = !!detachPaymentData.data?.result;
  const paymentDetachLoading = detachPaymentData.loading;

  const addPaymentMethod = (input: StripePathsInput) => {
    const inputObj = Object.fromEntries(
      Object.entries(input).map(([key, path]) => {
        return [
          key,
          isWebview
            ? generatePathWithQuery(path, {
                [URL_KEY_IS_MOBILE_APP]: String(isWebview),
                [URL_KEY_LANGUAGE]: language || defaultLanguage,
                [URL_KEY_COOKIE]: String(cookieAccepted)
              })
            : path
        ];
      })
    ) as StripePathsInput;

    return runCreateStripeSetupSession({
      variables: {
        input: inputObj
      }
    });
  };

  return {
    addPaymentMethod,
    addPaymentLoading: stripeSessionLoading,
    detachPaymentMethod: runDetachPayment,
    detachPaymentLoading: paymentDetachLoading,
    paymentMethodDetached
  };
}
