import React from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { useIntl } from 'react-intl';
import { GridGap, Grid } from '../../common/components/Grid';
import { ReactFCC } from '../../common/utils/helperTypes';
import { LinkBack } from '../../common/components/LinkBack';
import { LoaderBox } from '../../common/components/Loader';
import { useLocalStorage } from '../../common/hooks/useLocalStorage';
import { LOCAL_STORAGE_ORDERING } from '../../store/storageKeys';
import { CART_ROUTE, HOME_ROUTE, ORDERING_ROUTE, PRODUCT_PAGE_PARAM, PRODUCT_ROUTE } from '../../common/utils/routes';
import { Head } from '../../common/components/Head';
import { useUser } from '../../entities/user';
import { useCurrency } from '../../store/currency';
import { OrderingPageContent } from './content/OrderingPageContent';
import { OrderingPageState } from './types';
import s from './OrderingPage.module.scss';

export const OrderingPage: ReactFCC = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const { user } = useUser();

  const { currencyLoading } = useCurrency();

  const intl = useIntl();

  const [orderingStateLS, setOrderingStateLS, removeOrderingStateLS] =
    useLocalStorage<OrderingPageState>(LOCAL_STORAGE_ORDERING);

  const orderingState = location.state as OrderingPageState;

  /** Sentry events - Sentry.captureEvent({
    message: 'OrderingPage render',
    breadcrumbs: [
      {
        category: 'info',
        message: 'OrderingState info',
        data: {
          orderingState,
          orderingStateLS,
          profile: user
        }
      }
    ]
  }); **/

  useEffectOnce(() => {
    if (!orderingState && !orderingStateLS) {
      navigate(HOME_ROUTE, {
        replace: true
      });

      return;
    }

    if (orderingStateLS) {
      navigate(ORDERING_ROUTE, {
        replace: true,
        state: orderingStateLS
      });

      removeOrderingStateLS();
    }
  });

  return (
    <>
      <Head title={intl.formatMessage({ id: 'order.checkout' })} />

      {user && !currencyLoading ? (
        orderingState && (
          <Grid className={s.OrderingPage} gap={GridGap.MEDIUM}>
            {!orderingState.buyNow ? (
              <Grid.GridItem cols={{ xs: 2, lg: 12, xxl: 3 }}>
                <LinkBack to={CART_ROUTE}>{intl.formatMessage({ id: 'order.backToCart' })}</LinkBack>
              </Grid.GridItem>
            ) : (
              <Grid.GridItem cols={{ xs: 2, lg: 12, xxl: 3 }}>
                {/* todo уточнить */}
                <LinkBack to={generatePath(PRODUCT_ROUTE, { [PRODUCT_PAGE_PARAM]: [orderingState.productIds] })}>
                  Back to product
                </LinkBack>
              </Grid.GridItem>
            )}

            <Grid.GridItem cols={{ xs: 2, lg: 12, xxl: 9 }}>
              <OrderingPageContent orderingState={orderingState} saveOrderingState={setOrderingStateLS} />
            </Grid.GridItem>
          </Grid>
        )
      ) : (
        <LoaderBox />
      )}
    </>
  );
};
