import React from 'react';
import clsx from 'clsx';
import { Form } from 'react-bootstrap';
import { ReactFCC } from 'common/utils/helperTypes';
import { InputLayoutSpaces, FormLabel } from 'common/components/inputs';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import s from './InputLayout.module.scss';

export interface InputLayoutTouchedClasses {
  root?: string;
  label?: string;
  input?: string;
  error?: string;
  success?: string;
}

export interface InputLayoutTouchedSpaces {
  top?: ESpaceSize;
  bottom?: ESpaceSize;
}

export interface InputLayoutTouchedProps {
  className?: string;
  label?: string | React.ReactNode;
  description?: string | React.ReactNode;
  required?: boolean;
  error?: string | boolean;
  touched?: boolean;
  /**
   * @deprecated
   */
  space?: ESpaceSize | InputLayoutSpaces;
  classes?: InputLayoutTouchedClasses;
  help?: string;
  success?: string;
  disabled?: boolean;
  innerRef?: React.RefObject<HTMLDivElement>;
  style?: React.CSSProperties;
  loading?: boolean;
}

export const InputLayoutTouched: ReactFCC<InputLayoutTouchedProps> = (props) => {
  const {
    children,
    className,
    classes,
    label,
    description,
    required,
    error,
    help,
    space,
    disabled,
    innerRef,
    style,
    success,
    touched
  } = props;

  const spaceTop = typeof space === 'object' ? space?.top : space;
  const spaceBottom = typeof space === 'object' ? space?.bottom : space;

  return (
    <Form.Group className={clsx(s.InputLayout, className, classes?.root)} ref={innerRef} style={style}>
      {spaceTop && <Space size={spaceTop} />}

      {label && (
        <FormLabel className={classes?.label} disabled={disabled} required={required} description={description}>
          {label}

          {/* todo проверить */}
          {/*{loading && <Loader size={LoaderSize.SMALL} />}*/}
        </FormLabel>
      )}

      {children}

      {help && (
        <Form.Text
          className={clsx(s.InputLayout__help, {
            [s.InputLayout__help_error]: !!error && touched,
            [s.InputLayout__help_disabled]: disabled
          })}
          muted
        >
          {help}
        </Form.Text>
      )}

      {error && typeof error === 'string' && touched && (
        <Form.Text className={clsx(s.InputLayout__error, disabled && s.InputLayout__error_disabled, classes?.error)}>
          {error}
        </Form.Text>
      )}

      {success && <Form.Text className={clsx(s.InputLayout__success, classes?.success)}>{success}</Form.Text>}

      {spaceBottom && <Space size={spaceBottom} />}
    </Form.Group>
  );
};
