import React, { useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import {
  DebtType,
  DebtTypeEnum,
  OrderPaymentTypeEnum,
  useCancelOrderOnDebtMutation,
  useConfirmDebtPaymentMutation,
  useHandlePaidDebtMutation
} from 'store/graphql';
import { Heading, HeadingSize } from 'common/components/Heading/Heading';
import { Button, ButtonFit, ButtonVariant } from 'common/components/Button/Button/Button';
import { Alert } from 'common/components/Alert/Alert';
import { currencyCode } from 'store/intl';
import { usePayment } from 'store/payment/usePayment';
import { clearCache } from 'app/providers/auth-apollo';
import { handleDefaultError } from 'common/utils/handleDefaultError';
import { getGraphqlErrorMessage } from 'common/utils/graphqlErrors';
import { PaymentAddButton } from 'common/components/payment/PaymentAddButton/PaymentAddButton';
import { Modal } from 'common/components/Modal';
import { useToggle } from 'common/hooks/useToggle';
import { CustomerServiceModal } from '../../../../../features/order/customerService/components/CustomerServiceModal';
import { useIsMobile } from '../../../../../common/hooks/useIsMobile';
import { PaymentButtonProvider } from '../../../../../widgets/paypal';
import s from './OrderDebtAlert.module.scss';

const confirmDebtPaymentMutationOptions = {
  refetchQueries: ['Debts', 'Order'],
  update: clearCache(['getDebts', 'getOrderById'])
};

export interface OrderDebtAlertProps {
  debt: DebtType;
  orderID: string;
  orderId: number;
  paymentType: OrderPaymentTypeEnum;
  onSuccess?: () => void;
  refetchOrder: () => void;
  refetchDebts: () => void;
}

export const OrderDebtAlert = ({
  debt,
  orderID,
  orderId,
  paymentType,
  onSuccess,
  refetchOrder,
  refetchDebts
}: OrderDebtAlertProps) => {
  const debtId = debt.id;

  const location = useLocation();

  const intl = useIntl();

  const isMobile = useIsMobile();

  const [modalOpen, { set: openModal, unset: closeModal }] = useToggle();

  const [customerServiceModalOpen, { set: openCustomerServiceModal, unset: closeCustomerServiceModal }] =
    useToggle(false);

  const { hasPaymentMethod, addPaymentMethod, addPaymentLoading, intentPayment, intentPaymentLoading, openService } =
    usePayment();

  const [confirmDebtPaymentMutation, { loading: confirmDebtPaymentLoading }] = useConfirmDebtPaymentMutation(
    confirmDebtPaymentMutationOptions
  );
  const [cancelOrderMutation, { loading: cancelOrderMutationLoading }] = useCancelOrderOnDebtMutation(
    confirmDebtPaymentMutationOptions
  );

  const [handlePaidDebtMutation, { loading: handlePaidDebtMutationLoading }] = useHandlePaidDebtMutation();

  const confirmDeptPayment = useCallback(async () => {
    try {
      const debtIdStr = debtId.toString();

      if (!(await intentPayment(debtIdStr))) return;

      const result = await confirmDebtPaymentMutation({
        variables: {
          debtId: debtId
        }
      });

      if (!result.data?.confirmed) {
        handleDefaultError(intl.formatMessage({ id: 'general.somethingWrong' }));
        return;
      }

      if (result.data?.confirmed?.returnUrl) {
        return openService(result.data.confirmed.returnUrl);
      }
      toast.success(intl.formatMessage({ id: 'order.debtProcessed' }));

      closeModal();
    } catch (e) {
      const errMessage = getGraphqlErrorMessage(e);

      if (errMessage) {
        handleDefaultError(errMessage, e);
      }
    }
  }, [debtId, intentPayment, confirmDebtPaymentMutation, closeModal]);

  const handleAddPaymentMethod = () => {
    return addPaymentMethod({
      successPath: location.pathname,
      cancelPath: location.pathname
    });
  };

  const handleSuccessPaypalCharge = () => {
    closeModal();
    onSuccess?.();
  };

  const { formatNumber } = useIntl();
  const debtAmount = formatNumber(debt.amount || 0, {
    style: 'currency',
    currency: currencyCode
  });

  const handlePaidDebt = async () => {
    try {
      const result = await handlePaidDebtMutation({
        variables: {
          orderId: orderId,
          debtId: debtId
        }
      });
      if (result?.data?.result) {
        refetchOrder();
        refetchDebts();
      }
    } catch (e) {}
  };

  useEffect(() => {
    handlePaidDebt();
  }, []);

  const confirmLoading = confirmDebtPaymentLoading || intentPaymentLoading;

  return (
    <Alert className={s.OrderDebtAlert}>
      <div className={s.OrderDebtAlert__title}>
        <Heading size={HeadingSize.H5}>
          <FormattedMessage id={'order.alertPaymentDebtTitle'} />
        </Heading>
      </div>
      <div className={s.OrderDebtAlert__content}>
        <FormattedMessage id={'order.alertPaymentDebtText'} values={{ amount: debtAmount }} />
      </div>
      <div className={s.OrderDebtAlert__actions}>
        {hasPaymentMethod || paymentType === OrderPaymentTypeEnum.Paypal ? (
          <Button
            variant={ButtonVariant.ERROR}
            fit={isMobile ? ButtonFit.FILL : ButtonFit.FIT}
            onClick={openModal}
            loading={handlePaidDebtMutationLoading}
          >
            <FormattedMessage id={'order.alertPaymentDebtAction'} />
          </Button>
        ) : (
          <PaymentAddButton
            onClick={handleAddPaymentMethod}
            loading={addPaymentLoading || handlePaidDebtMutationLoading}
          />
        )}
        <Button
          variant={ButtonVariant.PRIMARY}
          fit={isMobile ? ButtonFit.FILL : ButtonFit.FIT}
          onClick={openCustomerServiceModal}
        >
          <FormattedMessage id={'customerService.title'} />
        </Button>
      </div>

      <CustomerServiceModal
        isOpen={customerServiceModalOpen}
        onClose={() => closeCustomerServiceModal()}
        orderID={orderID}
      />

      <Modal title={intl.formatMessage({ id: 'order.alertPaymentDebtTitle' })} isOpen={modalOpen} onClose={closeModal}>
        <Modal.Body>
          <FormattedMessage id={'order.alertPaymentDebtText'} values={{ amount: debtAmount }} />
        </Modal.Body>

        <Modal.Footer className={s.OrderDebtAlert__footer}>
          {paymentType === OrderPaymentTypeEnum.Stripe ? (
            <Modal.Button variant={ButtonVariant.PRIMARY} onClick={confirmDeptPayment} loading={confirmLoading}>
              <FormattedMessage id={'order.alertPaymentDebtAction'} />
            </Modal.Button>
          ) : (
            <PaymentButtonProvider
              order_id={orderId}
              debtId={debtId}
              orderPrice={debt.amount}
              onSuccess={handleSuccessPaypalCharge}
            />
          )}
          {debt.debt_type === DebtTypeEnum.Seller && (
            <Button
              variant={ButtonVariant.ERROR}
              onClick={cancelOrderMutation}
              disabled={confirmDebtPaymentLoading || intentPaymentLoading}
              loading={cancelOrderMutationLoading}
            >
              <FormattedMessage id={'order.cancelOrder'} />
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </Alert>
  );
};
