import { AvailableCurrencyEnum } from './CurrencyContext';

export const defaultRates = {
  RUB: 0.68,
  EUR: 0.0064,
  USD: 0.0067,
  JPY: 1.0,
  AUD: 0.010488,
  CAD: 0.009131,
  MYR: 0.0318289
};

export const defaultCurrency = (): AvailableCurrencyEnum => {
  const currentLanguage = navigator.language;

  const languageToCurrencyMap: { [key: string]: AvailableCurrencyEnum } = {
    ja: AvailableCurrencyEnum.JPY,
    'en-US': AvailableCurrencyEnum.USD,
    'en-GB': AvailableCurrencyEnum.GBP,
    'fr-CA': AvailableCurrencyEnum.CAD,
    fr: AvailableCurrencyEnum.EUR,
    de: AvailableCurrencyEnum.EUR,
    es: AvailableCurrencyEnum.EUR,
    ms: AvailableCurrencyEnum.MYR,
    'en-AU': AvailableCurrencyEnum.AUD
  };

  return languageToCurrencyMap[currentLanguage] || AvailableCurrencyEnum.JPY;
};
