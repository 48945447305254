import { CategoryPath } from 'store/graphql';

type CategoryPathIds = {
  categoryId: number;
  subCategoryId: number;
  subSubCategoryId: number;
};

export const convertCategoryPath = (categoryPath?: Partial<CategoryPath>) => {
  return {
    categoryId: categoryPath?.path?.find((p) => p.depth === 0)?.id || 0,
    subCategoryId: categoryPath?.path?.find((p) => p.depth === 1)?.id || 0,
    subSubCategoryId: categoryPath?.path?.find((p) => p.depth === 2)?.id || 0
  };
};
