import React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { ExtractArray, ReactFCC } from 'common/utils/helperTypes';
import { Heading, HeadingSize } from 'common/components/Heading/Heading';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { EBreakpoints, useBreakpoint } from 'common/hooks/useBreakpoint';
import { ProductQuery, ProductStatusEnum } from 'store/graphql';
import { Button, ButtonFit, ButtonVariant } from '../../../../common/components/Button';
import { useProductStore } from '../../productStore';
import { ProductComment } from './comment/ProductComment';
import { ProductCommentsForm } from './form/ProductCommentsForm';
import s from './ProductComments.module.scss';

export type TProductComments = {
  className?: string;
  productId: number;
  sellerId: number;
  productStatus: string;
  comments: ExtractArray<ProductQuery['marketPlace']['products']['entries']>['comments'];
};

export const ProductComments: ReactFCC<TProductComments> = (props) => {
  const { className, productId, productStatus, comments, sellerId } = props;

  const { commentsLoading, setCommentsLimit } = useProductStore();

  const entries = comments.entries;
  const pagination = comments.pagination;

  const isMobile = useBreakpoint(EBreakpoints.LG_DOWN);

  return (
    <div className={clsx(s.ProductComments, className)}>
      <Heading size={HeadingSize.H4}>
        <FormattedMessage id="product.comments" /> {entries.length && <span>({entries.length})</span>}
      </Heading>

      <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />

      {!entries?.length && (
        <div>
          <FormattedMessage id="product.commentsEmpty" />
        </div>
      )}

      <div className={s.ProductComments__content}>
        {entries?.slice().map((comment) => (
          <ProductComment comment={comment} key={comment.id} sellerId={sellerId} productStatus={productStatus} />
        ))}
      </div>

      {pagination.totalCount > entries.length && (
        <>
          <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />

          <Button
            variant={ButtonVariant.PRIMARY_OUTLINE}
            fit={isMobile ? ButtonFit.FILL : ButtonFit.FIT}
            onClick={() => setCommentsLimit(pagination.limit + 10)}
            loading={commentsLoading}
          >
            Load more
          </Button>
        </>
      )}

      <Space size={ESpaceSize.PIXEL_32} />

      {productStatus === ProductStatusEnum.ForSale && <ProductCommentsForm productId={productId} />}
    </div>
  );
};
