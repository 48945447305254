import React, { useLayoutEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { tryNumber } from '@proscom/ui-utils';
import { useProductQuery } from '../../store/graphql';
import { ReactFCC } from '../../common/utils/helperTypes';
import { NotFound } from '../../common/components/NotFound/NotFound';
import { LoaderBox } from '../../common/components/Loader';
import { useLocalStorageChange } from '../../common/hooks/useLocalStorageChange';
import { LOCAL_STORAGE_CART } from '../../store/storageKeys';
import { useUrlParam } from '../../common/hooks/useUrlParam';
import { HOME_ROUTE, PRODUCT_PAGE_PARAM } from '../../common/utils/routes';
import { Head } from '../../common/components/Head';
import { Link } from '../../common/components/Link';
import { useCurrency } from '../../store/currency';
import { ProductPageContent } from './content/ProductPageContent';
import { DEFAULT_COMMENTS_LIMIT, useProductStore } from './productStore';

export const ProductPage: ReactFCC = () => {
  const id = tryNumber(useUrlParam(PRODUCT_PAGE_PARAM), 0);

  const { commentsLimit, setCommentsLoading } = useProductStore();
  const { currencyLoading } = useCurrency();

  const baseVariables = useMemo(
    () => ({
      id: id
    }),
    [id]
  );

  const {
    data: productQuery,
    loading: productLoading,
    refetch: fetchProduct,
    fetchMore
  } = useProductQuery({
    variables: {
      ...baseVariables,
      commentsPagination: {
        offset: 0,
        limit: DEFAULT_COMMENTS_LIMIT
      }
    },
    skip: !id
  });

  const [product] = productQuery?.marketPlace?.products?.entries || [];

  useLocalStorageChange(LOCAL_STORAGE_CART, fetchProduct);

  const intl = useIntl();

  useLayoutEffect(() => {
    if (commentsLimit !== DEFAULT_COMMENTS_LIMIT) {
      setCommentsLoading(true);
      fetchMore({
        variables: {
          ...baseVariables,
          commentsPagination: {
            offset: 0,
            limit: commentsLimit
          }
        }
      }).then(() => setCommentsLoading(false));
    }
  }, [baseVariables, commentsLimit, fetchMore, setCommentsLoading]);

  const loading = productLoading || currencyLoading;

  return (
    <>
      <Head title={product?.name} />

      {loading ? (
        <LoaderBox />
      ) : product ? (
        <ProductPageContent product={product} />
      ) : (
        <NotFound title={intl.formatMessage({ id: 'product.notFound' })}>
          <FormattedMessage
            id="product.notFoundText"
            values={{
              a: (chunks) => <Link to={HOME_ROUTE}>{chunks}</Link>
            }}
          />
        </NotFound>
      )}
    </>
  );
};
