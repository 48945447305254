import React from 'react';

import clsx from 'clsx';
import { To } from 'react-router-dom';
import { RSAQUO } from '@proscom/ui-utils/src/utf';
import { ReactFCC } from '../../../../../common/utils/helperTypes';

import { Link } from '../../../../../common/components/Link';
import s from './ProductCharacteristicsItem.module.scss';

export type ToTitle = {
  title: string;
  to: To;
};
export type TProductCharacteristicsItem = {
  className?: string;
  name: string;
  value?: string | null;
  to?: To;
  multipleTitles?: ToTitle[];
};

export const ProductCharacteristicsItem: ReactFCC<TProductCharacteristicsItem> = (props) => {
  const { className, name, value, to, multipleTitles } = props;

  return (
    <div className={clsx(s.ProductCharacteristicsItem, className)}>
      <span>{name}</span>

      <div className={s.ProductCharacteristicsItem__filler} />
      {to ? <Link to={to}>{value}</Link> : !multipleTitles ? <span>{value}</span> : null}
      {multipleTitles && multipleTitles.length > 0 && (
        <div className={s.ProductCharacteristicsItem__multiple}>
          {multipleTitles.map((t, index) => (
            <div className={s.ProductCharacteristicsItem__multiple_item} key={index}>
              <Link to={t.to}>{t.title}</Link> {index < multipleTitles.length - 1 && <span>{RSAQUO}</span>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
