import React, { useCallback } from 'react';
import { LoaderBox } from 'common/components/Loader';
import { useNotifications } from 'store/notifications/useNotifications';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { FormattedMessage } from 'react-intl';
import { useEffectOnce } from 'react-use';
import { useNavigate } from 'react-router';
import { SortDirectionEnum, useHomePageQuery } from '../../store/graphql';
import { ProductsGrid } from '../../widgets/product';
import { Head } from '../../common/components/Head';
import { Button, ButtonFit, ButtonVariant } from '../../common/components/Button';
import { useIsMobile } from '../../common/hooks/useIsMobile';
import { useCurrency } from '../../store/currency';
import { useLang } from '../../store/intl';
import { useBatchedQueryParam } from '../../common/hooks/queryParam/useBatchedQueryParam';
import { useUi } from '../../store/ui';
import { useAuth } from '../../app/providers/auth-apollo';
import { AdBanners, NotificationsAlert } from './components';
import s from './IndexPage.module.scss';

export enum HomePageIntentEnum {
  Registration = 'registration'
}

export const DEFAULT_HOMEPAGE_LIMIT = 40;
export const DEFAULT_MOBILE_HOMEPAGE_LIMIT = 25;
export const HomePage = () => {
  const { importantUnreadCount: importantNotificationsCount, countLoading: notificationsCountLoading } =
    useNotifications();
  const { toggleAuthModal } = useUi();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const [intent] = useBatchedQueryParam('intent');

  useEffectOnce(() => {
    if (intent === HomePageIntentEnum.Registration && !isAuthenticated) {
      toggleAuthModal();
    }
    navigate({
      search: ''
    });
  });

  const { currencyLoading } = useCurrency();

  const isMobile = useIsMobile();
  const limit = isMobile ? DEFAULT_MOBILE_HOMEPAGE_LIMIT : DEFAULT_HOMEPAGE_LIMIT;
  const { language } = useLang();

  const {
    data: indexPageQuery,
    loading: indexPageQueryLoading,
    fetchMore: fetchMoreProducts
  } = useHomePageQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: isMobile ? DEFAULT_MOBILE_HOMEPAGE_LIMIT : DEFAULT_HOMEPAGE_LIMIT,
      offset: 0,
      sort: {
        direction: SortDirectionEnum.Desc
      },
      bannerFilters: {
        locale: language
      }
    }
  });

  const products = indexPageQuery?.products?.entries || [];
  const totalCount = indexPageQuery?.products.pagination.totalCount || 0;
  const banners = indexPageQuery?.banners || [];
  const offset = indexPageQuery?.products.pagination.offset || 0;
  const hasMore = totalCount > offset;

  const loading = indexPageQueryLoading || notificationsCountLoading || currencyLoading;

  const fetchMore = useCallback(() => {
    fetchMoreProducts({
      variables: {
        limit,
        offset: offset + limit
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevResult;
        return {
          products: {
            ...prevResult.products,
            entries: [...prevResult.products.entries, ...fetchMoreResult.products?.entries],
            pagination: fetchMoreResult.products.pagination
          },
          banners: fetchMoreResult.banners
        };
      }
    });
  }, [offset, limit, fetchMoreProducts]);

  return (
    <>
      <Head title={'Home'} />

      {!products.length && loading ? (
        <>
          <LoaderBox />
        </>
      ) : (
        <div className={s.HomePage}>
          {importantNotificationsCount > 0 && (
            <>
              <NotificationsAlert count={importantNotificationsCount} />
              <Space size={ESpaceSize.PIXEL_32} />
            </>
          )}

          {banners.length > 0 && <AdBanners className={s.HomePage__banners} banners={banners} />}
          <ProductsGrid className={s.HomePage__products} products={products} />
          {hasMore && (
            <Button
              variant={ButtonVariant.PRIMARY_OUTLINE}
              onClick={() => fetchMore()}
              fit={isMobile ? ButtonFit.FILL : ButtonFit.FIT}
              loading={indexPageQueryLoading}
            >
              <FormattedMessage id={'catalog.showMoreButton'} />
            </Button>
          )}
        </div>
      )}
    </>
  );
};
