import { Col, Container, Row } from 'react-bootstrap';
import React from 'react';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { HOME_ROUTE, LAW_ROUTE, PathBuilder, TESTIMONIALS_ROUTE, TICKETS_ROUTE } from '../../../../common/utils/routes';
import { FooterLinks } from '../FooterLinks/FooterLinks';
import { useToggle } from '../../../../common/hooks/useToggle';
import { FeedbackModal } from '../FeedbackModal/FeedbackModal';
import logoSrc from './assets/logo.svg';
import s from './Footer.module.scss';

export const Footer = () => {
  const intl = useIntl();
  const [feedbackModalOpen, { set: openFeedbackModal, unset: closeFeedbackModal }] = useToggle(false);

  return (
    <div className={s.Footer}>
      <Container>
        <Row>
          <Col xs={12} lg={3} className={s.Footer__info}>
            <Link className={s.Footer__logo} to={HOME_ROUTE}>
              <img src={logoSrc} alt="" />
            </Link>
            <span className={s.Footer__text}>
              <FormattedMessage id="footer.about" />
            </span>
          </Col>
          <FooterLinks
            title={intl.formatMessage({ id: 'footer.aboutUs' })}
            items={[
              {
                name: intl.formatMessage({ id: 'footer.linkAboutCompany' }),
                path: PathBuilder.getSupportAboutPath('about-company'),
                external: false
              },
              {
                name: intl.formatMessage({ id: 'footer.linkTermsOfUse' }),
                path: PathBuilder.getSupportAboutPath('terms-and-conditions'),
                external: false
              },
              {
                name: intl.formatMessage({ id: 'footer.linkPrivacyPolicy' }),
                path: PathBuilder.getSupportAboutPath('privacy-policy'),
                external: false
              },
              {
                name: intl.formatMessage({ id: 'footer.linkLaw' }),
                path: LAW_ROUTE,
                external: false
              },
              {
                name: intl.formatMessage({ id: 'footer.linkCookies' }),
                path: PathBuilder.getSupportAboutPath('use-of-cookies'),
                external: false
              },
              {
                name: intl.formatMessage({ id: 'footer.linkReviews' }),
                path: TESTIMONIALS_ROUTE
              },
              {
                name: intl.formatMessage({ id: 'footer.linkContacts' }),
                path: TICKETS_ROUTE,
                external: false
              },
              {
                name: intl.formatMessage({ id: 'footer.linkFeedback' }),
                path: '#',
                onClick: () => openFeedbackModal()
              }
            ]}
            xs={12}
            lg={2}
          />
          <FooterLinks
            title={intl.formatMessage({ id: 'footer.userManual' })}
            items={[
              {
                name: intl.formatMessage({ id: 'footer.userRegistration' }),
                path: PathBuilder.getSupportManualPath('user-regstration')
              },
              {
                name: intl.formatMessage({ id: 'footer.linkPaymentMethods' }),
                path: PathBuilder.getSupportManualPath('payment-methods')
              },
              {
                name: intl.formatMessage({ id: 'footer.linkPaymentCurrency' }),
                path: PathBuilder.getSupportManualPath('payment-currency')
              },
              {
                name: intl.formatMessage({ id: 'footer.linkRefunds' }),
                path: PathBuilder.getSupportManualPath('refund-policy')
              },
              {
                name: intl.formatMessage({ id: 'footer.linkCommissions' }),
                path: PathBuilder.getSupportManualPath('commission')
              },
              {
                name: intl.formatMessage({ id: 'footer.listItem' }),
                path: PathBuilder.getSupportManualPath('how-to-list-an-item')
              },
              {
                name: intl.formatMessage({ id: 'footer.linkDelivery' }),
                path: PathBuilder.getSupportManualPath('shipping-information')
              },
              {
                name: intl.formatMessage({ id: 'footer.linkLimit' }),
                path: PathBuilder.getSupportManualPath('size-and-weight')
              },
              {
                name: intl.formatMessage({ id: 'footer.packAndShip' }),
                path: PathBuilder.getSupportManualPath('packaging-and-shipping')
              },
              {
                name: intl.formatMessage({ id: 'footer.receivingSales' }),
                path: PathBuilder.getSupportManualPath('receiving-sales-proceeds')
              }
            ]}
            xs={12}
            lg={2}
          />
          <FooterLinks
            title={intl.formatMessage({ id: 'footer.help' })}
            items={[
              {
                name: intl.formatMessage({ id: 'footer.linkFaq' }),
                path: PathBuilder.getSupportHelpPath('faq')
              },
              {
                name: intl.formatMessage({ id: 'footer.linkSellerIdentification' }),
                path: PathBuilder.getSupportHelpPath('seller-identity-authentication')
              }
            ]}
            xs={12}
            lg={2}
          />
          {/*<Col xs={12} lg={{ span: 2, offset: 1 }} className="d-flex flex-column">
            <h6 className={clsx(s.Footer__header, s.Footer__header_mobileAppsHeader)}>
              <FormattedMessage id="footer.mobileApp" />
            </h6>
            <div className={s.Footer__mobileAppLinks}>
              <a href="/src/pages" className={s.Footer__mobileAppLink}>
                <IconGoogle />
              </a>
              <a href="/src/pages" className={s.Footer__mobileAppLink}>
                <IconAppStore />
              </a>
            </div>
            <h6 className={s.Footer__header}>
              <FormattedMessage id="footer.socialNetworks" />
            </h6>
            <div className="d-flex flex-row gap-3">
              <a href="/src/pages" className={s.Footer__socialLink}>
                <IconFacebook />
              </a>
              <a href="/src/pages" className={s.Footer__socialLink}>
                <IconInstagram />
              </a>
              <a href="/src/pages" className={s.Footer__socialLink}>
                <IconTwitter />
              </a>
              <a href="/src/pages" className={s.Footer__socialLink}>
                <IconLinkedin />
              </a>
            </div>
          </Col>*/}
        </Row>
        <Row>
          <span className={clsx(s.Footer__copyright, 'text-center text-lg-start')}>
            <FormattedMessage id="footer.copyright" values={{ year: new Date().getFullYear() }} />
          </span>
        </Row>
      </Container>
      <FeedbackModal onClose={() => closeFeedbackModal()} isOpen={feedbackModalOpen} />
    </div>
  );
};
