import TabLock from 'browser-tabs-lock';
import { from, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { requestToken, RequestTokenVariables } from '../api/requestToken';
import { singleton } from '../utils/singleton';
import { TokenResponse } from '../api/types';
import { retryPromise } from '../utils/retryPromise';
import { refreshToken, RefreshTokenVariables } from '../api/refreshToken';
import { logout } from '../api/logout';
import { requestCode } from '../api/requestCode';
import { handleError } from '../utils/handleError';
import { apiUrl, AUTH_CACHE_PREFIX } from '../../../../config';
import { Cache, CacheEntry } from './Cache';
import { authClient } from './apolloClient';
import { AuthenticationError } from './AuthenticationError';
import { AUTH_CACHE_KEY } from './cacheKeys';

const LOCK_KEY = `${AUTH_CACHE_PREFIX}:lock`;

const lock = new TabLock();

export class AuthService {
  cache = new Cache();
  client = authClient;

  public isAuthenticated() {
    const entry = this._getCacheEntry();

    if (!entry) {
      return false;
    }

    return entry.expires_at > Date.now();
  }

  public getEntryFromCache() {
    const entry = this._getCacheEntry();

    if (!entry) {
      return null;
    }

    if (entry.expires_at > Date.now()) {
      return entry;
    }

    return null;
  }

  public async getEntry({ force }: { force?: boolean } = {}) {
    const entry = this._getCacheEntry();

    if (!entry) {
      return null;
    }

    if (entry.expires_at > Date.now() && !force) {
      return entry;
    }

    if (entry.body.refresh_iat > Date.now()) {
      return await this._refreshToken({
        token: entry.body.refresh_token
      });
    }

    return null;
  }

  // todo maybe singleton
  public async requestCode(email: string) {
    try {
      const response = await requestCode(this.client, {
        email
      });

      if (!response.data?.response) {
        throw new Error('Code requesting error');
      }
    } catch (error: any) {
      handleError(error);
      if (!!error?.networkError) {
        throw new Error('Network error');
      }

      throw new AuthenticationError('Code requesting error');
    }
  }

  public async login(email: string, code: string, usePassword?: boolean) {
    try {
      return await this._requestToken({
        email,
        code,
        usePassword
      });
    } catch (error: any) {
      handleError(error);
      if (!!error?.networkError) {
        throw new Error('Network error');
      }

      throw new AuthenticationError('Login error');
    }
  }

  public async logout(force?: boolean) {
    const entry = this._getCacheEntry();
    const authLink = setContext((_, { headers }) => {
      const accessToken = entry?.body.access_token;
      return { headers: { ...headers, ...(accessToken && { Authorization: `Bearer ${accessToken}` }) } };
    });
    const httpLink = new HttpLink({ uri: apiUrl });
    const client = authClient;
    client.setLink(from([authLink, httpLink]));

    try {
      if (entry && !force) {
        await logout(client, {
          token: entry.body.refresh_token
        });
      }
    } catch (error: any) {
      handleError(error);
      if (!!error?.networkError) {
        throw new Error('Network error');
      }

      throw new AuthenticationError('Logout error');
    } finally {
      this._removeCacheEntry();
    }
  }

  private _requestToken = singleton(async (variables: RequestTokenVariables) => {
    if (await retryPromise(() => lock.acquireLock(LOCK_KEY, 5000), 10)) {
      try {
        window.addEventListener('pagehide', this._releaseLockOnPageHide);

        const { data } = await requestToken(this.client, variables);

        if (data?.response) {
          return this._setCacheEntry(data.response);
        } else {
          this._removeCacheEntry();
        }
      } catch (error: any) {
        this._removeCacheEntry();
        handleError(error);

        if (!!error?.networkError) {
          throw new Error('Network error');
        }

        throw new AuthenticationError('Request token error');
      } finally {
        await lock.releaseLock(LOCK_KEY);
        window.removeEventListener('pagehide', this._releaseLockOnPageHide);
      }
    }

    return null;
  });

  private _refreshToken = singleton(async (variables: RefreshTokenVariables) => {
    if (await retryPromise(() => lock.acquireLock(LOCK_KEY, 5000), 10)) {
      try {
        window.addEventListener('pagehide', this._releaseLockOnPageHide);

        const { data } = await refreshToken(this.client, variables);

        if (data?.response) {
          return this._setCacheEntry(data.response);
        } else {
          this._removeCacheEntry();
        }
      } catch (error: any) {
        this._removeCacheEntry();
        handleError(error);

        if (!!error?.networkError) {
          throw new Error('Network error');
        }

        throw new AuthenticationError('Refresh token error');
      } finally {
        await lock.releaseLock(LOCK_KEY);
        window.removeEventListener('pagehide', this._releaseLockOnPageHide);
      }
    }

    return null;
  });

  private _setCacheEntry(data: TokenResponse) {
    const entry: CacheEntry = {
      expires_at: new Date(data.accessTokenExpiresAt).getTime(),
      body: {
        access_token: data.accessToken,
        refresh_token: data.refreshToken.token,
        refresh_iat: new Date(data.refreshToken.expires_at).getTime(),
        user: {
          id: data.user.id
        }
      }
    };

    this.cache.set(AUTH_CACHE_KEY, entry);

    return entry;
  }

  private _getCacheEntry() {
    return this.cache.get(AUTH_CACHE_KEY);
  }

  private _removeCacheEntry() {
    return this.cache.remove(AUTH_CACHE_KEY);
  }

  private _releaseLockOnPageHide = async () => {
    await lock.releaseLock(LOCK_KEY);

    window.removeEventListener('pagehide', this._releaseLockOnPageHide);
  };
}
