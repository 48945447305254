import React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { EMDASH } from '@proscom/ui-utils';
import { ExtractArray, ReactFCC } from 'common/utils/helperTypes';
import { Heading, HeadingSize } from 'common/components/Heading/Heading';
import {
  ProductQuery,
  ProductStatusEnum,
  Rating as RatingType,
  SellerPublicInfoType,
  useDeliveryDaysQuery
} from 'store/graphql';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { useLang } from 'store/intl';
import { getDeliveryDate } from 'common/utils/date';
import { EBreakpoints, useBreakpoint } from 'common/hooks/useBreakpoint';
import { useCart, useProductInCart } from 'store/cart';
import { Button, ButtonFit, ButtonSize } from 'common/components/Button';
import { Divider } from 'common/components/Divider/Divider';
import { Rating } from 'common/components/Rating/Rating';
import { GridGap, Grid } from 'common/components/Grid/Grid';
import { Link } from 'common/components/Link/Link';
import { FormattedDate } from 'common/components/CustomFormatters';
import { BsBox } from 'react-icons/bs';
import { PathBuilder, PROFILE_ROUTE } from '../../../../common/utils/routes';
import { ProductBadge } from '../../../../entities/product';
import { Anchor } from '../../../../common/components/Anchor';
import { useUi } from '../../../../store/ui';
import { Loader, LoaderSize } from '../../../../common/components/Loader';
import { BuyNowButton } from '../../../../features/product/buyNow';
import { ComplaintContextMenu, ComplaintModal } from '../../../../features/product/complaint';
import { useToggle } from '../../../../common/hooks/useToggle';
import { UserAddress } from '../../../../widgets/address';
import { useCurrency } from '../../../../store/currency';
import s from './ProductInformation.module.scss';

export type TProductInformation = {
  className?: string;
  product: ExtractArray<ProductQuery['marketPlace']['products']['entries']>;
  seller?: SellerPublicInfoType;
  shippingAddress?: UserAddress;
  isLoggedIn: boolean;
};

const defaultRating: RatingType = {
  positive: 0,
  neutral: 0,
  negative: 0
};

export const ProductInformation: ReactFCC<TProductInformation> = ({
  className,
  product,
  seller,
  shippingAddress,
  isLoggedIn
}) => {
  const isMobile = useBreakpoint(EBreakpoints.LG_DOWN);

  const { addItemToCart, addItemToCartLoading, sellerId } = useCart();

  const [isProductInCart, isProductInCartLoading] = useProductInCart(product.id);

  const { language } = useLang();

  const isMyProduct = !!(sellerId && seller?.id === sellerId);
  const isSold = product.status === ProductStatusEnum.Sold;

  const cartButton = (
    <Button
      size={ButtonSize.LARGE}
      fit={ButtonFit.FILL}
      loading={!isSold && (addItemToCartLoading || isProductInCartLoading)}
      disabled={isSold || isProductInCart || isMyProduct}
      onClick={() => addItemToCart(product.id)}
    >
      {isMyProduct ? (
        <FormattedMessage id="product.isMyProduct" />
      ) : isProductInCart && !isSold ? (
        <FormattedMessage id="product.addedToCart" />
      ) : isSold ? (
        <FormattedMessage id="product.sold" />
      ) : (
        <FormattedMessage id="product.addToCart" />
      )}
    </Button>
  );

  const { data: deliveryMetadataData, loading: deliveryMetadataLoading } = useDeliveryDaysQuery({
    variables: {
      addressId: shippingAddress?.id || 0,
      productIds: [product.id]
    },
    skip: !shippingAddress
  });

  const { toggleAuthModal } = useUi();

  const [complaintModalOpen, { set: openComplaintModal, unset: closeComplaintModal }] = useToggle(false);

  const onClickSignIn = () => {
    toggleAuthModal();
  };

  const { deliveryDaysMax, deliveryDaysMin } = deliveryMetadataData?.metadata || {};

  const deliveryDate =
    deliveryDaysMin && deliveryDaysMax ? getDeliveryDate(deliveryDaysMin, deliveryDaysMax, language) : null;

  const { convertCurrency, currencyLoading } = useCurrency();

  return (
    <div className={clsx(s.ProductInformation, className)}>
      <Heading size={HeadingSize.H3}>{product.name}</Heading>

      <Space size={ESpaceSize.PIXEL_16} />

      <div className={s.ProductPageContent__price}>
        <Heading size={HeadingSize.H4}>{convertCurrency(product.price)}</Heading>

        {product.status === ProductStatusEnum.Sold && <ProductBadge />}
      </div>

      <Space size={ESpaceSize.PIXEL_8} />

      {product.sellerId !== sellerId && (
        <div className={s.ProductPageContent__headerDate}>
          {!deliveryMetadataLoading ? (
            <>
              <BsBox className={s.ProductPageContent__headerDateIcon} />
              <span>
                {!isLoggedIn ? (
                  <>
                    <FormattedMessage
                      id={'cart.date.signIn'}
                      values={{
                        a: (chunks) => (
                          <Anchor component={'span'} onClick={onClickSignIn}>
                            {chunks}
                          </Anchor>
                        )
                      }}
                    />
                  </>
                ) : deliveryDate ? (
                  <>
                    <FormattedMessage id="product.deliveryDate" /> <b>{deliveryDate}</b>
                  </>
                ) : shippingAddress?.invalid ? (
                  <FormattedMessage id="cart.date.invalid" />
                ) : (
                  <>
                    <FormattedMessage
                      id={'cart.date.noAddress'}
                      values={{
                        a: (chunks) => <Link to={PROFILE_ROUTE}>{chunks}</Link>
                      }}
                    />
                  </>
                )}
              </span>
            </>
          ) : (
            <Loader size={LoaderSize.SMALL} />
          )}
        </div>
      )}

      <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />

      <Grid cols={6} gap={isMobile ? GridGap.SMALL : GridGap.MEDIUM}>
        <Grid.GridItem cols={{ xs: 2, md: 5, xl: isProductInCart ? 4 : 2, xxl: isProductInCart ? 3 : 2 }}>
          {cartButton}
        </Grid.GridItem>

        <Grid.GridItem cols={{ xs: 2, md: 5, xl: 2, xxl: 2 }}>
          <BuyNowButton
            productId={product.id}
            isMyProduct={isMyProduct}
            isSold={isSold}
            isProductInCart={isProductInCart}
          />
        </Grid.GridItem>
      </Grid>

      <Divider space={{ top: isMobile ? undefined : ESpaceSize.PIXEL_32, bottom: ESpaceSize.PIXEL_16 }} />

      <div className={s.ProductPageContent__date}>
        <FormattedMessage id="product.postedDate" />{' '}
        <FormattedDate value={new Date(product.lastModified)} format={'LLL dd Y, HH:mm'} />
      </div>

      {seller && (
        <>
          <Link to={PathBuilder.getSellerPublicPath(seller.id)} className={s.ProductInformation__actionBlockLink}>
            <div className={s.ProductInformation__actionBlock}>
              <span className={s.ProductInformation__sellerNickname}>{seller.nickname || EMDASH}</span>
              <ComplaintContextMenu onClick={() => openComplaintModal()} />
            </div>

            <Space size={ESpaceSize.PIXEL_6} />

            <Rating {...(seller.rating || defaultRating)} />
          </Link>
        </>
      )}

      <Divider
        space={{
          top: isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24,
          bottom: isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32
        }}
      />

      <Heading size={HeadingSize.H4}>
        <FormattedMessage id="product.description" />
      </Heading>

      <Space size={isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16} />

      <div className={s.ProductPageContent__description}>{product.description}</div>

      {/* Модальное окно для отправки жалобы на товар */}
      <ComplaintModal
        isOpen={complaintModalOpen}
        onClose={() => closeComplaintModal()}
        product={product}
      ></ComplaintModal>
    </div>
  );
};
