import { FormattedMessage } from 'react-intl';
import { ArisoraOrderStatus, OrderContent, OrderPackages, useSellerOrdersQuery } from '../../../../../../store/graphql';
import { LoaderBox } from '../../../../../../common/components/Loader';
import { OrderSimpleCard } from '../../../../../../entities/order/components/OrderSimpleCard/OrderSimpleCard';
import { ESpaceSize, Space } from '../../../../../../common/components/Space/Space';
import { Divider } from '../../../../../../common/components/Divider/Divider';
import { PickupRequestParcelQR } from './PickupRequesParcelQR/PickupRequestParcelQR';
import s from './PickupRequestParcelCard.module.scss';

export type PickupRequestParcelCardProps = {
  packages: OrderPackages;
};
export const PickupRequestParcelCard = (props: PickupRequestParcelCardProps) => {
  const { packages } = props;
  const { data: ordersQuery, loading: ordersLoading } = useSellerOrdersQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        ids: [packages.orderId]
      }
    }
  });

  const order = ordersQuery?.orders.seller?.orders.entries[0];

  if (ordersLoading) {
    return <LoaderBox />;
  }

  return (
    <div className={s.PickupRequestParcelCard}>
      <div className={s.PickupRequestParcelCard__content}>
        <div>
          <PickupRequestParcelQR decodedQr={packages.decodedQr || ''} />
        </div>
        <div className={s.PickupRequestParcelCard__package}>
          <OrderSimpleCard
            id={order?.order.ID as string}
            entityId={order?.order.id || 0}
            date={order?.order.orderDate as Date}
            status={order?.order.status as ArisoraOrderStatus}
            contents={order?.contents as OrderContent[]}
            customerNickname={order?.order.customerNickname || ''}
            isSeller
          />
          <Space size={ESpaceSize.PIXEL_16} />
          <span>
            <FormattedMessage id={'pickupRequest.card.boxQuantities'} /> {packages.packages.length}
          </span>
          <Space size={ESpaceSize.PIXEL_16} />
          <div className={s.PickupRequestParcelCard__parcels}>
            {packages.packages.map((pack, index) => (
              <div key={index} className={s.PickupRequestParcelCard__parcels_sizes}>
                <span>
                  <FormattedMessage id={'pickupRequest.card.box'} /> {index + 1}
                </span>
                <span>
                  <FormattedMessage id={'pickupRequest.card.weight'} /> {pack.weight}
                </span>
                <span>
                  <FormattedMessage id={'pickupRequest.card.size'} /> {pack.height}cm * {pack.width}cm * {pack.length}cm
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Divider space={ESpaceSize.PIXEL_24} />
    </div>
  );
};
