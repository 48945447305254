import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { Carousel } from 'react-bootstrap';
import React, { useCallback } from 'react';
import { HomePageQuery } from '../../../../store/graphql';
import { openPathInNewTab } from '../../../../common/utils/url';
import { useIsMobile } from '../../../../common/hooks/useIsMobile';
import s from './AdBanners.module.scss';

export interface AdBannersProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;

  banners: HomePageQuery['banners'];
}

export const AdBanners: ReactFCC<AdBannersProps> = (props) => {
  const { className, banners } = props;

  const isMobile = useIsMobile();

  const onClick = useCallback((path?: string | null) => {
    if (path) {
      openPathInNewTab({ path });
    }
  }, []);

  return (
    <Carousel className={clsx(s.AdBanners, className)} variant="light" controls={false} interval={null}>
      {banners?.map((banner, index) => (
        <Carousel.Item className={s.AdBanners__item} onClick={() => onClick(banner?.redirectUrl)} key={index}>
          <div className={s.AdBanners__container}>
            {isMobile ? (
              <img
                className={s.AdBanners__banner}
                src={banner.mobileImage?.main_file?.url || ''}
                alt={banner.description || banner.name}
              />
            ) : (
              <img
                className={s.AdBanners__banner}
                src={banner.desktopImage?.main_file?.url || ''}
                alt={banner.description || banner.name}
              />
            )}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};
