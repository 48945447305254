import React, { useEffect } from 'react';
import { isIOS } from 'react-device-detect';
import * as Sentry from '@sentry/react';
import { Auth } from '../widgets/auth';
import { ToastContainer } from '../common/components/ToastContainer/ToastContainer';
import { useWebviewScrollOnTop } from '../common/hooks/useWebviewScrollOnTop';
import { StripeCallbackRedirect } from '../store/payment/StripeCallbackRedirect';
import { sentryDsn } from '../config';
import { AppRoutes } from './routes';

Sentry.init({
  dsn: sentryDsn,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration(), Sentry.httpClientIntegration()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

export const App = () => {
  useWebviewScrollOnTop();
  useEffect(() => {
    document.documentElement.classList.toggle('_ios', isIOS);
  }, []);

  return (
    <React.Fragment>
      <StripeCallbackRedirect>
        <Auth />
        <AppRoutes />
      </StripeCallbackRedirect>
      <ToastContainer />
    </React.Fragment>
  );
};
