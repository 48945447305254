import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { useAddressQuery } from '../../../store/graphql';
import { getGraphQLErrorInfo } from '../../../common/utils/graphqlErrors';
import { ZIP_NOT_FOUND } from '../components/AddressForm/constants';

export interface UseAddressProps {
  countryCode?: string;
  selectedCity?: string;
  zipCode?: string;
}

export const useAddress = (props: UseAddressProps = {}) => {
  const { countryCode, selectedCity, zipCode: zipCodeInput } = props;
  const intl = useIntl();

  const zipCodeNotAllowed = !!zipCodeInput && !/^([A-z0-9- ]{1,32})$/i.test(zipCodeInput);

  const {
    data: addressData,
    loading: addressLoading,
    error
  } = useAddressQuery({
    skip: !countryCode || !zipCodeInput || zipCodeNotAllowed,
    variables: {
      countryCode: countryCode || '',
      zipCode: zipCodeInput || ''
    },
    onError: (error) => {
      const parsedError = getGraphQLErrorInfo(error);
      if (parsedError?.statusCode === 500 || parsedError?.extensions?.code === 'INTERNAL_SERVER_ERROR') {
        toast.error(intl.formatMessage({ id: 'general.issues' }));
      }
    }
  });

  const parsedError = getGraphQLErrorInfo(error);

  const serviceError = parsedError?.statusCode === 500;
  const zipNotFound = parsedError?.extensions?.code === ZIP_NOT_FOUND;

  const addresses = addressData?.addressDirectory.addresses || [];
  const selectedCityIndex = selectedCity ? addresses.findIndex((i) => i.city === selectedCity) : 0;
  const address = addresses[selectedCityIndex] || null;

  const cityOptions = addresses.map((i) => ({
    title: i.city || '',
    value: i.city || ''
  }));

  return {
    address: countryCode ? address : null,
    cityOptions,
    addressLoading,
    serviceError,
    zipNotFound,
    zipCodeNotAllowed
  };
};
