import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactFCC } from 'common/utils/helperTypes';
import { Grid } from 'common/components/Grid/Grid';
import { LinkBack } from 'common/components/LinkBack/LinkBack';
import {
  BankAccountType,
  useBankAccountsQuery,
  useMakeWithdrawRequestMutation,
  useSellerBalanceQuery,
  WithdrawRequestInput
} from 'store/graphql';
import { Heading, HeadingSize } from 'common/components/Heading/Heading';
import { FormInput } from 'common/components/inputs';
import { Text } from 'common/components/Text';
import { Colors } from 'common/utils/colors';
import { formatMoney } from 'common/utils/format';
import { Button } from 'common/components/Button';
import { LoaderBox } from 'common/components/Loader/LoaderBox';
import { useFormik } from 'formik';
import { tryNumber } from '@proscom/ui-utils';
import { toast } from 'react-toastify';
import { clearCache } from 'app/providers/auth-apollo';
import { handleDefaultError } from 'common/utils/handleDefaultError';
import { Loader, LoaderSize } from 'common/components/Loader/Loader';
import { getGraphqlErrorCode, getGraphqlErrorMessage } from 'common/utils/graphqlErrors';
import { exceptions } from '@arisora/common/dist';
import { WALLET_ROUTE } from '../../../common/utils/routes';
import { Head } from '../../../common/components/Head';
import { useUser } from '../../../entities/user';
import { EWalletTabs } from '../wallet/WalletPage';
import { BankCardsLayout } from './components';
import { BankAccountForm } from './components/BankAccountForm';
import { amountValidation, WithdrawSchema, WithdrawSchemaKeys, WithdrawValues } from './schema';
import s from './WithdrawPage.module.scss';

const initialValues = {
  amount: '',
  bankAccountId: 0
};

const mutationOptions = {
  refetchQueries: ['WithdrawRequests', 'SellerBalance'],
  update: clearCache(['getSellerWithdrawRequests', 'getSellerBalance']),
  awaitRefetchQueries: true
};

export const WithdrawPage: ReactFCC = () => {
  const navigate = useNavigate();

  const { user } = useUser();

  const intl = useIntl();

  const { data: bankAccountsData, loading: accountsLoading, refetch: fetchBankAccounts } = useBankAccountsQuery();

  const bankAccounts = useMemo(() => bankAccountsData?.bankAccounts || [], [bankAccountsData?.bankAccounts]);

  const { data: balanceData, loading: balanceLoading } = useSellerBalanceQuery();

  const totalBalance = balanceData?.balance?.total || 0;
  const availableToWithdraw = balanceData?.balance?.availableToWithdraw || 0;

  const [makeWithdrawRequestMutation, { loading: makeWithdrawRequestLoading }] =
    useMakeWithdrawRequestMutation(mutationOptions);

  const [createdNewAccount, setCreatedNewAccount] = useState(false);

  const formik = useFormik<WithdrawValues>({
    initialValues: initialValues as unknown as WithdrawValues, // todo какой то костыль
    validationSchema: WithdrawSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, helpers) => {
      const input: WithdrawRequestInput = {
        withdrawAmount: tryNumber(values.amount, 0),
        bankAccountPresetId: values.bankAccountId
      };

      if (input.withdrawAmount > tryNumber(availableToWithdraw, 0)) {
        formik.setErrors({ amount: intl.formatMessage({ id: 'withdraw.lessThanAvailable' }) });
        return;
      }

      try {
        await makeWithdrawRequestMutation({
          variables: {
            input
          }
        });

        navigate(WALLET_ROUTE, {
          state: {
            defaultTab: EWalletTabs.REQUESTS
          }
        });
        helpers.resetForm();
        toast.success(intl.formatMessage({ id: 'withdraw.requestCreated' }));
      } catch (e) {
        const errCode = getGraphqlErrorCode(e);
        const errMessage = getGraphqlErrorMessage(e);

        switch (errCode) {
          case exceptions.INSUFFICIENT_FUNDS:
            toast.error(intl.formatMessage({ id: 'withdraw.notEnoughMoney' }));
            break;
          default:
            handleDefaultError(`"${intl.formatMessage({ id: 'withdraw.createError' })}" "${errMessage}"`, e);
        }
      } finally {
        if (createdNewAccount) {
          fetchBankAccounts();
          setCreatedNewAccount(false);
        }
      }
    }
  });

  const formSetFieldValue = formik.setFieldValue;
  const formSubmit = formik.submitForm;

  const onCreateBankAccount = useCallback(
    (id: BankAccountType['id']) => {
      formSetFieldValue(WithdrawSchemaKeys.bankAccountId, id);
      setCreatedNewAccount(true);
      setTimeout(() => formSubmit());
    },
    [formSetFieldValue, formSubmit]
  );

  useLayoutEffect(() => {
    if (bankAccounts.length !== 0) {
      formSetFieldValue(WithdrawSchemaKeys.bankAccountId, bankAccounts[0].id);
    }
  }, [bankAccounts, formSetFieldValue]);

  if (!user) {
    return <LoaderBox />;
  }

  return (
    <>
      <Head title={'Withdraw'} />

      <Grid className={s.WithdrawPage}>
        <Grid.GridItem cols={{ xs: 2, lg: 3 }}>
          <LinkBack>
            <FormattedMessage id="general.back" />
          </LinkBack>
        </Grid.GridItem>

        <Grid.GridItem cols={{ xs: 2, lg: 6 }}>
          <Heading className={s.WithdrawPage__title} size={HeadingSize.H3}>
            <FormattedMessage id="profile.withdrawTitle" />
          </Heading>

          <div className={s.WithdrawPage__table}>
            <Text component={'span'} color={Colors.GRAY_600}>
              Your balance
            </Text>
            {balanceLoading ? (
              <Loader size={LoaderSize.SMALL} />
            ) : (
              <Text component={'span'}>{formatMoney(totalBalance)}</Text>
            )}

            <Text component={'span'} color={Colors.GRAY_600}>
              Available for withdrawal
            </Text>
            {balanceLoading ? (
              <Loader size={LoaderSize.SMALL} />
            ) : (
              <Text component={'span'} tooltip={'???'}>
                {/* todo вписать тултипы */}
                {formatMoney(availableToWithdraw)}
              </Text>
            )}
          </div>

          <FormInput
            className={s.WithdrawPage__amountInput}
            type={'number'}
            name={WithdrawSchemaKeys.amount}
            label={'Withdrawal amount'}
            placeholder={'Withdrawal amount'}
            precision={2}
            onChange={formik.handleChange}
            value={formik.values.amount}
            error={formik.errors.amount}
            unitRight={amountValidation.unit}
            disabled={balanceLoading}
            required
          />

          <Heading className={s.WithdrawPage__subtitle} size={HeadingSize.H5}>
            Bank details
          </Heading>

          {accountsLoading ? (
            <LoaderBox />
          ) : bankAccounts.length ? (
            <>
              <BankCardsLayout
                className={s.WithdrawPage__accounts}
                bankAccounts={bankAccounts}
                isLoading={accountsLoading}
                selectedId={formik.values.bankAccountId}
                setSelectedId={(id) => formik.setFieldValue(WithdrawSchemaKeys.bankAccountId, id)}
              />
              <Button onClick={formik.submitForm} loading={makeWithdrawRequestLoading}>
                Send request
              </Button>
            </>
          ) : (
            <BankAccountForm
              onAccountSaved={onCreateBankAccount}
              loading={makeWithdrawRequestLoading}
              validate={formik.validateForm}
            />
          )}
        </Grid.GridItem>
      </Grid>
    </>
  );
};
