import { RouteObject } from 'react-router-dom';
import React from 'react';
import { NotFoundPage } from '../notFound/NotFoundPage';
import { MANUALS_DYNAMIC_ROUTE, MANUALS_ROUTE, PROHIBITED_ITEMS_ROUTE, withRoutes } from '../../common/utils/routes';
import { ManualsLayout } from './_layout/ManualsLayout';
import { ProhibitedItemsPage } from './prohibitedItems/ProhibitedItemsPage';
import { ManualPage } from './manual/ManualPage';

const routes: RouteObject[] = [
  {
    element: <ManualsLayout />,
    children: [
      {
        index: true,
        element: <ManualPage />
      },
      {
        path: MANUALS_DYNAMIC_ROUTE.replace(MANUALS_ROUTE, ''),
        element: <ManualPage />
      },
      {
        path: PROHIBITED_ITEMS_ROUTE.replace(MANUALS_ROUTE, ''),
        element: <ProhibitedItemsPage />
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
    ]
  }
];

export const ManualsRoutes = withRoutes(routes);
