export type DebtOrderType = { entityId: number; orderId: string; variant: OrderDebtVariant };

export enum OrderDebtVariant {
  Action = 'action',
  Payment = 'payment'
}

export const ordersDebtsVariantIntlNames: Record<OrderDebtVariant, string> = {
  [OrderDebtVariant.Action]: 'orders.alertActionDebt',
  [OrderDebtVariant.Payment]: 'orders.alertPaymentDebt'
};
