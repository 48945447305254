import { createContext } from 'react';
import { AuthState } from './authState';

export interface IAuthContext extends AuthState {
  requestCode: (email: string) => Promise<void>;
  login: (email: string, code: string, usePassword?: boolean) => Promise<void>;
  logout: (force?: boolean) => Promise<void>;
  getToken: (props?: { force?: boolean }) => Promise<string | null>;
}

export const AuthContext = createContext<IAuthContext | null>(null);
