import React, { useLayoutEffect, useRef, useState } from 'react';
import { BsCheckCircleFill, BsInfoCircle, BsPrinter, BsSave } from 'react-icons/bs';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { noop } from 'lodash-es';
import { Heading, HeadingSize } from 'common/components/Heading/Heading';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { EOpeningBlockStatus, OpeningBlock } from 'common/components/OpeningBlock/OpeningBlock';
import { Tooltip } from 'common/components/Tooltip/Tooltip';
import { ReactFCC } from 'common/utils/helperTypes';
import { Button, ButtonFit, ButtonVariant } from 'common/components/Button';
import {
  ArisoraOrderStatus,
  FedexStatusEnum,
  OrderPackages,
  useConfirmPackagesSendingMutation,
  useCreateShipmentMutation,
  useReferenceNumberQuery
} from 'store/graphql';
import { clearCache, useAuth } from 'app/providers/auth-apollo';
import { getGraphqlErrorMessage } from 'common/utils/graphqlErrors';
import { handleDefaultError } from 'common/utils/handleDefaultError';
import { Link } from 'common/components/Link/Link';
import ReactToPrint from 'react-to-print';
import QRCode from 'qrcode';
import { useNavigate } from 'react-router';
import { OrderPageBanner } from '../OrderBanner/OrderPageBanner';
import { DELIVERY_ROUTE, PathBuilder } from '../../../../../common/utils/routes';
import { useUser } from '../../../../../entities/user';
import { Text } from '../../../../../common/components/Text';
import { useIsMobile } from '../../../../../common/hooks/useIsMobile';
import { useToggle } from '../../../../../common/hooks/useToggle';
import { useWebview } from '../../../../../store/webview/useWebview';
import { externalApiUrl } from '../../../../../config';
import { Loader, LoaderSize } from '../../../../../common/components/Loader';
import { OrderCombineParcelInput } from '../OrderCombineParcelInput/OrderCombineParcelInput';
import { OrderDimensions } from '../../../../../features/order/yamatoFee/hooks/useYamatoFee';
import { PickupRequestForm } from '../../../../../widgets/pickupRequest/PickupRequestForm';
import { OrderPageCombinePrint } from './OrderCombinePrint/OrderCombinePrint';
import { OrderCombineQRModal } from './OrderCombineQRModal/OrderCombineQRModal';
import s from './OrderPageCombineFedex.module.scss';

export type TOrderPageCombine = {
  orderId: number;
  orderID: string;
  orderStatus: ArisoraOrderStatus;
  packages?: OrderPackages | null;
  refetchOrder?: () => void;
  dimensions: OrderDimensions;
};

const mutationOptions = {
  refetchQueries: ['Order', 'SellerOrder'],
  update: clearCache(['user'])
};

export const OrderPageCombineFedex: ReactFCC<TOrderPageCombine> = (props) => {
  const { orderId, orderStatus, packages, orderID, refetchOrder, dimensions } = props;
  const { user } = useUser();
  const { getToken } = useAuth();
  const addresses = user?.addresses;
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { isWebview, callWebviewMethod } = useWebview();

  const [qrModalOpened, { set: openQrModal, unset: closeQrModal }] = useToggle(false);

  const sellerAddress = addresses?.find((address) => address.sellerAddress);

  const deliveryPriceFulfilled = packages?.deliveryPriceFulfilled;
  const fedexStatus = packages?.fedexStatus;
  const decodedQr = packages?.decodedQr;
  const pickupUuid = packages?.pickupRequestUuid;

  const isPacked = !!pickupUuid;
  const isSent =
    orderStatus === ArisoraOrderStatus.DeliveringWithinJapan ||
    orderStatus === ArisoraOrderStatus.InternationalDelivery ||
    orderStatus === ArisoraOrderStatus.CustomsClearance;

  const [isPackaging, setIsPackaging] = useState(false);

  const [stepOneOpen, setStepOneOpen] = useState(false);
  const [stepTwoOpen, setStepTwoOpen] = useState(false);
  const [stepThreeOpen, setStepThreeOpen] = useState(false);
  const [stepFourOpen, setStepFourOpen] = useState(false);

  const [qrDataURL, setQrDataURL] = useState<string>('');
  const printRef = useRef<HTMLDivElement>(null);

  const [isOneOrderPickup, setIsOneOrderPickup] = useState(false);

  const [createShipmentMutation, { loading: createShipmentLoading }] = useCreateShipmentMutation(mutationOptions);

  const [confirmPickup, { loading: confirmPickupLoading }] = useConfirmPackagesSendingMutation(mutationOptions);

  const {
    data: checkQrData,
    stopPolling: stopQrPolling,
    startPolling: startQrPolling
  } = useReferenceNumberQuery({
    skip: !!qrDataURL,
    variables: {
      orderId
    }
  });

  const qrCodeStepActive = deliveryPriceFulfilled;
  const courierStepActive = !!decodedQr;

  const createShipment = async () => {
    if (!deliveryPriceFulfilled) {
      return;
    }

    try {
      await createShipmentMutation({
        variables: {
          orderId,
          sellerAddressId: sellerAddress?.id || 0
        }
      });
      startQrPolling(8000);
      toast.success(intl.formatMessage({ id: 'profile.createQR' }));
    } catch (e) {
      const errorMessage = getGraphqlErrorMessage(e);

      if (errorMessage) {
        handleDefaultError(errorMessage, e);
      }
    }
  };

  useLayoutEffect(() => {
    if (decodedQr) {
      QRCode.toDataURL(decodedQr, { margin: 0, scale: 10 }).then((data) => setQrDataURL(data));
    }
  }, [decodedQr]);

  useLayoutEffect(() => {
    if (checkQrData?.result?.referenceNumber || checkQrData?.result?.status === FedexStatusEnum.Initial) {
      refetchOrder?.();
      stopQrPolling();
    }
  }, [checkQrData, refetchOrder, stopQrPolling]);

  const intl = useIntl();

  return (
    <>
      {isPackaging || isPacked || deliveryPriceFulfilled ? (
        <OrderPageBanner>
          {isSent ? (
            <>
              <Heading size={HeadingSize.H5}>
                <FormattedMessage id={'orderCombine.thanks'} />
              </Heading>
              <OrderPageBanner.Text>
                <FormattedMessage id={'orderCombine.thanksSent'} />
              </OrderPageBanner.Text>
            </>
          ) : (
            <>
              <Heading size={HeadingSize.H5}>
                <FormattedMessage id={'orderCombine.sendingOrder'} />
              </Heading>

              <OrderPageBanner.Text>
                <FormattedMessage
                  id={'orderCombine.instructions'}
                  values={{
                    a: (chunks) => <Link to={DELIVERY_ROUTE}>{chunks}</Link>
                  }}
                />
              </OrderPageBanner.Text>
            </>
          )}

          <Space size={ESpaceSize.PIXEL_24} />

          <OpeningBlock
            title={intl.formatMessage({ id: 'orderCombine.recalculate' })}
            status={deliveryPriceFulfilled ? EOpeningBlockStatus.DONE : EOpeningBlockStatus.ACTIVE}
            number={1}
            isOpen={stepOneOpen || !!deliveryPriceFulfilled}
            setOpen={setStepOneOpen}
          >
            <div>
              <FormattedMessage id={'orderCombine.parcelSpecification'} />
              <Tooltip text={intl.formatMessage({ id: 'orderCombine.boxPriority' })}>
                <Tooltip.Container>
                  <BsInfoCircle />
                </Tooltip.Container>
              </Tooltip>
            </div>

            <Space size={ESpaceSize.PIXEL_16} />

            <div className={s.OrderPageCombineFedex__sendingForm}>
              <div className={s.OrderPageCombineFedex__newDimensionsForm}>
                {sellerAddress && packages !== undefined && (
                  <OrderCombineParcelInput
                    sellerAddressId={sellerAddress.id}
                    orderId={orderId}
                    isSent={isSent}
                    packages={packages}
                    dimensions={dimensions}
                    trustedOrder={true}
                    initialShipFee={0}
                  />
                )}
              </div>
            </div>
          </OpeningBlock>

          <Space size={ESpaceSize.PIXEL_16} />

          <OpeningBlock
            title={intl.formatMessage({ id: 'orderCombine.qrGenerate' })}
            status={
              !!decodedQr
                ? EOpeningBlockStatus.DONE
                : qrCodeStepActive
                ? EOpeningBlockStatus.ACTIVE
                : EOpeningBlockStatus.DISABLED
            }
            number={2}
            isOpen={stepTwoOpen || !!qrCodeStepActive}
            setOpen={setStepTwoOpen}
          >
            {(!fedexStatus || fedexStatus === FedexStatusEnum.Initial) && (
              <div>
                {decodedQr
                  ? intl.formatMessage({ id: 'orderCombine.showToCourier' })
                  : intl.formatMessage({ id: 'orderCombine.afterQR' })}
              </div>
            )}

            <Space size={ESpaceSize.PIXEL_16} />

            {!decodedQr ? (
              <div className={s.OrderPageCombineFedex__sendingForm}>
                {!fedexStatus || !(fedexStatus === FedexStatusEnum.Pending) ? (
                  <Button
                    className={s.OrderPageCombineFedex__sendingFormButton}
                    variant={ButtonVariant.PRIMARY}
                    onClick={createShipment}
                    loading={createShipmentLoading}
                    disabled={!!decodedQr}
                  >
                    <FormattedMessage id={'orderCombine.qrGenerateButton'} />
                  </Button>
                ) : (
                  <div className={s.OrderPageCombineFedex__qrBlock_prepare}>
                    <FormattedMessage id={'orderCombine.qrPreparing'} />
                    <Loader size={LoaderSize.SMALL} />
                  </div>
                )}
              </div>
            ) : (
              <div className={s.OrderPageCombineFedex__qrBlock}>
                <div className={s.OrderPageCombineFedex__qrBlock_content}>
                  <Heading size={HeadingSize.H5}>
                    <FormattedMessage id={'orderCombine.shipmentReference'} />
                  </Heading>
                  <Text className={s.OrderPageCombineFedex__qrBlock_referenceNumber}>{decodedQr}</Text>
                  <Space size={ESpaceSize.PIXEL_16} />
                  <img
                    src={qrDataURL}
                    className={s.OrderPageCombineFedex__qrBlock_image}
                    onClick={isMobile ? () => openQrModal() : noop}
                    alt={decodedQr}
                  />
                  <Space size={ESpaceSize.PIXEL_24} />
                </div>
                <div className={s.OrderPageCombineFedex__qrBlock_controls}>
                  {isWebview ? (
                    <Button
                      fit={ButtonFit.FILL}
                      onClick={async () => {
                        const token = await getToken();
                        callWebviewMethod('saveOrderPackages', [
                          `${externalApiUrl}/documents/packages`,
                          token,
                          orderId,
                          sellerAddress?.id
                        ]);
                      }}
                    >
                      <BsSave /> <FormattedMessage id={'orderCombine.save'} />
                    </Button>
                  ) : (
                    <>
                      {/* TODO Download PDF externally */}
                      <ReactToPrint
                        bodyClass={s.OrderPageCombineFedex__printContent}
                        content={() => printRef.current}
                        trigger={() => (
                          <Button
                            variant={ButtonVariant.PRIMARY_OUTLINE}
                            fit={ButtonFit.FILL}
                            className={s.OrderPageCombineFedexWarehouse__addressPrint_button}
                          >
                            <BsPrinter /> <FormattedMessage id={'orderCombine.print'} />
                          </Button>
                        )}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
          </OpeningBlock>
          <Space size={ESpaceSize.PIXEL_16} />

          <OpeningBlock
            title={intl.formatMessage({ id: 'orderCombine.createPickup' })}
            status={
              isPacked
                ? EOpeningBlockStatus.DONE
                : courierStepActive
                ? EOpeningBlockStatus.ACTIVE
                : EOpeningBlockStatus.DISABLED
            }
            number={3}
            isOpen={courierStepActive || isPacked || stepThreeOpen}
            setOpen={setStepThreeOpen}
          >
            <div>
              {isPacked ? (
                <div className={s.OrderPageCombineFedex__pickupRequestInfo}>
                  <FormattedMessage id={'orderCombine.createdPickup'} />
                  <Button
                    onClick={() => navigate(PathBuilder.getPickupRequestPath(pickupUuid))}
                    variant={ButtonVariant.PRIMARY_OUTLINE}
                  >
                    <FormattedMessage id={'orderCombine.openPickupPage'} />
                  </Button>
                </div>
              ) : (
                <>
                  <FormattedMessage
                    id={'orderCombine.pickupGuide'}
                    values={{
                      br: () => <br></br>
                    }}
                  />
                  <Space size={ESpaceSize.PIXEL_16} />
                </>
              )}
            </div>

            {!isPacked && <PickupRequestForm readyOrderIds={[orderId]} nextStep={true} />}
          </OpeningBlock>

          <Space size={ESpaceSize.PIXEL_16} />

          <OpeningBlock
            title={intl.formatMessage({ id: 'orderCombine.confirmSending' })}
            status={
              isSent ? EOpeningBlockStatus.DONE : isPacked ? EOpeningBlockStatus.ACTIVE : EOpeningBlockStatus.DISABLED
            }
            number={4}
            isOpen={!!pickupUuid || isSent || stepFourOpen}
            setOpen={setStepFourOpen}
          >
            <div>
              {isSent
                ? intl.formatMessage({ id: 'orderCombine.sendingConfirmed' })
                : intl.formatMessage({ id: 'orderCombine.sendingGuide' })}
            </div>

            <Space size={ESpaceSize.PIXEL_16} />

            <div className={s.OrderPageCombineFedex__sendingForm}>
              <Button
                className={s.OrderPageCombineFedex__sendingFormButton}
                variant={isSent ? ButtonVariant.SUCCESS_OUTLINE : ButtonVariant.PRIMARY}
                onClick={
                  isSent || !pickupUuid
                    ? noop
                    : () => confirmPickup({ variables: { input: { orderIds: [orderId], pickupUuid } } })
                }
                loading={confirmPickupLoading}
                disabled={isSent}
                leftIcon={isSent ? BsCheckCircleFill : undefined}
              >
                {isSent
                  ? intl.formatMessage({ id: 'orderCombine.orderSent' })
                  : intl.formatMessage({ id: 'orderCombine.confirmSending' })}
              </Button>
            </div>
          </OpeningBlock>
        </OrderPageBanner>
      ) : (
        <OrderPageBanner>
          <Heading size={HeadingSize.H5}>
            <FormattedMessage id="profile.orderCombineAlertTitle" />
          </Heading>

          <OrderPageBanner.Text>
            <FormattedMessage id="profile.orderCombineAlertText" />
          </OrderPageBanner.Text>

          <OrderPageBanner.Actions>
            <Button variant={ButtonVariant.PRIMARY} onClick={() => setIsPackaging(true)}>
              <FormattedMessage id="profile.orderCombineAlertButton" />
            </Button>
          </OrderPageBanner.Actions>
        </OrderPageBanner>
      )}
      {/* helpers */}
      <OrderCombineQRModal qrDataURL={qrDataURL} isOpen={qrModalOpened} onClose={closeQrModal} />
      {orderID && !!qrDataURL && decodedQr && (
        <OrderPageCombinePrint
          orderID={orderID}
          qrDataURL={qrDataURL}
          className={s.OrderPageCombineFedex__qrBlock_print}
          decodedQr={decodedQr}
          ref={printRef}
        />
      )}
    </>
  );
};
