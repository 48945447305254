import { FormattedMessage } from 'react-intl';
import React from 'react';
import { useNavigate } from 'react-router';
import { Heading, HeadingSize } from '../../../common/components/Heading';
import s from './SupportCard.module.scss';

export type CardItem = {
  link: string;
  titleId: string;
};
export type SupportCardProps = {
  headingId: string;
  icon: React.ReactNode;
  link: string;
  items?: CardItem[];
};

export const SupportCard = (props: SupportCardProps) => {
  const { link, items, headingId, icon } = props;
  const navigate = useNavigate();
  return (
    <div className={s.SupportCard__badge}>
      <Heading
        size={HeadingSize.H5}
        className={s.SupportCard__heading}
        onClick={(e) => {
          e.stopPropagation();
          navigate(link);
        }}
      >
        <FormattedMessage id={headingId} />
        {icon}
      </Heading>
      <div className={s.SupportCard__badge_textCols}>
        {items &&
          items.length > 0 &&
          items.map((item, index) => (
            <span
              className={s.SupportCard__badge_helpText}
              onClick={(e) => {
                e.stopPropagation();
                navigate(item.link);
              }}
            >
              <FormattedMessage id={`${item.titleId}`} />
            </span>
          ))}
      </div>
    </div>
  );
};
