import { ReactFCC } from 'common/utils/helperTypes';
import { SpecificAttributeInput } from 'store/graphql';
import React from 'react';
import { tryNumber } from '@proscom/ui-utils';
import { BaseCatalogFilter } from '../../CatalogFilter';
import { useCatalogFiltersSpecificAttributes } from './useCatalogFiltersSpecificAttributes';

export interface CatalogFiltersSpecificAttributesProps {
  /**
   * Дополнительный css-класс
   */
  classes?: {
    filter?: string;
    items?: string;
    item?: string;
  };
  /**
   * ID под-подкатегории, по которой запрашивается список атрибутов для фильтрации
   */
  subSubCategoryId?: number;
  /**
   * Доступные значения для фильтров
   */
  specificAttributeValues: SpecificAttributeInput[];
  /**
   * Сеттер выбранных значений для определенного атрибута
   */
  setSpecificAttributeValues: React.Dispatch<React.SetStateAction<SpecificAttributeInput[]>>;
}

export const CatalogFiltersSpecificAttributes: ReactFCC<CatalogFiltersSpecificAttributesProps> = (props) => {
  const { classes, subSubCategoryId, specificAttributeValues, setSpecificAttributeValues } = props;

  const { specificAttributesOptions, toggleAttributeValue } = useCatalogFiltersSpecificAttributes({
    subSubCategoryId,
    specificAttributeValues,
    setSpecificAttributeValues
  });

  return (
    <>
      {specificAttributesOptions.map((attribute) => (
        <BaseCatalogFilter
          className={classes?.filter}
          classes={{
            items: classes?.items,
            item: classes?.item
          }}
          title={attribute.name}
          items={attribute.variants || []}
          active={
            specificAttributeValues
              .find((value) => value.attributeId === attribute.id)
              ?.variantIds.map((v) => v.toString()) || []
          }
          setActive={(value) =>
            toggleAttributeValue(
              attribute.id,
              value.map((v) => tryNumber(v, 0))
            )
          }
          key={attribute.id}
        />
      ))}
    </>
  );
};
