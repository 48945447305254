import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { BsPatchQuestion } from 'react-icons/bs';
import { Grid } from '../../../common/components/Grid';
import { LinkBack } from '../../../common/components/LinkBack';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import { HELP_ROUTE, SUPPORT_HUB_ROUTE } from '../../../common/utils/routes';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { ArticleTypeEnum, useArticlesMetaQuery } from '../../../store/graphql';
import { Loader } from '../../../common/components/Loader';
import { HelpNavLink } from './HelpNavLink';
import s from './HelpLayout.module.scss';

export const HelpLayout: React.FC = () => {
  const location = useLocation();

  const isMobile = useIsMobile();

  const { data: helpMetaQuery, loading: helpMetaLoading } = useArticlesMetaQuery({
    variables: {
      input: {
        type: ArticleTypeEnum.Help
      }
    }
  });

  const helpMeta = helpMetaQuery?.result || [];

  return (
    <Grid className={location.pathname === HELP_ROUTE && isMobile ? s.HelpLayout__mobile : s.HelpLayout} cols={12}>
      <Grid.GridItem
        cols={{ xs: 2, lg: 3, xl: 3 }}
        className={location.pathname === HELP_ROUTE || !isMobile ? s.HelpLayout__content : ''}
      >
        {location.pathname === HELP_ROUTE || !isMobile ? (
          <>
            <div className={s.HelpLayout__back}>
              <LinkBack to={SUPPORT_HUB_ROUTE}>
                <FormattedMessage id="general.back" />
              </LinkBack>
            </div>
            <div className={s.HelpLayout__badge}>
              <BsPatchQuestion size={16} />
              <span className={s.HelpLayout__badge_text}>
                <FormattedMessage id={'footer.help'} />
              </span>
            </div>
            {helpMetaLoading ? (
              <div className={s.HelpLayout__loader}>
                <Loader />
              </div>
            ) : (
              <>
                {[
                  ...(helpMeta?.map((a) => (
                    <HelpNavLink to={a.slug} end>
                      {a.name}
                    </HelpNavLink>
                  )) || [])
                ]}
              </>
            )}
          </>
        ) : (
          <>
            <LinkBack>
              <FormattedMessage id="general.back" />
            </LinkBack>

            <Space size={ESpaceSize.PIXEL_24} />
          </>
        )}
      </Grid.GridItem>

      <Grid.GridItem cols={{ xs: 2, lg: 9 }} start={{ xs: 1, lg: 5 }}>
        <Outlet />
      </Grid.GridItem>
    </Grid>
  );
};
