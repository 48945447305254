import React from 'react';

import { Navigate } from 'react-router-dom';
import { EMDASH, tryNumber } from '@proscom/ui-utils';
import { FormattedMessage } from 'react-intl';
import { ReactFCC } from '../../common/utils/helperTypes';
import { Rating, useCustomerPublicInfoQuery, useSellerPublicInfoQuery } from '../../store/graphql';
import { LoaderBox } from '../../common/components/Loader';
import { useUrlParam } from '../../common/hooks/useUrlParam';
import { NOT_FOUND_ROUTE, PUBLIC_PAGE_PARAM } from '../../common/utils/routes';
import { Head } from '../../common/components/Head';
import { LinkBack } from '../../common/components/LinkBack';
import { ESpaceSize, Space } from '../../common/components/Space/Space';
import { Grid } from '../../common/components/Grid';
import { UserAvatar, UserInfo, useUser } from '../../entities/user';
import { EBreakpoints, useBreakpoint } from '../../common/hooks/useBreakpoint';
import { Heading, HeadingSize } from '../../common/components/Heading';
import { UserPageContent } from './content/UserPageContent';
import s from './content/UserPageContent.module.scss';

export type TUserPage = {
  isSeller?: boolean;
  isInternalPage?: boolean;
};

const defaultRating: Rating = {
  positive: 0,
  neutral: 0,
  negative: 0
};

export const UserPage: ReactFCC<TUserPage> = (props) => {
  const { isSeller, isInternalPage } = props;

  const { user } = useUser();

  const isCustomer = !isSeller || (!user?.seller && isInternalPage);

  //Made for profile layout
  const isInternalSeller = (!!user?.seller && isInternalPage) || false;

  const id = tryNumber(useUrlParam(PUBLIC_PAGE_PARAM) || (!!user?.seller ? user?.sellerId : user?.id), 0);

  const isMobile = useBreakpoint(EBreakpoints.LG_DOWN);

  const {
    data: customerPublicQuery,
    loading: customerLoading,
    called: customerCalled
  } = useCustomerPublicInfoQuery({
    variables: {
      input: {
        ids: isCustomer && id ? [id] : undefined
      }
    },
    skip: !id || isSeller || isInternalSeller,
    fetchPolicy: 'cache-and-network'
  });

  const customer = customerPublicQuery?.customer?.customers?.entries[0];

  const {
    data: sellerPublicQuery,
    loading: sellerLoading,
    called: sellerCalled
  } = useSellerPublicInfoQuery({
    skip: !isSeller && !isInternalSeller,
    variables: {
      input: {
        ids: (isSeller || isInternalSeller) && id ? [id] : undefined
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const seller = sellerPublicQuery?.seller?.sellers?.entries[0];

  if (
    !id ||
    (!seller?.nickname && !sellerLoading && sellerCalled) ||
    (!customer?.nickname && !customerLoading && customerCalled)
  ) {
    return <Navigate to={NOT_FOUND_ROUTE} replace />;
  }

  if (sellerLoading || customerLoading) {
    return <LoaderBox />;
  }

  return (
    <>
      <Head
        title={
          isSeller || isInternalSeller
            ? `${seller?.nickname} ${EMDASH} Sellers`
            : `${customer?.nickname} ${EMDASH} Users`
        }
      />
      {isInternalPage && (
        <Heading size={HeadingSize.H3}>
          <FormattedMessage id={'reviews.publicPage'} />
        </Heading>
      )}
      <Space size={ESpaceSize.PIXEL_32} />

      {!isInternalPage && (
        <>
          <LinkBack>
            <FormattedMessage id="general.back" />
          </LinkBack>
          <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />
        </>
      )}
      <Grid cols={{ xs: 1, md: 12, lg: 12 }}>
        {(((isSeller || isInternalSeller) && seller) || (isCustomer && customer)) && (
          <Grid.GridItem cols={{ xs: 1, md: 12, xl: 12 }}>
            <div className={s.UserPageContent__userCard}>
              <UserAvatar
                src={seller?.avatar?.thumbnails?.M?.url || customer?.avatar?.thumbnails?.M?.url || ''}
                className={s.UserPageContent__userAvatar}
                isTrusted={seller?.isTrusted}
                isSeller={!!seller}
                isOwnPage={isInternalPage}
              />
              <UserInfo
                publicUser={{
                  nickname: seller?.nickname || customer?.nickname || EMDASH,
                  ratings: {
                    positive: seller?.rating?.positive || customer?.customerRating?.positive || 0,
                    neutral: seller?.rating?.neutral || customer?.customerRating?.neutral || 0,
                    negative: seller?.rating?.negative || customer?.customerRating?.negative || 0
                  }
                }}
              />
            </div>
          </Grid.GridItem>
        )}

        {(isSeller || isInternalSeller) && (
          <Grid.GridItem cols={{ xs: 1, md: 12, xl: 12 }}>
            <UserPageContent
              id={id}
              nickname={seller?.nickname || EMDASH}
              avatarUrl={seller?.avatar?.thumbnails?.M?.url || ''}
              sellerRating={seller?.rating || defaultRating}
              isTrusted={seller?.isTrusted}
              isSeller
            />
          </Grid.GridItem>
        )}

        {isCustomer && customer && (
          <Grid.GridItem cols={{ xs: 1, md: 12, xl: 12 }}>
            <UserPageContent
              id={id}
              nickname={customer?.nickname || EMDASH}
              avatarUrl={customer?.avatar?.thumbnails?.M?.url || ''}
              customerRating={customer?.customerRating || defaultRating}
              sellerRating={customer?.customerRating || defaultRating}
            />
          </Grid.GridItem>
        )}
      </Grid>
    </>
  );
};
