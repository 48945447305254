import { tryNumber } from '@proscom/ui-utils';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useCatalogCategoryPathQuery, useCatalogNavigationQuery } from 'store/graphql';
import { CATEGORY_PAGE_PARAM } from '../../../common/utils/routes';

export const useNavigation = () => {
  const { [CATEGORY_PAGE_PARAM]: categoryId } = useParams();
  const { data, loading } = useCatalogNavigationQuery({
    variables: { id: tryNumber(categoryId) },
    skip: false
  });

  const { data: pathQuery, loading: pathLoading } = useCatalogCategoryPathQuery({
    skip: !categoryId,
    variables: { id: tryNumber(categoryId, 0) }
  });

  const categories = data?.categories?.categories;
  const category = useMemo(() => categories?.find((i) => i.id === tryNumber(categoryId)), [categories, categoryId]);
  const subCategories = category?.children || [];
  const categoryPath = pathQuery?.path.path || [];

  return {
    categories,
    subCategories,
    category,
    categoryPath,
    navigationLoading: loading || pathLoading
  };
};
