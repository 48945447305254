import React, { useRef } from 'react';
import { PayPalButtons, usePayPalScriptReducer, FUNDING } from '@paypal/react-paypal-js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { CUSTOMER_ORDERS_ROUTE, PathBuilder } from 'arisora-web/src/common/utils/routes';
import {
  OnClickActions,
  CreateOrderActions,
  OnApproveActions,
  OnCancelledActions,
  CreateOrderData,
  OnApproveData
} from '@paypal/paypal-js/types/components/buttons';
import { useIntl } from 'react-intl';
import { LoaderBox } from '../../../../common/components/Loader';

import {
  OrderPaymentTypeEnum,
  useConfirmOrderMutation,
  UserAddressType,
  IntentsEnum,
  useAuthorizeOrderProcessMutation,
  useCreatePaypalOrderIntentProcessMutation,
  useUpsertPaypalOrderMutation,
  PaypalOrderStatusEnum,
  useCancelDebtTransactionsMutation
} from '../../../../store/graphql';
import { GoogleTagEvents, googleTagSendDefaultEvent } from '../../../../features/analytics';
import { handleDefaultError } from '../../../../common/utils/handleDefaultError';
import { getGraphqlErrorMessage } from '../../../../common/utils/graphqlErrors';
import { clearCache } from '../../../../app/providers/auth-apollo';
import { useCart } from '../../../../store/cart';
import { useUser } from '../../../../entities/user';

export interface PaymentButtonProps {
  orderPrice: number;
  order_id?: number;
  debtId?: number;
  productIds?: number[];
  deliveryPrice?: number;
  buyNow?: boolean;
  shippingAddressId?: number;
  billingAddressId?: number;
  currency: string;
  intent: IntentsEnum;
  onSuccess?: () => void;
}

export const PaymentButton = ({
  orderPrice,
  order_id,
  debtId,
  productIds,
  deliveryPrice,
  buyNow,
  shippingAddressId,
  billingAddressId,
  currency,
  intent,
  onSuccess
}: PaymentButtonProps) => {
  const navigate = useNavigate();

  const { setLocalProductIds } = useCart();
  const { user } = useUser();
  const [{ isPending }] = usePayPalScriptReducer();
  const intl = useIntl();

  const confirmOrderMutationOptions = {
    refetchQueries: ['Cart', 'CartLength', 'IsProductInCart', 'CatalogProducts', 'CustomerOrders', 'Order'],
    update: clearCache([
      'getUserCart',
      'getUserCartLength',
      'isProductInCart',
      'getProducts',
      'getCustomerOrders',
      'order'
    ])
  };
  const authorizeProcessMutationOptions = {
    refetchQueries: ['CustomerOrders', 'Order'],
    update: clearCache(['getCustomerOrders', 'order'])
  };
  const [confirmOrderMutation] = useConfirmOrderMutation(confirmOrderMutationOptions);
  const [createPaypalOrderIntentProcessMutation] = useCreatePaypalOrderIntentProcessMutation();
  const [authorizeOrderProcessMutation] = useAuthorizeOrderProcessMutation(authorizeProcessMutationOptions);
  const [upsertPaypalOrderMutation] = useUpsertPaypalOrderMutation();
  const [cancelDebtTransactionsMutation] = useCancelDebtTransactionsMutation();

  const currentOrderRef = useRef(0);

  /** Обработка клика по кнопке
   * Если нет предварительного заказа для оплаты, то оплата не запускается
   * Новый заказ создаётся с флагом is_paid = false
   */
  const onClickButton = async (data: Record<string, unknown>, actions: OnClickActions) => {
    if (order_id) {
      currentOrderRef.current = order_id;
    } else {
      await confirmOrderPaypal(shippingAddressId || 0, billingAddressId || 0);
    }

    if (!currentOrderRef.current) {
      return actions.reject();
    }
    return actions.resolve();
  };

  /** Обработка создания ордера в PayPal
   * Создаёт заказ в PayPal для авторизации или захвата
   */
  const onCreateOrder = async (data: CreateOrderData, actions: CreateOrderActions) => {
    try {
      const newOrder = await createPaypalOrderIntentProcessMutation({
        variables: {
          input: {
            order_id: currentOrderRef.current,
            amount: orderPrice,
            currency: currency,
            intent: intent,
            userId: user?.id as number,
            debtId: debtId
          }
        }
      });
      const newOrderId = newOrder.data?.orderId;

      return `${newOrderId}`;
    } catch (e: any) {
      toast.error(intl.formatMessage({ id: 'payment.transactionAbortedError' }));
      return e.message;
    }
  };

  /** Обработка создания ордера в БД
   * Создаёт авторизованный заказ в БД на основе данных из PayPal
   * Переводит заказ клиента в оплаченный статус
   *
   * Перед авторизацией ордера проверяем, что email плательщика и email юзера
   * совпадают. Если нет, то не авторизуем order Paypal и в БД ничего не сохраняем. Статус заказа остаётся
   * не оплачен
   */
  const onApproveOrder = async (data: OnApproveData, actions: OnApproveActions) => {
    try {
      const authorizeOrderProcess = await authorizeOrderProcessMutation({
        variables: {
          input: {
            paypal_order_id: data.orderID,
            order_id: currentOrderRef.current,
            debtId: debtId,
            userId: user?.id as number
          }
        }
      });
      if (authorizeOrderProcess.data?.result) {
        onSuccess?.();
        toast.success(intl.formatMessage({ id: 'payment.transactionCompleted' }));
      } else {
        toast.error(intl.formatMessage({ id: 'general.errorIsOccurred' }));
      }
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      navigate(PathBuilder.getOrderPath(currentOrderRef.current));
    }
  };

  /** Обработка события закрытия окна оплаты PayPal
   * Меняет созданному в БД orderPaypal на CANCELLED
   * При этом неавторизованные заказы в Paypal отменяются автоматически
   */
  const onCancelOrder = async (data: Record<string, unknown>, actions: OnCancelledActions) => {
    toast.error(intl.formatMessage({ id: 'payment.transactionAborted' }));
    await upsertPaypalOrderMutation({
      variables: {
        input: {
          amount: orderPrice,
          order_id: currentOrderRef.current,
          paypal_order_id: data.orderID as string,
          status: PaypalOrderStatusEnum.Cancelled
        }
      }
    });
    if (currentOrderRef.current > 0) {
      navigate(PathBuilder.getOrderPath(currentOrderRef.current));
    } else {
      navigate(CUSTOMER_ORDERS_ROUTE);
    }
  };

  // TODO рассмотреть другие кейсы
  const onError = async (err: Record<string, unknown>): Promise<void> => {
    toast.error('Transaction error');
    if (debtId && order_id) {
      cancelDebtTransactionsMutation({
        variables: {
          orderId: order_id
        }
      }).then();
    }
  };

  const confirmOrderPaypal = async (
    shippingAddressId: UserAddressType['id'],
    billingAddressId: UserAddressType['id']
  ) => {
    try {
      if (!shippingAddressId || !billingAddressId) {
        return;
      }
      googleTagSendDefaultEvent(GoogleTagEvents.order);
      const result = await confirmOrderMutation({
        variables: {
          input: {
            productIds,
            deliveryCost: deliveryPrice || 0,
            shippingAddressId,
            billingAddressId,
            buyNow,
            paymentType: OrderPaymentTypeEnum.Paypal
          }
        }
      });
      const newOrderId = result.data?.confirmed?.createdOrderId;
      if (!newOrderId) {
        handleDefaultError(intl.formatMessage({ id: 'general.somethingWrong' }));
      } else {
        currentOrderRef.current = +newOrderId;
        googleTagSendDefaultEvent(GoogleTagEvents.purchase, {
          products: productIds,
          cost: orderPrice || 0
        });
        setLocalProductIds([]);
        toast.success(intl.formatMessage({ id: 'order.orderProcessed' }));

        return newOrderId;
      }
    } catch (e) {
      const errMessage = getGraphqlErrorMessage(e);
      if (errMessage) {
        handleDefaultError(errMessage, e);
      }
    }
  };

  return (
    <>
      {isPending ? (
        <LoaderBox />
      ) : (
        <PayPalButtons
          style={{ layout: 'vertical', color: 'blue' }}
          createOrder={(data, actions) => onCreateOrder(data, actions)}
          onApprove={(data, actions) => onApproveOrder(data, actions)}
          onCancel={(data, actions) => onCancelOrder(data, actions)}
          onClick={(data, actions) => onClickButton(data, actions)}
          onError={(err) => onError(err)}
          fundingSource={FUNDING.PAYPAL}
        />
      )}
    </>
  );
};
