import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { BsBriefcase } from 'react-icons/bs';
import { Grid } from '../../../common/components/Grid';
import { LinkBack } from '../../../common/components/LinkBack';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import { ABOUT_ROUTE, LAW_ROUTE, SUPPORT_HUB_ROUTE } from '../../../common/utils/routes';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { ArticleTypeEnum, useArticlesMetaQuery } from '../../../store/graphql';
import { Loader } from '../../../common/components/Loader';
import { HelpNavLink } from './HelpNavLink';
import s from './AboutLayout.module.scss';

export const AboutLayout: React.FC = () => {
  const location = useLocation();
  const isMobile = useIsMobile();

  const { data: aboutMetaQuery, loading: aboutMetaLoading } = useArticlesMetaQuery({
    variables: {
      input: {
        type: ArticleTypeEnum.Info
      }
    }
  });

  const aboutMeta = aboutMetaQuery?.result || [];

  return (
    <Grid className={location.pathname === ABOUT_ROUTE && isMobile ? s.AboutLayout__mobile : s.AboutLayout} cols={12}>
      <Grid.GridItem
        cols={{ xs: 2, lg: 3, xl: 3 }}
        className={location.pathname === ABOUT_ROUTE || !isMobile ? s.AboutLayout__content : ''}
      >
        {location.pathname === ABOUT_ROUTE || !isMobile ? (
          <>
            <div className={s.AboutLayout__back}>
              <LinkBack to={SUPPORT_HUB_ROUTE}>
                <FormattedMessage id="general.back" />
              </LinkBack>
            </div>
            <div className={s.AboutLayout__badge}>
              <BsBriefcase size={16} />
              <span className={s.AboutLayout__badge_text}>
                <FormattedMessage id={'footer.aboutUs'} />
              </span>
            </div>
            {aboutMetaLoading ? (
              <div className={s.AboutLayout__loader}>
                <Loader />
              </div>
            ) : (
              <>
                {[
                  ...(aboutMeta?.map((a) => (
                    <HelpNavLink to={a.slug} end>
                      {a.name}
                    </HelpNavLink>
                  )) || []),
                  <HelpNavLink to={LAW_ROUTE} end>
                    <FormattedMessage id={'footer.linkLaw'} />
                  </HelpNavLink>
                ]}
              </>
            )}
          </>
        ) : (
          <>
            <LinkBack>
              <FormattedMessage id="general.back" />
            </LinkBack>

            <Space size={ESpaceSize.PIXEL_24} />
          </>
        )}
      </Grid.GridItem>

      <Grid.GridItem cols={{ xs: 2, lg: 9 }} start={{ xs: 1, lg: 5 }}>
        <Outlet />
      </Grid.GridItem>
    </Grid>
  );
};
