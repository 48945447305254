import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { DebtType, useConfirmDebtPaymentMutation } from 'store/graphql';
import { Heading, HeadingSize } from 'common/components/Heading/Heading';
import { Button, ButtonFit, ButtonVariant } from 'common/components/Button/Button/Button';
import { Alert } from 'common/components/Alert/Alert';
import { currencyCode } from 'store/intl';
import { usePayment } from 'store/payment/usePayment';
import { clearCache } from 'app/providers/auth-apollo';
import { handleDefaultError } from 'common/utils/handleDefaultError';
import { getGraphqlErrorMessage } from 'common/utils/graphqlErrors';
import { PaymentAddButton } from 'common/components/payment/PaymentAddButton/PaymentAddButton';
import { Modal } from 'common/components/Modal';
import { useToggle } from 'common/hooks/useToggle';
import { CustomerServiceModal } from '../../../../../features/order/customerService/components/CustomerServiceModal';
import { useIsMobile } from '../../../../../common/hooks/useIsMobile';
import s from './OrderDebtAlert.module.scss';

const confirmDebtPaymentMutationOptions = {
  refetchQueries: ['Debts', 'Order'],
  update: clearCache(['getDebts', 'getOrderById'])
};

export interface OrderDebtAlertProps {
  debt: DebtType;
  orderID?: string;
}

export const OrderDebtAlert = ({ debt, orderID }: OrderDebtAlertProps) => {
  const debtId = debt.id;

  const location = useLocation();

  const intl = useIntl();

  const isMobile = useIsMobile();

  const [modalOpen, { set: openModal, unset: closeModal }] = useToggle();

  const [customerServiceModalOpen, { set: openCustomerServiceModal, unset: closeCustomerServiceModal }] =
    useToggle(false);

  const { hasPaymentMethod, addPaymentMethod, addPaymentLoading, intentPayment, intentPaymentLoading } = usePayment();

  const [confirmDebtPaymentMutation, { loading: confirmDebtPaymentLoading }] = useConfirmDebtPaymentMutation(
    confirmDebtPaymentMutationOptions
  );

  const confirmDeptPayment = useCallback(async () => {
    try {
      if (!(await intentPayment(debtId.toString()))) return;

      const result = await confirmDebtPaymentMutation({
        variables: {
          debtId
        }
      });

      if (!result.data?.confirmed) {
        handleDefaultError(intl.formatMessage({ id: 'general.somethingWrong' }));
        return;
      }

      toast.success(intl.formatMessage({ id: 'order.debtProcessed' }));

      closeModal();
    } catch (e) {
      const errMessage = getGraphqlErrorMessage(e);

      if (errMessage) {
        handleDefaultError(errMessage, e);
      }
    }
  }, [debtId, intentPayment, confirmDebtPaymentMutation, closeModal]);

  const handleAddPaymentMethod = () => {
    return addPaymentMethod({
      successPath: location.pathname,
      cancelPath: location.pathname
    });
  };

  const { formatNumber } = useIntl();
  const debtAmount = formatNumber(debt.amount || 0, {
    style: 'currency',
    currency: currencyCode
  });

  const confirmLoading = confirmDebtPaymentLoading || intentPaymentLoading;

  return (
    <Alert className={s.OrderDebtAlert}>
      <div className={s.OrderDebtAlert__title}>
        <Heading size={HeadingSize.H5}>
          <FormattedMessage id={'order.alertPaymentDebtTitle'} />
        </Heading>
      </div>
      <div className={s.OrderDebtAlert__content}>
        <FormattedMessage id={'order.alertPaymentDebtText'} values={{ amount: debtAmount }} />
      </div>
      <div className={s.OrderDebtAlert__actions}>
        {hasPaymentMethod ? (
          <Button variant={ButtonVariant.ERROR} fit={isMobile ? ButtonFit.FILL : ButtonFit.FIT} onClick={openModal}>
            <FormattedMessage id={'order.alertPaymentDebtAction'} />
          </Button>
        ) : (
          <PaymentAddButton onClick={handleAddPaymentMethod} loading={addPaymentLoading} />
        )}
        <Button
          variant={ButtonVariant.PRIMARY}
          fit={isMobile ? ButtonFit.FILL : ButtonFit.FIT}
          onClick={openCustomerServiceModal}
        >
          <FormattedMessage id={'customerService.title'} />
        </Button>
      </div>

      <CustomerServiceModal
        isOpen={customerServiceModalOpen}
        onClose={() => closeCustomerServiceModal()}
        orderID={orderID}
      />

      <Modal title={intl.formatMessage({ id: 'order.alertPaymentDebtTitle' })} isOpen={modalOpen} onClose={closeModal}>
        <Modal.Body>
          <FormattedMessage id={'order.alertPaymentDebtText'} values={{ amount: debtAmount }} />
        </Modal.Body>

        <Modal.Footer>
          <Modal.Button variant={ButtonVariant.ERROR} onClick={confirmDeptPayment} loading={confirmLoading}>
            <FormattedMessage id={'order.alertPaymentDebtAction'} />
          </Modal.Button>
        </Modal.Footer>
      </Modal>
    </Alert>
  );
};
