import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { BsInfoCircle } from 'react-icons/bs';
import { useFormik } from 'formik';
import { omitBy, orderBy, sumBy } from 'lodash-es';
import { FormattedMessage, useIntl } from 'react-intl';
import { EMDASH } from '@proscom/ui-utils';
import StickyBox from 'react-sticky-box';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { ObjectEntries, ReactFCC } from 'common/utils/helperTypes';
import { Heading, HeadingSize } from 'common/components/Heading/Heading';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { EBreakpoints, useBreakpoint } from 'common/hooks/useBreakpoint';
import { Grid, GridGap } from 'common/components/Grid/Grid';
import { EOpeningBlockStatus, EOpeningBlockVariant, OpeningBlock } from 'common/components/OpeningBlock/OpeningBlock';
import { Divider } from 'common/components/Divider/Divider';
import { Button, ButtonFit, ButtonVariant } from 'common/components/Button';
import { Tooltip } from 'common/components/Tooltip/Tooltip';
import { ConditionalWrapper } from 'common/components/ConditionalWrapper/ConditionalWrapper';
import { LoaderBox } from 'common/components/Loader/LoaderBox';
import { getGraphQLErrorInfo, getGraphqlErrorMessage, getParsedErrorMessage } from 'common/utils/graphqlErrors';
import { useCart } from 'store/cart';
import { handleDefaultError } from 'common/utils/handleDefaultError';
import { clearCache } from 'app/providers/auth-apollo';
import { usePayment } from 'store/payment/usePayment';
import { Loader, LoaderSize } from 'common/components/Loader/Loader';
import { PaymentAddButton } from 'common/components/payment/PaymentAddButton/PaymentAddButton';
import { PaymentCardNumber } from 'common/components/payment/PaymentCardNumber/PaymentCardNumber';
import { Anchor } from 'common/components/Anchor/Anchor';
import { GoogleTagEvents, googleTagSendDefaultEvent } from 'features/analytics';
import {
  OrderPaymentTypeEnum,
  useAddProductToFavoritesMutation,
  useCartProductsQuery,
  useCheckUnpaidOrderQuery,
  useConfirmOrderMutation,
  useDeliveryMetadataLazyQuery,
  UserAddressType
} from 'store/graphql';
import { OrderingPageState } from '../types';
import { AddressSelectLayout, getAddressInformationFromUser } from '../../../widgets/address';
import { ProductCard } from '../../../entities/product';
import { CUSTOMER_ORDERS_ROUTE, PathBuilder } from '../../../common/utils/routes';
import { useUser } from '../../../entities/user';
import { useCurrency } from '../../../store/currency';
import { formatMoney } from '../../../common/utils/format';
import { AvailableCurrencyEnum } from '../../../store/currency/CurrencyContext';
import { useOnTabClose } from '../../../common/hooks/useOnTabClose';
import { InputLayout } from '../../../common/components/inputs';
import { PaymentButtonProvider } from '../../../widgets/paypal';
import { PaySystemCheckbox } from '../../../features/inputs';
import { Alert, AlertVariant } from '../../../common/components/Alert';
import { OrderingKeys, OrderingSchema, TOrderingValues } from './schema';
import s from './OrderingPageContent.module.scss';

export type TOrderingPageContent = {
  orderingState: OrderingPageState;
  saveOrderingState: (state: OrderingPageState) => void;
};

const confirmOrderMutationOptions = {
  refetchQueries: ['Cart', 'CartLength', 'IsProductInCart', 'CatalogProducts'],
  update: clearCache(['getUserCart', 'getUserCartLength', 'isProductInCart', 'getProducts'])
};

const deliveryDateFormat = 'MMM dd';

enum Steps {
  products = 'products',
  shipping = 'shipping',
  payment = 'payment'
}
type ToggleStepsOpenType = Record<Steps, boolean>;
type FormStepsDoneType = Record<Exclude<Steps, 'products'>, boolean>;

const stepValidators: Record<Exclude<Steps, 'products'>, (values: TOrderingValues) => boolean> = {
  [Steps.shipping]: (values: TOrderingValues) => {
    return !!values.shippingAddressId && !!values.billingAddressId && !!values.hasDeliveryData;
  },
  [Steps.payment]: (values: TOrderingValues) => {
    return values.hasPaymentMethod;
  }
};

const initialStepsOpen = {
  products: true,
  shipping: true,
  payment: true
};

const initialStepsDone = {
  shipping: false,
  payment: false
};

const closeStepIfDone = {
  shipping: false,
  payment: false
};

function getOpeningBlockStatus(done?: boolean): EOpeningBlockStatus {
  return done ? EOpeningBlockStatus.DONE : EOpeningBlockStatus.ACTIVE;
}

export const OrderingPageContent: ReactFCC<TOrderingPageContent> = (props) => {
  const { orderingState, saveOrderingState } = props;
  const intl = useIntl();

  const { data: unpaidOrderData, loading: unpaidOrderLoading } = useCheckUnpaidOrderQuery();

  const unpaidOrder = unpaidOrderData?.result?.orderId;

  const paymentBlockRef = useRef<HTMLDivElement>(null);
  const paymentMethodChanged = orderingState.paymentMethodChanged;

  const productIds = orderingState.productIds;
  const buyNow = orderingState.buyNow;

  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useBreakpoint(EBreakpoints.LG_DOWN);
  const { user } = useUser();
  const { formatMessage } = useIntl();
  const { setLocalProductIds } = useCart();
  const {
    paymentCardLastNumbers,
    addPaymentMethod,
    addPaymentLoading,
    intentPayment,
    intentPaymentLoading,
    openService
  } = usePayment();

  const [stepsOpen, setStepsOpen] = useState<ToggleStepsOpenType>(initialStepsOpen);
  const [stepsDone, setStepsDone] = useState<FormStepsDoneType>(initialStepsDone);
  const stepsDoneRef = useRef<FormStepsDoneType>(initialStepsDone);

  const shippingStepDone = stepsDone[Steps.shipping];
  const shippingStepStatus = getOpeningBlockStatus(shippingStepDone);

  const paymentStepDone = stepsDone[Steps.payment];
  const paymentStepStatus = getOpeningBlockStatus(paymentStepDone);

  const handleStepToggle = useCallback((stepId: Steps) => {
    return (open: boolean) => {
      setStepsOpen((prevState) => ({
        ...prevState,
        [stepId]: open
      }));
    };
  }, []);
  const handleStepsDone = useCallback((values: TOrderingValues) => {
    const doneSteps: FormStepsDoneType = {
      shipping: stepValidators[Steps.shipping](values),
      payment: stepValidators[Steps.payment](values)
    };

    setStepsDone(doneSteps);

    /**
     * Closing done steps only once
     */
    const stepsToClose: Partial<FormStepsDoneType> = {};
    /**
     * Opening first undone step
     */
    const stepsToOpen: Partial<FormStepsDoneType> = {};

    const doneStepsEntries = Object.entries(doneSteps) as ObjectEntries<FormStepsDoneType>;
    doneStepsEntries.forEach(([stepKey, stepDone], stepIndex) => {
      const prevStep = doneStepsEntries[stepIndex - 1];

      if (stepDone && !stepsDoneRef.current[stepKey] && closeStepIfDone[stepKey]) {
        stepsToClose[stepKey] = false;
        stepsDoneRef.current[stepKey] = true;
      }

      if (!stepDone && !!prevStep?.[1]) {
        stepsToOpen[stepKey] = true;
      }
    });

    if (Object.keys(stepsToClose).length || Object.keys(stepsToOpen).length) {
      setStepsOpen((prevState) => ({
        ...prevState,
        ...stepsToClose,
        ...stepsToOpen
      }));
    }
  }, []);

  const [shippingAddressId] = useState(
    () => user?.addresses.find((address) => address.defaultShipping && address.countryCode !== 'JP')?.id
  );
  const [billingAddressId] = useState(
    () => user?.addresses.find((address) => address.defaultBilling && address.countryCode !== 'JP')?.id
  );

  const formik = useFormik<TOrderingValues>({
    initialValues: {
      shippingAddressId: shippingAddressId || 0,
      billingAddressId: billingAddressId || 0,
      hasPaymentMethod: false,
      hasDeliveryData: false
    },
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: OrderingSchema,
    onSubmit: (values) => confirmOrder(values.shippingAddressId, values.billingAddressId)
  });
  const formValues = formik.values;
  const formSetFieldValue = formik.setFieldValue;

  useEffect(() => {
    handleStepsDone(formValues);
  }, [formValues, handleStepsDone]);

  const productsQuery = useCartProductsQuery({
    variables: {
      productIds,
      limit: productIds?.length || 0
    }
  });
  const products = productsQuery.data?.products.entries;
  const orderCost = sumBy(products, 'price');

  const [shippingInfoChanged, setShippingInfoChanged] = useState(false);

  const [
    getDeliveryMetadata,
    { data: deliveryMetadataData, loading: deliveryMetadataLoading, error: deliveryMetadataError }
  ] = useDeliveryMetadataLazyQuery();

  const deliveryMetadata = deliveryMetadataData?.metadata;
  const totalCost = deliveryMetadata ? deliveryMetadata.deliveryPrice + orderCost : null;

  const deliveryDaysMin = deliveryMetadata?.deliveryDaysMin;
  const deliveryDaysMax = deliveryMetadata?.deliveryDaysMax;

  const deliveryMetadataErrorMessage = useMemo(() => {
    if (shippingInfoChanged || !deliveryMetadataError) return null;
    const pError = getGraphQLErrorInfo(deliveryMetadataError);
    return pError?.statusCode === 400
      ? formatMessage({ id: 'order.deliveryCalcErrorMessage' })
      : getParsedErrorMessage(pError);
  }, [deliveryMetadataError, shippingInfoChanged, formatMessage]);

  const shippingFilled = !!(formValues.shippingAddressId && formValues.billingAddressId);
  const destinationFilled = !!(deliveryMetadata && totalCost && !deliveryMetadataLoading && !shippingInfoChanged);

  // hard filtering here and in backend
  const filteredAddresses = user?.addresses?.filter((address) => address.countryCode !== 'JP') || [];

  useEffect(() => {
    setShippingInfoChanged(true);
  }, [formValues.shippingAddressId]);

  useDebounce(
    () => {
      if (shippingFilled) {
        setShippingInfoChanged(false);

        getDeliveryMetadata({
          variables: {
            productIds,
            addressId: formValues.shippingAddressId
          }
        });
      }
    },
    1000,
    [shippingFilled, formValues.shippingAddressId]
  );

  useEffect(() => {
    formSetFieldValue(OrderingKeys.hasDeliveryData, destinationFilled);
  }, [destinationFilled, formSetFieldValue]);

  useEffect(() => {
    formSetFieldValue(OrderingKeys.hasPaymentMethod, !!paymentCardLastNumbers);
  }, [paymentCardLastNumbers, formSetFieldValue]);

  useEffect(() => {
    formSetFieldValue(OrderingKeys.shippingAddressId, shippingAddressId);
    formSetFieldValue(OrderingKeys.billingAddressId, billingAddressId);
  }, [shippingAddressId, billingAddressId, formSetFieldValue]);

  const [confirmOrderMutation, { loading: confirmOrderLoading }] = useConfirmOrderMutation(confirmOrderMutationOptions);
  const confirmOrder = async (shippingAddressId: UserAddressType['id'], billingAddressId: UserAddressType['id']) => {
    try {
      if (!shippingAddressId || !billingAddressId) {
        return;
      }
      if (!(await intentPayment())) return;

      googleTagSendDefaultEvent(GoogleTagEvents.order);

      const result = await confirmOrderMutation({
        variables: {
          input: {
            productIds,
            deliveryCost: deliveryMetadata?.deliveryPrice || 0,
            shippingAddressId,
            billingAddressId,
            buyNow,
            paymentType: OrderPaymentTypeEnum.Stripe //TODO
          }
        }
      });

      const newOrderId = result.data?.confirmed?.createdOrderId;
      if (!newOrderId) {
        handleDefaultError(intl.formatMessage({ id: 'general.somethingWrong' }));
        return;
      }

      googleTagSendDefaultEvent(GoogleTagEvents.purchase, {
        products: productIds,
        cost: totalCost || 0
      });

      setLocalProductIds([]);

      if (result.data?.confirmed?.returnUrl) {
        return openService(result.data.confirmed.returnUrl);
      }

      navigate(CUSTOMER_ORDERS_ROUTE, { replace: true });

      toast.success(intl.formatMessage({ id: 'order.orderProcessed' }));
    } catch (e) {
      const errMessage = getGraphqlErrorMessage(e);

      if (errMessage) {
        handleDefaultError(errMessage, e);
      }
    }
  };

  const submitButtonActive = useMemo(() => {
    return Object.values(stepsDone).every(Boolean) && destinationFilled;
  }, [destinationFilled, stepsDone]);

  const orderCheckoutLoading = intentPaymentLoading || confirmOrderLoading;

  const handleAddPaymentMethod = () => {
    const vals = omitBy<TOrderingValues>(formValues, ['hasPaymentMethod', 'hasDeliveryData']) as TOrderingValues;
    saveOrderingState({
      productIds,
      buyNow,
      paymentMethodChanged: true,
      ...vals
    });

    return addPaymentMethod({
      successPath: location.pathname,
      cancelPath: location.pathname
    });
  };

  const { currency, convertCurrency } = useCurrency();

  const scrollToPaymentBlock = () => paymentBlockRef?.current?.scrollIntoView({ block: 'center' });

  useEffect(() => {
    if (paymentMethodChanged) {
      scrollToPaymentBlock();
      const vals = omitBy<TOrderingValues>(formValues, ['hasPaymentMethod', 'hasDeliveryData']) as TOrderingValues;
      saveOrderingState({ productIds, buyNow, paymentMethodChanged: false, ...vals });
    }
  }, [orderingState, buyNow, paymentMethodChanged, productIds, saveOrderingState, formValues]);

  // Add to favorites on buyNow tab close
  const [addToFavoriteMutation] = useAddProductToFavoritesMutation();
  const addProductsToFavorite = () => {
    for (const id of productIds) {
      addToFavoriteMutation({
        variables: {
          productId: id
        }
      }).then();
    }
  };
  useOnTabClose(addProductsToFavorite, buyNow);

  const paysystems = orderBy(
    Object.values(OrderPaymentTypeEnum).map((paysystem) => ({
      title: paysystem,
      value: paysystem
    })),
    'title',
    'desc'
  );
  const [currentPaysystem, setPaysystem] = useState<OrderPaymentTypeEnum>(OrderPaymentTypeEnum.Stripe);

  return (
    <div className={s.OrderingPageContent}>
      <Heading size={HeadingSize.H3}>{formatMessage({ id: 'order.checkout' })}</Heading>

      <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_32} />

      <Grid cols={{ xs: 2, lg: 12, xxl: 9 }} gap={GridGap.MEDIUM}>
        <Grid.GridItem cols={{ xs: 2, lg: 8, xxl: 6 }}>
          <OpeningBlock
            title={formatMessage({ id: 'order.shippingTitle' })}
            number={1}
            classes={{
              header: s.OrderingPageContent__itemHeader,
              content: s.OrderingPageContent__itemContent
            }}
            variant={EOpeningBlockVariant.LARGE}
            status={shippingStepStatus}
            isOpen={stepsOpen.shipping}
            setOpen={handleStepToggle(Steps.shipping)}
          >
            {user && (
              <AddressSelectLayout
                className={s.OrderingPageContent__shipping}
                addresses={filteredAddresses}
                shippingAddressId={formik.values.shippingAddressId}
                billingAddressId={formik.values.billingAddressId}
                setShippingAddressId={(id) => formik.setFieldValue(OrderingKeys.shippingAddressId, id)}
                setBillingAddressId={(id) => formik.setFieldValue(OrderingKeys.billingAddressId, id)}
                personalInformation={getAddressInformationFromUser(user)}
                hideBilling={true}
                selectOnFirst={!shippingAddressId}
              />
            )}

            {deliveryMetadataErrorMessage && <div className="text-danger mt-3">{deliveryMetadataErrorMessage}</div>}
          </OpeningBlock>

          <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />

          <div ref={paymentBlockRef}>
            <OpeningBlock
              title={formatMessage({ id: 'order.paymentMethodTitle' })}
              number={2}
              classes={{
                header: s.OrderingPageContent__itemHeader,
                content: s.OrderingPageContent__itemContent
              }}
              variant={EOpeningBlockVariant.LARGE}
              status={paymentStepStatus}
              isOpen={stepsOpen.payment}
              setOpen={handleStepToggle(Steps.payment)}
            >
              <InputLayout>
                <div className={s.OrderingPageContent__paysystems}>
                  {currentPaysystem === OrderPaymentTypeEnum.Paypal && (
                    <Alert variant={AlertVariant.Warning}>
                      <FormattedMessage id={'order.paypalScam'} />
                    </Alert>
                  )}
                  {paysystems.map((paysystem, index) => (
                    <PaySystemCheckbox
                      key={index}
                      name={'paysystem'}
                      className={clsx(
                        s.OrderingPageContent__paysystem,
                        currentPaysystem === paysystem.title && s.OrderingPageContent__paysystem_active
                      )}
                      label={paysystem.title}
                      checked={currentPaysystem === paysystem.title}
                      value={paysystem.title}
                      onChange={() => {
                        setPaysystem(paysystem.title);
                      }}
                    />
                  ))}
                </div>
              </InputLayout>
              <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />
              {currentPaysystem === OrderPaymentTypeEnum.Stripe &&
                (!paymentCardLastNumbers ? (
                  <PaymentAddButton loading={addPaymentLoading} onClick={handleAddPaymentMethod} />
                ) : (
                  <>
                    <PaymentCardNumber cardLastNumbers={paymentCardLastNumbers} />
                    <Space size={ESpaceSize.PIXEL_24} />
                    {addPaymentLoading ? (
                      <Loader size={LoaderSize.SMALL} />
                    ) : (
                      <Anchor component={'button'} onClick={handleAddPaymentMethod}>
                        Change payment method
                      </Anchor>
                    )}
                  </>
                ))}
            </OpeningBlock>

            <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />
            <OpeningBlock
              title={formatMessage({ id: 'order.productsInOrderTitle' })}
              number={3}
              className={s.OrderingPageContent__item}
              classes={{
                header: s.OrderingPageContent__itemHeader,
                content: s.OrderingPageContent__itemContent
              }}
              variant={EOpeningBlockVariant.LARGE}
              status={getOpeningBlockStatus(true)}
              isOpen={stepsOpen.products}
              setOpen={handleStepToggle(Steps.products)}
            >
              {products?.map((product, index) => (
                <React.Fragment key={index}>
                  {index !== 0 && <Divider space={ESpaceSize.PIXEL_16} />}

                  <ProductCard product={product} />
                </React.Fragment>
              ))}
            </OpeningBlock>
          </div>
        </Grid.GridItem>

        <Grid.GridItem cols={{ xs: 2, lg: 4, xxl: 3 }}>
          <ConditionalWrapper condition={!isMobile} wrapper={(children) => <StickyBox>{children}</StickyBox>}>
            <OpeningBlock
              classes={{
                header: s.OrderingPageContent__summaryBlockHeader,
                content: s.OrderingPageContent__summaryBlockContent
              }}
              title={formatMessage({ id: 'order.summaryPayment' })}
              variant={EOpeningBlockVariant.LARGE}
              status={EOpeningBlockStatus.ACTIVE}
              isOpen={true}
              control={false}
            >
              {deliveryMetadataLoading || unpaidOrderLoading ? (
                <LoaderBox className={s.OrderingPageContent__summaryBlockLoader} />
              ) : (
                <>
                  {/* todo: проставить статус оплаты */}
                  {/*<div className={s.OrderingPageContent__summaryBlockContentRow}>*/}
                  {/*  <div>Payment status</div>*/}
                  {/*  <Badge>Not paid</Badge>*/}
                  {/*</div>*/}

                  {/*<Space size={ESpaceSize.PIXEL_8} />*/}

                  <div className={s.OrderingPageContent__summaryBlockContentRow}>
                    <div>{formatMessage({ id: 'order.cost' })}</div>
                    <div>
                      {formatMoney(orderCost)}
                      {currency !== AvailableCurrencyEnum.JPY && (
                        <span className={s.OrderingPageContent__totalCostHelper}>({convertCurrency(orderCost)})</span>
                      )}
                    </div>
                  </div>

                  <Space size={ESpaceSize.PIXEL_8} />

                  <div className={s.OrderingPageContent__summaryBlockContentRow}>
                    <div>
                      {formatMessage({ id: 'order.estimatedShipping' })}
                      <Tooltip text="Shipping cost is preliminary">
                        <div className={s.OrderingPageContent__summaryBlockTipContainer}>
                          <BsInfoCircle />
                        </div>
                      </Tooltip>
                    </div>

                    <span>
                      {destinationFilled ? (
                        <div>
                          {formatMoney(deliveryMetadata?.deliveryPrice)}
                          {currency !== AvailableCurrencyEnum.JPY && (
                            <span className={s.OrderingPageContent__totalCostHelper}>
                              ({convertCurrency(deliveryMetadata?.deliveryPrice)})
                            </span>
                          )}
                        </div>
                      ) : (
                        EMDASH
                      )}
                    </span>
                  </div>

                  <Divider space={ESpaceSize.PIXEL_16} />

                  <div
                    className={clsx(
                      s.OrderingPageContent__summaryBlockContentRow,
                      s.OrderingPageContent__summaryBlockContentRow__large
                    )}
                  >
                    <div>{formatMessage({ id: 'order.totalCost' })}</div>

                    {destinationFilled ? (
                      <div>
                        {formatMoney(totalCost)}
                        {currency !== AvailableCurrencyEnum.JPY && (
                          <span className={s.OrderingPageContent__totalCostHelper}>({convertCurrency(totalCost)})</span>
                        )}
                      </div>
                    ) : (
                      EMDASH
                    )}
                  </div>

                  <Space size={ESpaceSize.PIXEL_24} />

                  {unpaidOrder ? (
                    <Button
                      fit={ButtonFit.FILL}
                      variant={ButtonVariant.ERROR}
                      onClick={() => navigate(PathBuilder.getOrderPath(unpaidOrder))}
                    >
                      <FormattedMessage id="ordering.unpaidOrder" />
                    </Button>
                  ) : currentPaysystem === OrderPaymentTypeEnum.Stripe ? (
                    <Button
                      fit={ButtonFit.FILL}
                      onClick={formik.submitForm}
                      loading={orderCheckoutLoading}
                      disabled={!submitButtonActive}
                    >
                      {formatMessage({ id: 'order.checkoutButton' })}
                    </Button>
                  ) : (
                    totalCost && (
                      <PaymentButtonProvider
                        orderPrice={totalCost}
                        productIds={productIds}
                        deliveryPrice={deliveryMetadata?.deliveryPrice}
                        buyNow={buyNow}
                        shippingAddressId={shippingAddressId}
                        billingAddressId={billingAddressId}
                      />
                    )
                  )}
                </>
              )}
            </OpeningBlock>
          </ConditionalWrapper>
        </Grid.GridItem>
      </Grid>
    </div>
  );
};
