import { RouteObject } from 'react-router-dom';
import React from 'react';
import { NotFoundPage } from '../notFound/NotFoundPage';
import { HELP_DYNAMIC_ROUTE, HELP_ROUTE, withRoutes } from '../../common/utils/routes';
import { HelpLayout } from './_layout/HelpLayout';

import { HelpPage } from './help/HelpPage';

const routes: RouteObject[] = [
  {
    element: <HelpLayout />,
    children: [
      {
        index: true,
        element: <HelpPage />
      },
      {
        path: HELP_DYNAMIC_ROUTE.replace(HELP_ROUTE, ''),
        element: <HelpPage />
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
    ]
  }
];

export const HelpRoutes = withRoutes(routes);
