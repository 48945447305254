import { InferType, number, object, string } from 'yup';
import { format, startOfDay } from 'date-fns';
import { validateErrors } from '../../../../../common/utils/validate';

const today = startOfDay(new Date());
export const courierAvailMinDate = format(today, 'yyyy-MM-dd');

const validateObject = {
  closeTime: string().defined(validateErrors.required),
  readyTime: string().defined(validateErrors.required),
  sellerAddressId: number().defined(validateErrors.required)
};

export const OrderPickupSchema = object(validateObject);

export type TOrderPickupValues = InferType<typeof OrderPickupSchema>;

export const OrderPickupKeys = Object.fromEntries(Object.keys(validateObject).map((key) => [key, key])) as Record<
  keyof TOrderPickupValues,
  string
>;

const dimensionsObject = {
  weight: number().positive().defined(validateErrors.required),
  boxHeight: number().positive().defined(validateErrors.required),
  boxWidth: number().positive().defined(validateErrors.required),
  boxLength: number().positive().defined(validateErrors.required)
};

export const ParcelDimensionsSchema = object(dimensionsObject);

export const ParcelLimitSchema = ParcelDimensionsSchema.test(
  'sum-fields',
  'Maximum dimensions should not exceed 200',
  (values) => {
    const { boxHeight = 0, boxLength = 0, boxWidth = 0 } = values;
    const sum = boxHeight + boxLength + boxWidth;
    return sum <= 200;
  }
);

export type TParcelDimensionsValues = InferType<typeof ParcelDimensionsSchema>;

export const ParcelDimensionsKeys = Object.fromEntries(
  Object.keys(dimensionsObject).map((key) => [key, key])
) as Record<keyof TParcelDimensionsValues, string>;
