import React from 'react';
import { UseStripeSessionReturnType } from './usePaymentSession';

export interface IPaymentContext extends UseStripeSessionReturnType {
  hasPaymentMethod: boolean;
  paymentCardLastNumbers: string;
  intentPayment: (debtId?: string) => Promise<string | null>;
  paypalPayment: (debtId: string) => Promise<string | null>;
  openService: (url: string) => void;
  intentPaymentLoading: boolean;
  reconfirm: (orderId: number) => Promise<void>;
  reconfirmLoading: boolean;
}

export const PaymentContext = React.createContext<IPaymentContext>({
  addPaymentMethod: () => {},
  addPaymentLoading: false,
  detachPaymentMethod: () => {},
  detachPaymentLoading: false,
  paymentMethodDetached: false,
  hasPaymentMethod: false,
  paymentCardLastNumbers: '',
  intentPayment: () => new Promise((res) => res(null)),
  paypalPayment: () => new Promise((res) => res(null)),
  intentPaymentLoading: false,
  openService: () => {},
  reconfirm: () => new Promise((res) => res()),
  reconfirmLoading: false
});

export enum FromStripeQS {
  ordering = 'ordering',
  me = 'me',
  canceled = 'canceled'
}

export const fromStripeUrls = {
  [FromStripeQS.ordering]: 'arisora://ordering',
  [FromStripeQS.me]: 'arisora://me',
  [FromStripeQS.canceled]: 'arisora://canceled'
};
