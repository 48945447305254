import { useUpdateEffect } from 'react-use';
import { URL_KEY_OFFSET } from 'store/urlKeys';
import { useBatchedQueryParam } from '../../hooks/queryParam/useBatchedQueryParam';

export type UsePaginationProps = {
  scrollToTopOnPageChange?: boolean;
};

export const usePagination = ({ scrollToTopOnPageChange = true }: UsePaginationProps = {}) => {
  const [offset, setOffset] = useBatchedQueryParam<number>(URL_KEY_OFFSET, {
    defaultValue: 0,
    getter: (v) => Number(v) || 0
  });

  useUpdateEffect(() => {
    if (scrollToTopOnPageChange) {
      window.scrollTo(0, 0);
    }
  }, [offset, scrollToTopOnPageChange]);

  return {
    offset,
    setOffset
  };
};
