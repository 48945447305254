import React from 'react';
import { useIntl } from 'react-intl';
import { Modal, ModalProps } from '../../../../common/components/Modal';
import { ReactFCC } from '../../../../common/utils/helperTypes';

export enum AuthModalStep {
  ENTER_EMAIL = 'EnterEmail',
  ENTER_EMAIL_PASSWORD = 'EnterEmailPassword',
  ENTER_CODE = 'EnterCode'
}

export interface AuthModalProps extends Pick<ModalProps, 'isOpen' | 'onClose'> {
  className?: string;
  step: AuthModalStep;
}

export const AuthModal: ReactFCC<AuthModalProps> = (props) => {
  const { children, className, isOpen, onClose, step } = props;

  const intl = useIntl();

  return (
    <Modal className={className} isOpen={isOpen} onClose={onClose} title={intl.formatMessage({ id: 'auth.title' })}>
      {children}
    </Modal>
  );
};
