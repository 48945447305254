import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client';
import { TokenResponse } from './types';

const RequestTokenDocument = gql`
  mutation request_token($input: UserConfirmInput!) {
    response: confirmUserByCode(input: $input) {
      accessToken
      accessTokenExpiresAt
      refreshToken {
        token
        expires_at
      }
      user {
        id
      }
    }
  }
`;

const RequestTokenWithPassword = gql`
  mutation request_token_with_password($input: UserPasswordInput!) {
    response: confirmUserByPassword(input: $input) {
      accessToken
      accessTokenExpiresAt
      refreshToken {
        token
        expires_at
      }
      user {
        id
      }
    }
  }
`;

export interface RequestTokenVariables {
  email: string;
  code: string;
  usePassword?: boolean;
}

export async function requestToken(client: ApolloClient<NormalizedCacheObject>, variables: RequestTokenVariables) {
  const { email, code } = variables;
  return await client.mutate<{ response: TokenResponse }>({
    mutation: variables?.usePassword ? RequestTokenWithPassword : RequestTokenDocument,
    variables: {
      input: {
        email,
        ...(variables?.usePassword ? { password: code } : { code })
      }
    }
  });
}
