import { useFormik } from 'formik';
import { useNavigate } from 'react-router';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TicketTopicEnum, useCreateTicketMutation } from '../../../store/graphql';
import { PathBuilder, TICKETS_ROUTE } from '../../../common/utils/routes';
import { handleDefaultError } from '../../../common/utils/handleDefaultError';
import { FormInput, FormSelect } from '../../../common/components/inputs';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { Heading, HeadingSize } from '../../../common/components/Heading';
import { Button } from '../../../common/components/Button';
import { clearCache } from '../../../app/providers/auth-apollo';
import { Head } from '../../../common/components/Head';
import { LinkBack } from '../../../common/components/LinkBack';
import {
  CreateTicketSchema,
  CreateTicketSchemaKeys,
  TCreateTicketValues,
  ticketAttributeLimits,
  ticketTopics
} from './schema';
import s from './CreateTicketPage.module.scss';

export const CreateTicketPage = () => {
  const [createTicketMutation, { loading: createTicketLoading }] = useCreateTicketMutation({
    refetchQueries: ['Tickets'],
    update: clearCache(['getTickets']),
    awaitRefetchQueries: true
  });
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const intl = useIntl();
  const formik = useFormik<TCreateTicketValues>({
    initialValues: {
      name: '',
      topic: '',
      firstMessage: ''
    },
    validationSchema: CreateTicketSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, helpers) => {
      try {
        const { data: mutationResponse } = await createTicketMutation({
          variables: {
            input: {
              name: values.name,
              topic: values.topic as TicketTopicEnum,
              firstMessage: values.firstMessage
            }
          }
        });
        const ticketId = mutationResponse?.result?.ticketId;
        if (!ticketId) {
          handleDefaultError(intl.formatMessage({ id: 'ticket.createError' }));
          return;
        }
        navigate(PathBuilder.getTicketRequestPath(ticketId));
      } catch (e) {
        handleDefaultError(intl.formatMessage({ id: 'ticket.createError' }), e);
      }
    }
  });

  return (
    <>
      <div className={s.CreateTicketPage}>
        <>
          <Head title={intl.formatMessage({ id: 'profile.tickets' })} />
          <LinkBack onClick={() => navigate(TICKETS_ROUTE)}>Back</LinkBack>
          <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />
        </>
        <Heading size={isMobile ? HeadingSize.H4 : HeadingSize.H3} adaptive={false}>
          <FormattedMessage id="profile.createTicketTitle" />
        </Heading>
        <Space size={ESpaceSize.PIXEL_24} />
        <FormSelect
          name={CreateTicketSchemaKeys.topic}
          label={intl.formatMessage({ id: 'profile.createTicketTopic' })}
          defaultOption={{
            title: `${intl.formatMessage({ id: 'general.select' })} ${intl.formatMessage({ id: 'catalog.category' })}`
          }}
          options={ticketTopics || []}
          onItemSelect={(option) => {
            formik.setFieldValue(CreateTicketSchemaKeys.topic, option.value);
          }}
          value={formik.values.topic}
          error={formik.errors.topic}
          space={isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16}
          required
        />
        <FormInput
          name={CreateTicketSchemaKeys.name}
          label={intl.formatMessage({ id: 'profile.createTicketName' })}
          placeholder={intl.formatMessage({ id: 'profile.createTicketName' })}
          onChange={(e) => {
            formik.handleChange(e);
          }}
          value={formik.values.name}
          error={formik.errors.name}
          maxLength={ticketAttributeLimits.name.max}
          space={isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16}
          required
        />
        <FormInput
          as={'textarea'}
          name={CreateTicketSchemaKeys.firstMessage}
          label={intl.formatMessage({ id: 'profile.createTicketFirstMessage' })}
          placeholder={intl.formatMessage({ id: 'profile.createTicketFirstMessage' })}
          onChange={(e) => {
            formik.handleChange(e);
          }}
          value={formik.values.firstMessage}
          error={formik.errors.firstMessage}
          space={{ top: isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16 }}
          maxLength={ticketAttributeLimits.firstMessage.max}
          required
        />
        <Space size={ESpaceSize.PIXEL_24} />
        <Button loading={createTicketLoading} onClick={() => formik.submitForm()}>
          <FormattedMessage id={'profile.ticketsOpen'} />{' '}
        </Button>
      </div>
    </>
  );
};
