import React from 'react';

import clsx from 'clsx';

import { FormattedMessage, useIntl } from 'react-intl';
import { ReactFCC } from '../../../../common/utils/helperTypes';
import { Maybe, Product, SpecificValueExtended } from '../../../../store/graphql';
import { HeadingSize, Heading } from '../../../../common/components/Heading';
import { ESpaceSize, Space } from '../../../../common/components/Space/Space';
import { EBreakpoints, useBreakpoint } from '../../../../common/hooks/useBreakpoint';
import { deliverySpeedDaysIntlNames, productConditionIntlNames } from '../../../../common/constants/product';
import { PathBuilder } from '../../../../common/utils/routes';
import { ProductCharacteristicsItem } from './item/ProductCharacteristicsItem';
import s from './ProductCharacteristics.module.scss';

export type TProductCharacteristicsType = Pick<
  Product,
  | 'id'
  | 'name'
  | 'categoryId'
  | 'categoryName'
  | 'categoryPath'
  | 'brandName'
  | 'colorName'
  | 'condition'
  | 'weight'
  | 'boxHeight'
  | 'boxWidth'
  | 'boxLength'
  | 'deliverySpeedDays'
> & {
  specificValues?: Maybe<Pick<SpecificValueExtended, 'attributeName' | 'variantValue'>[]>;
};

export type TProductCharacteristics = {
  className?: string;
  product: TProductCharacteristicsType;
};

export const ProductCharacteristics: ReactFCC<TProductCharacteristics> = (props) => {
  const { className, product } = props;

  const isMobile = useBreakpoint(EBreakpoints.LG_DOWN);

  const intl = useIntl();

  const categoryPath = product.categoryPath.path;

  return (
    <div className={clsx(s.ProductCharacteristics, className)}>
      <Heading size={HeadingSize.H4}>
        <FormattedMessage id="product.characteristics" />
      </Heading>
      <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />
      <ProductCharacteristicsItem
        name={intl.formatMessage({ id: 'catalog.category' })}
        multipleTitles={categoryPath.map((p) => ({
          title: p.name,
          to: PathBuilder.getCatalogPath({
            categoryId: p.id
          })
        }))}
      />

      {product.deliverySpeedDays && (
        <ProductCharacteristicsItem
          name={intl.formatMessage({ id: 'catalog.deliverySpeedDays' })}
          value={intl.formatMessage({ id: deliverySpeedDaysIntlNames[product.deliverySpeedDays] })}
        />
      )}

      {product.brandName && (
        <ProductCharacteristicsItem name={intl.formatMessage({ id: 'catalog.brand' })} value={product.brandName} />
      )}
      {product.colorName && (
        <ProductCharacteristicsItem name={intl.formatMessage({ id: 'catalog.color' })} value={product.colorName} />
      )}

      {product.specificValues?.map((item, index) => (
        <ProductCharacteristicsItem name={item.attributeName} value={item.variantValue} key={index} />
      ))}

      {product.condition && (
        <ProductCharacteristicsItem
          name={intl.formatMessage({ id: 'catalog.condition' })}
          value={intl.formatMessage({ id: productConditionIntlNames[product.condition] })}
        />
      )}

      {product.weight && (
        <ProductCharacteristicsItem
          name={intl.formatMessage({ id: 'product.weight' })}
          value={`${product.weight} kg`}
        />
      )}

      {product.boxWidth && product.boxHeight && product.boxLength && (
        <ProductCharacteristicsItem
          name={intl.formatMessage({ id: 'product.packageDimensions' })}
          value={`${product.boxHeight} × ${product.boxWidth} × ${product.boxLength} cm`}
        />
      )}
    </div>
  );
};
