import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUi } from '../../../store/ui';
import { Link } from '../../../common/components/Link';
import { Anchor } from '../../../common/components/Anchor';
import { HOME_ROUTE } from '../../../common/utils/routes';
import { useAuth } from '../../../app/providers/auth-apollo';
import s from './CartEmpty.module.scss';

export const CartEmpty: React.FC = () => {
  const { toggleAuthModal } = useUi();
  const { isAuthenticated } = useAuth();
  const intl = useIntl();

  return (
    <div className={s.CartEmpty}>
      <h5 className={s.CartEmpty__title}>{intl.formatMessage({ id: 'cart.noItems' })}</h5>
      {isAuthenticated ? (
        <span className={s.CartEmpty__text}>
          <FormattedMessage
            id={'cart.goToStart'}
            values={{
              a: (chunks) => <Link to={HOME_ROUTE}>{chunks}</Link>
            }}
          />
        </span>
      ) : (
        <span className={s.CartEmpty__text}>
          <FormattedMessage
            id={'cart.goToStartUnauthorized'}
            values={{
              a: (chunks) => <Link to={HOME_ROUTE}>{chunks}</Link>,
              span: (chunks) => (
                <Anchor component={'span'} onClick={() => toggleAuthModal(true)}>
                  {chunks}
                </Anchor>
              )
            }}
          />
        </span>
      )}
    </div>
  );
};
