import { FormikValues, useFormik } from 'formik';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { orderBy } from 'lodash-es';
import {
  DeliverySpeedDaysEnum,
  ProductConditionEnum,
  useCreateProductCategoriesQuery,
  useCreateProductColorsQuery,
  useCreateProductSpecificAttributesQuery
} from 'store/graphql';
import { deliverySpeedDaysIntlNames, productConditionIntlNames } from 'common/constants/product';
import clsx from 'clsx';
import { useUser } from '../../../../entities/user';
import { TCreateProductValues } from './schema';

import s from './CreateProductPageContent.module.scss';

type TUseFormikType<V extends FormikValues> = ReturnType<typeof useFormik<V>>;

export type TUseFormDataOptions = {
  skip?: boolean;
  formik: TUseFormikType<TCreateProductValues>;
};

export const useFormData = (options: TUseFormDataOptions) => {
  const { skip, formik } = options;

  const { formatMessage } = useIntl();
  const { user } = useUser();

  /** Category */

  const { data: categoriesQuery, loading: categoriesLoading } = useCreateProductCategoriesQuery({
    skip
  });
  const categories = categoriesQuery?.categories.categories.map(({ id, name }) => ({
    title: name,
    value: id.toString()
  }));
  const subCategories = categoriesQuery?.categories?.categories
    ?.find(({ id }) => formik.values.categoryId === id)
    ?.children?.map(({ id, name }) => ({ title: name, value: id.toString() }));
  const subSubCategories = categoriesQuery?.categories?.categories
    ?.find(({ id }) => formik.values.categoryId === id)
    ?.children?.find(({ id }) => formik.values.subCategoryId === id)
    ?.children?.map(({ id, name, mandatoryAttributeId }) => ({
      title: name,
      value: id.toString(),
      mandatoryAttribute: mandatoryAttributeId || null
    }));

  /** Disabled flag for other data */
  const catSelected = !!formik.values.categoryId;
  const subCatSelected = !!formik.values.subCategoryId;
  const subSubCatSelected = !!formik.values.subSubCategoryId;
  const disabled = skip || !catSelected || !subCatSelected || !subSubCatSelected;

  const { data: colorsQuery, loading: colorsLoading } = useCreateProductColorsQuery({
    skip: disabled
  });
  const colors = colorsQuery?.colors.map(({ id, name, code }) => ({
    title: (
      <div className={s.CreateProductPageContent__colorSelect}>
        <div
          className={clsx(
            s.CreateProductPageContent__colorSelectCircle,
            code === '#FFFFFF' && s.CreateProductPageContent__colorWhite
          )}
          style={{ background: code }}
        ></div>
        <span>{name}</span>
      </div>
    ),
    value: id.toString()
  }));

  const conditions = [
    {
      title: formatMessage({ id: productConditionIntlNames[ProductConditionEnum.New] }),
      value: ProductConditionEnum.New
    },
    {
      title: formatMessage({ id: productConditionIntlNames[ProductConditionEnum.AlmostUnused] }),
      value: ProductConditionEnum.AlmostUnused
    },
    {
      title: formatMessage({ id: productConditionIntlNames[ProductConditionEnum.LightDamage] }),
      value: ProductConditionEnum.LightDamage
    },
    {
      title: formatMessage({ id: productConditionIntlNames[ProductConditionEnum.SomeDamage] }),
      value: ProductConditionEnum.SomeDamage
    },
    {
      title: formatMessage({ id: productConditionIntlNames[ProductConditionEnum.Damaged] }),
      value: ProductConditionEnum.Damaged
    },
    {
      title: formatMessage({ id: productConditionIntlNames[ProductConditionEnum.Bad] }),
      value: ProductConditionEnum.Bad
    }
  ];

  const deliverySpeedDaysVariants = useMemo(() => {
    return orderBy(
      Object.values(DeliverySpeedDaysEnum).map((type) => ({
        title: formatMessage({ id: deliverySpeedDaysIntlNames[type] }),
        value: type
      })),
      (o) => o.title
    );
  }, [formatMessage]);

  /** Specific attributes */

  const { data: specificAttributesQuery, loading: specificAttributesLoading } = useCreateProductSpecificAttributesQuery(
    {
      skip: disabled,
      variables: {
        categoryId: formik.values.subSubCategoryId
      }
    }
  );
  const specificAttributes = specificAttributesQuery?.specificAttributes.map((attr) => ({
    id: attr.id,
    name: attr.name,
    variants: attr.variants.map((variant) => ({ title: variant.value, value: variant.id.toString() }))
  }));

  return {
    categories,
    categoriesLoading,

    subCategories,
    subSubCategories,
    colors,
    colorsLoading,

    conditions,
    deliverySpeedDaysVariants,

    specificAttributes,
    specificAttributesLoading,

    disabled
  };
};
