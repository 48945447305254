import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { FormikErrors, useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal } from 'common/components/Modal/Modal';
import {
  AttachmentUploadStatus,
  LocalAttachmentType,
  SellerAttachment,
  useRegisterSellerMutation
} from 'store/graphql';
import { clearCache } from 'app/providers/auth-apollo';
import { getGraphqlErrorMessage } from 'common/utils/graphqlErrors';
import { handleDefaultError } from 'common/utils/handleDefaultError';
import { FormCheckbox, FormInput, InputLayout, InputUpload } from 'common/components/inputs';
import { Text, TextVariant } from 'common/components/Text';
import { Colors } from 'common/utils/colors';
import { Button, ButtonVariant } from 'common/components/Button';
import { differenceInYears } from 'date-fns';
import { AddressLayout, AddressLayoutProps } from '../../../../../widgets/address';
import { useUser } from '../../../../../entities/user';
import { FileAction, ImageUploadExt } from '../../../../../lib/msfiles-client';
import { maxNameLength } from '../../../../../widgets/auth';
import { Anchor } from '../../../../../common/components/Anchor';
import { VerificationFormAttachment } from '../VerificationFormAttachment';
import { DEFAULT_UPLOAD_MAX_SIZE_MB, DEFAULT_VERIFICATION_MAX_FILES } from '../../../../../lib/file-uploader/constants';
import { PhoneVerification } from '../PhoneVerification';
import { LoaderBox } from '../../../../../common/components/Loader';
import { ArisoraBuckets } from '../../../../../lib/file-uploader/hooks/types';
import { usePrivateUpload } from '../../../../../lib/file-uploader/hooks/usePrivateUpload';
import { VerificationSchema, VerificationSchemaKeys, VerificationValues } from './schema';
import s from './VerificationForm.module.scss';

export interface SellerVerificationModalProps extends Pick<AddressLayoutProps, 'onClickOpenForm'> {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
}

export enum VerificationGender {
  Male = 'Male',
  Female = 'Female'
}

export const DEFAULT_SMS_TIMEOUT_IN_SECONDS = 60;

export const VerificationForm: React.FC<SellerVerificationModalProps> = ({
  className,
  isOpen,
  onClose,
  onClickOpenForm
}) => {
  const intl = useIntl();
  const { user } = useUser();

  const [registerSeller, { loading: registerSellerLoading }] = useRegisterSellerMutation({
    refetchQueries: ['User'],
    update: clearCache(['getUser'])
  });

  const [verificationCode, setVerificationCode] = useState(user?.phoneVerified ? null : '');
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '');
  const [isPhoneVerified, setPhoneVerified] = useState(user?.phoneVerified ?? false);
  const [attachmentsUploading, setAttachmentsUploading] = useState(false);

  const validationSchema = useMemo(() => VerificationSchema(intl), [intl]);

  const formik = useFormik<VerificationValues>({
    initialValues: {
      gender: VerificationGender.Male,
      name: '',
      secondName: '',
      nameHiragana: '',
      secondNameHiragana: '',
      birthdate: '',
      contactNumber: '',
      addressId: 0,
      attachments: [],
      smsCode: verificationCode
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: FormikErrors<VerificationValues> = {};

      if (differenceInYears(new Date(), new Date(values.birthdate)) < 18) {
        errors.birthdate = intl.formatMessage({ id: 'profile.verificationAgeError' });
      }

      if (values.attachments.some((i) => i.error)) {
        errors.attachments = intl.formatMessage({ id: 'profile.verificationFilesError' });
      }

      return errors;
    },
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, helpers) => {
      try {
        const address = user?.addresses.find((address) => address.id === values.addressId);

        if (!address) {
          handleDefaultError(intl.formatMessage({ id: 'profile.verificationError' }));
          return;
        }

        const inputAttachments = formik.values.attachments.map((i) => ({
          uid: i?.msfiles_uid || ''
        })) as SellerAttachment[];

        await registerSeller({
          variables: {
            input: {
              name: values.name,
              secondName: values.secondName,
              nameHiragana: values.nameHiragana,
              secondNameHiragana: values.secondNameHiragana,
              gender: values.gender,
              dateOfBirth: values.birthdate,
              contactNumber: values.contactNumber,
              attachments: inputAttachments,
              addressId: values.addressId,
              sms_code: verificationCode
            }
          }
        });

        helpers.resetForm();
        onClose();
      } catch (e) {
        const graphqlErrorMessage = getGraphqlErrorMessage(e);
        switch (graphqlErrorMessage) {
          case 'NOT_JAPAN':
            handleDefaultError(intl.formatMessage({ id: 'profile.verificationResidenceError' }));
            break;
          default:
            handleDefaultError(graphqlErrorMessage || intl.formatMessage({ id: 'profile.verificationError' }), e);
            break;
        }
      }
    }
  });

  const formSetFieldValue = formik.setFieldValue;
  const formSetFieldError = formik.setFieldError;

  const [uploadMutation, { data, remove, previousData }] = usePrivateUpload({
    multiple: true,
    allowedActions: [FileAction.UploadFile, FileAction.UploadImage],
    bucket: ArisoraBuckets.Sellers
  });

  const onUploadChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const files = Array.from(e.target.files).slice(0, DEFAULT_VERIFICATION_MAX_FILES);
        setAttachmentsUploading(true);
        const promises = files.map(async (fileSource) => {
          try {
            formSetFieldError('attachments', undefined);
            await uploadMutation({
              file: fileSource,
              amount: files.length,
              params: {
                synchronously: true,
                ext: ImageUploadExt.jpg
              }
            });
          } catch (e: any) {
            formSetFieldError('attachments', e.message);
            const graphqlErrorMessage = getGraphqlErrorMessage(e);
            handleDefaultError(
              graphqlErrorMessage || e.message || intl.formatMessage({ id: 'general.uploadError' }),
              e
            );
          }
        });

        Promise.all(promises).then(() => {
          e.target.value = '';
          setAttachmentsUploading(false);
        });
      }
    },
    [formSetFieldError, uploadMutation]
  );

  useLayoutEffect(() => {
    let attachments = data?.privateAttachments.entries || [];
    if (attachments.length === 0 && previousData && previousData?.privateAttachments.entries.length > 0) {
      attachments = previousData?.privateAttachments.entries;
    }
    formSetFieldValue(VerificationSchemaKeys.attachments, attachments);
  }, [data, formSetFieldValue, previousData]);

  useLayoutEffect(() => {
    if (user?.addresses.length) {
      formSetFieldValue('addressId', user.addresses[0].id);
    }
  }, [formSetFieldValue, user?.addresses]);

  useLayoutEffect(() => {
    if (verificationCode) {
      formSetFieldValue(VerificationSchemaKeys.smsCode, verificationCode);
    }
  }, [formSetFieldValue, verificationCode]);

  useLayoutEffect(() => {
    if (phoneNumber) {
      formSetFieldValue(VerificationSchemaKeys.contactNumber, phoneNumber);
    }
  }, [formSetFieldValue, phoneNumber]);

  const pullCode = (code: string) => {
    setVerificationCode(code);
  };

  const pullPhone = (phone: string) => {
    setPhoneNumber(phone);
  };

  const pullNumberVerified = (isVerified: boolean) => {
    setPhoneVerified(isVerified);
  };

  const onAttachmentDelete = (attachment: LocalAttachmentType) => {
    remove(attachment);
  };

  if (!user) {
    return <LoaderBox />;
  }

  return (
    <Modal
      title={intl.formatMessage({ id: 'profile.verificationTitle' })}
      className={clsx(s.VerificationForm, className)}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Body>
        <PhoneVerification
          pullCode={pullCode}
          pullPhone={pullPhone}
          pullNumberVerified={pullNumberVerified}
          resendTimeout={60}
          initialData={{ phone: phoneNumber, numberVerified: isPhoneVerified }}
          isSeller={true}
        />
        <InputLayout
          className={s.VerificationForm__input}
          label={intl.formatMessage({ id: 'profile.verificationGender' })}
          error={formik.errors.gender}
          required
        >
          <div className={clsx(s.VerificationForm__group, s.VerificationForm__group_radio)}>
            <FormCheckbox
              type={'radio'}
              name={'Name'}
              label={intl.formatMessage({ id: 'profile.verificationGenderMale' })}
              checked={formik.values.gender === VerificationGender.Male}
              value={VerificationGender.Male}
              disabled={!isPhoneVerified}
              onChange={() => formik.setFieldValue(VerificationSchemaKeys.gender, VerificationGender.Male)}
            />

            <FormCheckbox
              type={'radio'}
              name={'Name'}
              label={intl.formatMessage({ id: 'profile.verificationGenderFemale' })}
              checked={formik.values.gender === VerificationGender.Female}
              value={VerificationGender.Female}
              disabled={!isPhoneVerified}
              onChange={() => formik.setFieldValue(VerificationSchemaKeys.gender, VerificationGender.Male)}
            />
          </div>
        </InputLayout>

        <InputLayout
          className={s.VerificationForm__input}
          label={intl.formatMessage({ id: 'profile.verificationNameKanji' })}
          required
        >
          <div className={s.VerificationForm__group}>
            <FormInput
              name={VerificationSchemaKeys.name}
              placeholder={intl.formatMessage({ id: 'profile.verificationName' })}
              value={formik.values.name}
              onChange={formik.handleChange}
              maxLength={maxNameLength}
              error={formik.errors.name}
              disabled={!isPhoneVerified}
              required
            />
            <FormInput
              name={VerificationSchemaKeys.secondName}
              placeholder={intl.formatMessage({ id: 'profile.verificationSecondName' })}
              value={formik.values.secondName}
              onChange={formik.handleChange}
              maxLength={maxNameLength}
              error={formik.errors.secondName}
              disabled={!isPhoneVerified}
              required
            />
          </div>
        </InputLayout>

        <InputLayout
          className={s.VerificationForm__input}
          label={intl.formatMessage({ id: 'profile.verificationNameKatakana' })}
          required
        >
          <div className={s.VerificationForm__group}>
            <FormInput
              name={VerificationSchemaKeys.nameHiragana}
              placeholder={intl.formatMessage({ id: 'profile.verificationFirstNameKatakana' })}
              value={formik.values.nameHiragana}
              onChange={formik.handleChange}
              maxLength={maxNameLength}
              error={formik.errors.nameHiragana}
              disabled={!isPhoneVerified}
              required
            />
            <FormInput
              name={VerificationSchemaKeys.secondNameHiragana}
              placeholder={intl.formatMessage({ id: 'profile.verificationSecondNameKatakana' })}
              value={formik.values.secondNameHiragana}
              onChange={formik.handleChange}
              maxLength={maxNameLength}
              error={formik.errors.secondNameHiragana}
              disabled={!isPhoneVerified}
              required
            />
          </div>
        </InputLayout>

        <FormInput
          className={s.VerificationForm__input}
          type="date"
          name={VerificationSchemaKeys.birthdate}
          label={intl.formatMessage({ id: 'profile.verificationBirthday' })}
          placeholder={intl.formatMessage({ id: 'profile.verificationBirthdayPlaceholder' })}
          value={formik.values.birthdate}
          onChange={formik.handleChange}
          error={formik.errors.birthdate}
          disabled={!isPhoneVerified}
          required
        />

        <InputLayout
          className={s.VerificationForm__input}
          label={intl.formatMessage({ id: 'profile.verificationAddress' })}
          error={formik.errors.addressId}
          required
        >
          {user?.addresses && (
            <AddressLayout
              addresses={user.addresses}
              onClickOpenForm={onClickOpenForm}
              selectedAddressId={formik.values.addressId}
              setSelectedAddressId={(id) => formik.setFieldValue(VerificationSchemaKeys.addressId, id)}
              buttonDisabled={!isPhoneVerified}
              interactive
            />
          )}
        </InputLayout>

        <InputLayout
          classes={{
            label: s.VerificationForm__attachmentsLabel
          }}
          label={intl.formatMessage({ id: 'profile.verificationDocuments' })}
          description={
            <FormattedMessage
              id="profile.verificationDocumentsText"
              values={{
                a: (chunks) => (
                  <Anchor href="/" inline>
                    {chunks}
                  </Anchor>
                )
              }}
            />
          }
          error={formik.errors.attachments as string}
          required
        >
          <div
            className={clsx(s.VerificationForm__attachmentsList, {
              [s.VerificationForm__attachmentsList_filled]: formik.values.attachments.length !== 0
            })}
          >
            {(formik.values.attachments as LocalAttachmentType[]).map((attachment, index) => (
              <VerificationFormAttachment
                name={attachment.title ?? ''}
                url={attachment.thumbnails?.S?.url || attachment.main_file?.url || ''}
                error={attachment.upload_status === AttachmentUploadStatus.Error ? 'upload error' : undefined}
                onDelete={() => onAttachmentDelete(attachment)}
                key={index}
              />
            ))}
          </div>

          {formik.values.attachments.length < DEFAULT_VERIFICATION_MAX_FILES && (
            <InputUpload onChange={onUploadChange} disabled={!isPhoneVerified} multiple>
              <Button
                component={'div'}
                className={s.VerificationForm__attachmentsButton}
                variant={ButtonVariant.PRIMARY_OUTLINE}
                loading={attachmentsUploading}
                disabled={!isPhoneVerified || attachmentsUploading}
              >
                <FormattedMessage id="profile.verificationAttachButton" />
              </Button>
            </InputUpload>
          )}

          <Text variant={TextVariant.BODY_S_NORMAL} color={Colors.GRAY_600}>
            <FormattedMessage
              id="profile.verificationDocumentsSubtext"
              values={{ amount: DEFAULT_VERIFICATION_MAX_FILES, size: DEFAULT_UPLOAD_MAX_SIZE_MB }}
            />
          </Text>
        </InputLayout>
      </Modal.Body>

      <Modal.Footer>
        <Modal.Button onClick={formik.submitForm} disabled={!isPhoneVerified} loading={registerSellerLoading}>
          <FormattedMessage id="profile.verificationSubmitButton" />
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
};
