import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { Modal, ModalBody, ModalButton, ModalFooter } from '../../../../../../common/components/Modal';
import { ButtonVariant } from '../../../../../../common/components/Button';
import s from './RemoveAllFavoritesModal.module.scss';

export interface RemoveAllFavoritesModalProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Модальное окно открыто
   */
  isOpen: boolean;
  /**
   * Обработчик закрытия модального окна
   */
  onClose: () => void;
  /**
   * Обработчик нажатия кнопки подтверждения
   */
  onClickConfirm?: () => void;
  /**
   * Состояния загрузки
   */
  loading?: boolean;
}

export const RemoveAllFavoritesModal: ReactFCC<RemoveAllFavoritesModalProps> = (props) => {
  const { className, isOpen, onClose, onClickConfirm, loading } = props;
  const intl = useIntl();

  return (
    <Modal
      className={clsx(className)}
      title={intl.formatMessage({ id: 'general.removeFavorites' })}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalBody>
        <p>{intl.formatMessage({ id: 'general.removeFavoritesQuestion' })}</p>
      </ModalBody>

      <ModalFooter className={s.RemoveAllFavoritesModal__footer}>
        <ModalButton variant={ButtonVariant.SECONDARY} onClick={onClose}>
          {intl.formatMessage({ id: 'general.back' })}
        </ModalButton>

        <ModalButton onClick={onClickConfirm} loading={loading}>
          {intl.formatMessage({ id: 'general.remove' })}
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
};
