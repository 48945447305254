import clsx from 'clsx';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import metadata from 'libphonenumber-js/min/metadata';
import { FormikErrors, useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { usePrevious } from 'react-use';
import { useMaskito } from '@maskito/react';
import { maskitoPhoneOptionsGenerator } from '@maskito/phone';
import { ReactFCC } from '../../../../common/utils/helperTypes';
import {
  useCreateUserAddressMutation,
  UserAddressCreateInput,
  UserAddressUpdateInput,
  useUpdateUserAddressMutation
} from '../../../../store/graphql';
import { Modal } from '../../../../common/components/Modal';
import { ButtonVariant } from '../../../../common/components/Button';
import {
  DEFAULT_INPUT_DELAY,
  FormCheckbox,
  FormInput,
  FormInputSelect,
  FormLabel
} from '../../../../common/components/inputs';
import { ESpaceSize } from '../../../../common/components/Space/Space';
import { LoaderBox } from '../../../../common/components/Loader';
import { clearCache } from '../../../../app/providers/auth-apollo';
import { useCountry } from '../../hooks/useCountry';
import { useAddress } from '../../hooks/useAddress';
import { AddressPersonalInformation, UserAddress } from '../../types';
import { getGraphQLErrorInfo } from '../../../../common/utils/graphqlErrors';
import { handleDefaultError } from '../../../../common/utils/handleDefaultError';
import { Validator } from '../../../../common/utils/validate';
import { useSingleTimeout } from '../../../../common/hooks/useSingleTimeout';
import { Divider } from '../../../../common/components/Divider/Divider';
import { AddressSchema, AddressSchemaKeys, AddressValues } from './schema';
import { AddressFormFieldsFactory } from './AddressFormFieldsFactory';
import { CALCULATE_DELIVERY_ERROR, JAPANESE_COUNTRY_CODE, PHONE_NOT_VALID } from './constants';
import s from './AddressForm.module.scss';

export interface AddressFormProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Состояние открытости модального окна
   */
  isOpen: boolean;
  /**
   * Обработчик события закрытия модального окна
   */
  onClose: () => void;
  /**
   * Объект адреса для редактирования
   */
  address?: UserAddress | null;
  /**
   * Чекбоксы дефолтных адресов по умолчнию
   */
  defaultsSelected?: boolean;
  /**
   * Персональная информация по умолчанию
   */
  personalInformation?: AddressPersonalInformation;
}

const mutationOptions = {
  refetchQueries: ['User'],
  update: clearCache(['getUserById'])
};

const onlyLatinLettersError = 'Only latin letters are allowed';

export const AddressForm: ReactFCC<AddressFormProps> = (props) => {
  const { className, isOpen, onClose, address: initialAddressProp, defaultsSelected, personalInformation } = props;

  const [_, rerender] = useState({});

  const initialAddressRef = useRef(initialAddressProp);
  const initialAddress = initialAddressRef.current;

  const timeout = useSingleTimeout();

  const mountedRef = useRef(false);
  useEffect(() => {
    mountedRef.current = true;
  }, []);

  useEffect(() => {
    if (initialAddressProp) {
      initialAddressRef.current = initialAddressProp;
      rerender({});
    }
  }, [initialAddressProp]);

  useEffect(() => {
    if (!isOpen) {
      timeout.set(() => {
        initialAddressRef.current = undefined;
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const [saveAddressAnyway, setSaveAddressAnyway] = useState(false);

  const intl = useIntl();

  const initialValues = useMemo(
    () => ({
      title: initialAddress?.title ?? '',
      fullName: initialAddress?.fullName ?? personalInformation?.fullName ?? '',
      email: initialAddress?.email ?? personalInformation?.email ?? '',
      phone: initialAddress?.phone ?? '',
      countryCode: initialAddress?.countryCode ?? '',
      zipCode: initialAddress?.zipCode ?? '',
      state: initialAddress?.state ?? '',
      stateCode: initialAddress?.stateCode ?? '',
      province: initialAddress?.province ?? '',
      provinceCode: initialAddress?.provinceCode ?? '',
      region: initialAddress?.region ?? '',
      regionCode: initialAddress?.regionCode ?? '',
      city: initialAddress?.city ?? '',
      prefecture: initialAddress?.prefecture ?? '',
      town: initialAddress?.town ?? '',
      address: initialAddress?.address ?? '',
      address1: initialAddress?.address1 ?? '',
      address2: initialAddress?.address2 ?? '',
      defaultBilling: initialAddress?.defaultBilling ?? defaultsSelected ?? true,
      defaultShipping: initialAddress?.defaultShipping ?? defaultsSelected ?? true,
      force: initialAddress?.invalid || undefined
    }),
    [initialAddress, defaultsSelected, personalInformation]
  );

  const initialCountryOption = useMemo(
    () =>
      initialAddress
        ? {
            title: initialAddress.country,
            value: initialAddress.countryCode
          }
        : undefined,
    [initialAddress]
  );

  const [createUserAddressMutation, { loading: createUserAddressLoading }] =
    useCreateUserAddressMutation(mutationOptions);
  const [updateUserAddressMutation, { loading: updateUserAddressLoading }] =
    useUpdateUserAddressMutation(mutationOptions);

  const formik = useFormik<AddressValues>({
    initialValues: initialValues as AddressValues,
    validationSchema: AddressSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: true,
    validate: (values) => {
      const errors: FormikErrors<AddressValues> = {};

      const regexp = /[^# /,.@\-0-9\p{L}\p{M}]/mu;

      if (values.countryCode !== JAPANESE_COUNTRY_CODE) {
        if (values.address1 === '') {
          errors.address1 = Validator.errors.required;
        } else if (regexp.test(values.address1)) {
          errors.address1 = onlyLatinLettersError;
        }

        if (values.address2 && regexp.test(values.address2)) {
          errors.address2 = onlyLatinLettersError;
        }
      }

      if (!country?.supportsZipCode) {
        if (!values.city) {
          errors.city = Validator.errors.required;
        }

        if (!values.region) {
          errors.region = Validator.errors.required;
        }
      }

      return errors;
    },
    onSubmit: async (values, helpers) => {
      const input: UserAddressCreateInput = {
        address: values.address || undefined,
        address1: values.address1,
        address2: values.address2 || undefined,
        city: values.city || undefined,
        countryCode: values.countryCode,
        defaultBilling: values.defaultBilling || undefined,
        defaultShipping: values.defaultShipping || undefined,
        email: values.email,
        fullName: values.fullName || undefined,
        phone: values.phone,
        prefecture: values.prefecture || undefined,
        provinceCode: values.provinceCode || undefined,
        region: values.region,
        regionCode: values.regionCode || undefined,
        state: values.state || undefined,
        stateCode: values.stateCode || undefined,
        title: values.title || '',
        town: values.town || undefined,
        zipCode: values.zipCode || undefined,
        force: values.force
      };

      if (!initialAddress) {
        try {
          await createUserAddressMutation({
            variables: {
              input
            }
          });

          toast.success(intl.formatMessage({ id: 'profile.addressCreated' }));

          helpers.resetForm();
          closeForm();
        } catch (e) {
          const graphqlError = getGraphQLErrorInfo(e);
          switch (graphqlError?.extensions?.code) {
            case CALCULATE_DELIVERY_ERROR:
              setSaveAddressAnyway(true);
              toast.error(intl.formatMessage({ id: 'address.error.calculate' }));
              helpers.setFieldValue(AddressSchemaKeys.defaultShipping, false);
              helpers.setFieldValue(AddressSchemaKeys.defaultBilling, false);
              helpers.setFieldValue(AddressSchemaKeys.force, true);
              break;
            case PHONE_NOT_VALID:
              toast.error(intl.formatMessage({ id: 'address.error.phone_not_valid' }));
              helpers.setFieldError(
                AddressSchemaKeys.phone,
                intl.formatMessage({ id: 'address.error.phone_not_valid' })
              );
              break;
            default:
              handleDefaultError('Sorry, an error occurred while creating an address', e);
          }
        }
      } else {
        let updateInput: UserAddressUpdateInput = {
          id: initialAddress.id,
          title: input.title,
          defaultShipping: input.defaultShipping,
          defaultBilling: input.defaultBilling
        };

        if (!initialAddress.sellerAddress) {
          updateInput = {
            ...updateInput,
            ...input
          };
        }

        try {
          await updateUserAddressMutation({
            variables: {
              input: updateInput
            }
          });

          toast.success(intl.formatMessage({ id: 'profile.addressUpdated' }));

          helpers.resetForm();
          closeForm();
        } catch (e) {
          const graphqlError = getGraphQLErrorInfo(e);
          switch (graphqlError?.extensions?.code) {
            case CALCULATE_DELIVERY_ERROR:
              setSaveAddressAnyway(true);
              toast.error(intl.formatMessage({ id: 'address.error.calculate' }));
              if (!isJapaneseAddress) {
                helpers.setFieldValue(AddressSchemaKeys.defaultShipping, false);
                helpers.setFieldValue(AddressSchemaKeys.defaultBilling, false);
                helpers.setFieldValue(AddressSchemaKeys.force, true);
              }
              break;
            case PHONE_NOT_VALID:
              toast.error(intl.formatMessage({ id: 'address.error.phone_not_valid' }));
              helpers.setFieldError(
                AddressSchemaKeys.phone,
                intl.formatMessage({ id: 'address.error.phone_not_valid' })
              );
              break;
            default:
              handleDefaultError(intl.formatMessage({ id: 'address.form.updateError' }), e);
          }
        }
      }
    }
  });

  const formSetFieldValue = formik.setFieldValue;
  const formSetFieldError = formik.setFieldError;
  const formSetValues = formik.setValues;

  const { country, countryInput, setCountryInput, countryOptions } = useCountry({
    countryCode: formik.values.countryCode,
    initialCountryOption
  });

  const { address, cityOptions, addressLoading, zipNotFound, serviceError, zipCodeNotAllowed } = useAddress({
    countryCode: formik.values.countryCode,
    selectedCity: formik.values.city,
    zipCode: country?.supportsZipCode ? formik.values?.zipCode : undefined
  });

  const resetFields = useCallback(() => {
    formSetFieldValue(AddressSchemaKeys.state, '');
    formSetFieldValue(AddressSchemaKeys.stateCode, '');
    formSetFieldValue(AddressSchemaKeys.province, '');
    formSetFieldValue(AddressSchemaKeys.provinceCode, '');
    formSetFieldValue(AddressSchemaKeys.city, '');
    formSetFieldValue(AddressSchemaKeys.prefecture, '');
    formSetFieldValue(AddressSchemaKeys.town, '');
    formSetFieldValue(AddressSchemaKeys.address, '');
    formSetFieldValue(AddressSchemaKeys.region, '');
    formSetFieldValue(AddressSchemaKeys.regionCode, '');
    formSetFieldValue(AddressSchemaKeys.address1, '');
    formSetFieldValue(AddressSchemaKeys.address2, '');
  }, [formSetFieldValue]);

  const cityUpdated = useRef(false);

  const prevCountry = usePrevious(country);

  const phoneOptions = useMemo(() => {
    if (country) {
      return maskitoPhoneOptionsGenerator({ countryIsoCode: country.alpha2 as any, metadata });
    }
  }, [country]);
  const phoneMaskRef = useMaskito({ options: phoneOptions });

  // очищаем при изменении страны
  useEffect(() => {
    if (prevCountry?.id && prevCountry.id !== country?.id) {
      formSetFieldValue(AddressSchemaKeys.zipCode, '');
      formSetFieldValue(AddressSchemaKeys.force, undefined);
      setSaveAddressAnyway(false);
      resetFields();
      cityUpdated.current = false;
    }
  }, [country, formSetFieldValue, prevCountry?.id, resetFields]);

  // очищаем при изменении зипкода
  useEffect(() => {
    if (!initialValues) {
      resetFields();
      cityUpdated.current = false;
      formSetFieldValue(AddressSchemaKeys.force, undefined);
      setSaveAddressAnyway(false);
    }
  }, [formSetFieldValue, initialValues, formik.values.zipCode, resetFields]);

  // обрабатываем ошибки зипкода
  useEffect(() => {
    const error = zipCodeNotAllowed ? 'Zip code is not allowed' : undefined;
    formSetFieldError(AddressSchemaKeys.zipCode, error);
  }, [formSetFieldError, serviceError, zipCodeNotAllowed, zipNotFound]);

  useEffect(() => {
    if (mountedRef.current) {
      formSetFieldValue(AddressSchemaKeys.force, zipNotFound);
      setSaveAddressAnyway(zipNotFound);
    }
  }, [zipNotFound, formSetFieldValue]);

  // Заполняем поля при выборе адреса
  useEffect(() => {
    if (address) {
      formSetFieldValue(AddressSchemaKeys.state, address.state || '');
      formSetFieldValue(AddressSchemaKeys.stateCode, address.stateCode || '');
      formSetFieldValue(AddressSchemaKeys.province, address.province || '');
      formSetFieldValue(AddressSchemaKeys.provinceCode, address.provinceCode || '');
      formSetFieldValue(AddressSchemaKeys.prefecture, address.prefecture || '');
      formSetFieldValue(AddressSchemaKeys.town, address.town || '');
      formSetFieldValue(AddressSchemaKeys.address, address.allAddress || '');

      if (address.countryCode === JAPANESE_COUNTRY_CODE) {
        formSetFieldValue(AddressSchemaKeys.region, address.prefecture || '');
      } else {
        formSetFieldValue(AddressSchemaKeys.region, address.state || '');
        formSetFieldValue(AddressSchemaKeys.regionCode, address.stateCode || '');
      }

      if (!cityUpdated.current) {
        formSetFieldValue(AddressSchemaKeys.city, address.city || '');
        cityUpdated.current = true;
      }
    }
  }, [address, formSetFieldValue, formSetValues]);

  useEffect(() => {
    if (address?.province) {
      formSetFieldValue(AddressSchemaKeys.address1, `${address?.province}, `);
    }
  }, [address, formSetFieldValue]);

  useLayoutEffect(() => {
    if (initialValues) {
      formSetValues(initialValues);

      if (initialValues.force) {
        setSaveAddressAnyway(true);
      }
    }
  }, [formSetValues, initialValues]);

  const closeForm = () => {
    onClose();
    formik.resetForm();
    setCountryInput('');
  };

  const addressDisabled = !country || (country.supportsZipCode && !formik.values.zipCode) || zipCodeNotAllowed; //|| zipNotFound //|| (country.supportsZipCode && (!address || !formik.values.zipCode));
  const zipDisabledByCountry = !!country && !country.supportsZipCode;
  const disabledSellerAddress = !!initialAddress?.sellerAddress;

  const isJapaneseAddress = useMemo(() => {
    return formik.values.countryCode === JAPANESE_COUNTRY_CODE;
  }, [formik.values.countryCode]);

  return (
    <Modal
      title={intl.formatMessage({ id: initialAddress ? 'address.editButton' : 'address.addButton' })}
      className={clsx(className)}
      isOpen={isOpen}
      onClose={closeForm}
    >
      <Modal.Body>
        <FormInput
          name={AddressSchemaKeys.title}
          label={intl.formatMessage({ id: 'address.form.title' })}
          placeholder={intl.formatMessage({ id: 'address.form.title.placeholder' })}
          onChange={formik.handleChange}
          value={formik.values.title}
          error={formik.errors.title}
          space={ESpaceSize.PIXEL_12}
        />

        <FormInput
          name={AddressSchemaKeys.fullName}
          label={intl.formatMessage({ id: 'address.form.fullName' })}
          placeholder={intl.formatMessage({ id: 'address.form.fullName.placeholder' })}
          onChange={formik.handleChange}
          value={formik.values.fullName}
          error={formik.errors.fullName}
          space={ESpaceSize.PIXEL_12}
          required
          disabled={disabledSellerAddress}
        />

        <FormInput
          name={AddressSchemaKeys.email}
          label={intl.formatMessage({ id: 'address.form.email' })}
          placeholder={intl.formatMessage({ id: 'address.form.email.placeholder' })}
          onChange={formik.handleChange}
          value={formik.values.email}
          error={formik.errors.email}
          space={ESpaceSize.PIXEL_12}
          required
          disabled={disabledSellerAddress}
        />

        <FormInputSelect
          name={AddressSchemaKeys.countryCode}
          label={intl.formatMessage({ id: 'address.form.country' })}
          placeholder={intl.formatMessage({ id: 'address.form.country.placeholder' })}
          options={countryOptions || []}
          value={countryInput}
          onChange={(e) => {
            setCountryInput(e.target.value);
            formik.setFieldValue(AddressSchemaKeys.countryCode, '');
          }}
          onItemSelect={(option) => option.value && formik.setFieldValue(AddressSchemaKeys.countryCode, option.value)}
          error={formik.errors.countryCode}
          space={ESpaceSize.PIXEL_12}
          required
          disabled={disabledSellerAddress}
          inputDelayMs={DEFAULT_INPUT_DELAY}
        />

        <FormInput
          name={AddressSchemaKeys.phone}
          label={intl.formatMessage({ id: 'address.form.phone' })}
          placeholder={intl.formatMessage({ id: 'address.form.phone.placeholder' })}
          onChange={formik.handleChange}
          value={formik.values.phone}
          error={formik.errors.phone}
          space={ESpaceSize.PIXEL_12}
          ref={phoneMaskRef}
          required
          disabled={!country || disabledSellerAddress}
        />

        <FormInput
          name={AddressSchemaKeys.zipCode}
          label={intl.formatMessage({ id: 'address.form.zipCode' })}
          placeholder={intl.formatMessage({
            id: zipDisabledByCountry ? 'address.form.zipCodeError.placeholder' : 'address.form.zipCode.placeholder'
          })}
          onChange={formik.handleChange}
          value={formik.values.zipCode}
          error={
            formik.errors.zipCode ||
            (serviceError || zipNotFound ? intl.formatMessage({ id: 'profile.verificationZipConfirm' }) : undefined)
          }
          space={ESpaceSize.PIXEL_12}
          required={!!country?.supportsZipCode}
          disabled={!country || zipDisabledByCountry || disabledSellerAddress}
          inputDelayMs={DEFAULT_INPUT_DELAY}
          classes={{
            error: serviceError || zipNotFound ? s.AddressForm__blackError : undefined
          }}
          isInvalid={serviceError || zipNotFound ? false : undefined}
        />

        {addressLoading && <LoaderBox />}

        <AddressFormFieldsFactory
          formik={formik}
          country={country}
          zipCode={formik.values.zipCode}
          address={address}
          cityOptions={cityOptions}
          disabledSellerAddress={disabledSellerAddress}
        />

        <>
          <FormInput
            name={AddressSchemaKeys.address1}
            label={intl.formatMessage({ id: 'address.form.address1' })}
            placeholder={intl.formatMessage({ id: 'address.form.address1.placeholder' })}
            onChange={formik.handleChange}
            value={formik.values.address1}
            error={formik.errors.address1}
            space={ESpaceSize.PIXEL_12}
            required
            disabled={disabledSellerAddress}
          />

          {/*
          That was deleted, but we may need that in the future
          <FormInput
            name={AddressSchemaKeys.address2}
            label={intl.formatMessage({ id: 'address.form.address2' })}
            placeholder={intl.formatMessage({ id: 'address.form.address2.placeholder' })}
            onChange={formik.handleChange}
            value={formik.values.address2}
            error={formik.errors.address2}
            space={{ top: ESpaceSize.PIXEL_12, bottom: ESpaceSize.PIXEL_24 }}
            disabled={disabledSellerAddress}
          />*/}

          <FormLabel>
            <FormattedMessage id={'address.form.useAddressAs'} />
          </FormLabel>

          <FormCheckbox
            name={AddressSchemaKeys.defaultBilling}
            label={intl.formatMessage({ id: 'address.form.defaultBilling' })}
            onChange={formik.handleChange}
            checked={formik.values.defaultBilling}
            space={{ bottom: ESpaceSize.PIXEL_6 }}
            disabled={defaultsSelected || initialAddress?.defaultBilling || saveAddressAnyway}
          />

          <FormCheckbox
            name={AddressSchemaKeys.defaultShipping}
            label={intl.formatMessage({ id: 'address.form.defaultShipping' })}
            onChange={formik.handleChange}
            checked={formik.values.defaultShipping}
            disabled={defaultsSelected || initialAddress?.defaultShipping || saveAddressAnyway}
          />

          {saveAddressAnyway && !isJapaneseAddress && (
            <>
              <Divider space={ESpaceSize.PIXEL_12} />
              <FormLabel description={intl.formatMessage({ id: 'profile.verificationDeliveryAddressError' })}>
                Manual review
              </FormLabel>
              <FormCheckbox
                name={AddressSchemaKeys.force}
                label={intl.formatMessage({ id: 'profile.verificationCreateAgreement' })}
                onChange={formik.handleChange}
                checked={formik.values.force}
                disabled
              />
            </>
          )}
        </>
      </Modal.Body>

      <Modal.Footer className={s.AddressForm__footer}>
        <Modal.Button variant={ButtonVariant.SECONDARY} onClick={closeForm}>
          <FormattedMessage id={'general.back'} />
        </Modal.Button>

        <Modal.Button
          onClick={formik.submitForm}
          loading={createUserAddressLoading || updateUserAddressLoading}
          disabled={addressDisabled}
        >
          {address ? <FormattedMessage id={'address.saveButton'} /> : <FormattedMessage id={'address.addButton'} />}
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
};
