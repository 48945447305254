import { OrderBoxSizesInput } from '../../../../store/graphql';
import { CartProduct } from '../../../../pages/cart/types';
import { OrderDimensions } from '../../yamatoFee/hooks/useYamatoFee';

export function calculateOverallCartDimensions(
  content: {
    productId: number;
    product?: CartProduct;
  }[]
): OrderDimensions {
  const orderDimensions: OrderBoxSizesInput[] =
    content.map((c) => ({
      weight: c?.product?.weight || 0,
      boxWidth: c?.product?.boxWidth || 0,
      boxHeight: c?.product?.boxHeight || 0,
      boxLength: c?.product?.boxLength || 0
    })) || [];

  return orderDimensions?.reduce(
    (accumulator, item) => {
      Object.keys(item).forEach((key) => {
        accumulator[key as keyof OrderBoxSizesInput] =
          (accumulator[key as keyof OrderBoxSizesInput] || 0) + item[key as keyof OrderBoxSizesInput];
      });
      return accumulator;
    },
    {
      weight: 0,
      boxLength: 0,
      boxWidth: 0,
      boxHeight: 0
    }
  );
}
