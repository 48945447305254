import React from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal } from '../../../../../common/components/Modal';
import { FormInput } from '../../../../../common/components/inputs';
import { ReactFCC } from '../../../../../common/utils/helperTypes';
import { AuthModalBaseProps } from '../types';
import { AuthModalStep } from '../AuthModal';
import { ESpaceSize, Space } from '../../../../../common/components/Space/Space';
import { Alert, AlertVariant } from '../../../../../common/components/Alert';
import { AuthModalEnterEmailKeys, AuthModalEnterEmailSchema } from './authModalEnterEmailSchema';
import s from './AuthModalEnterEmail.module.scss';

export type AuthModalEnterEmailProps = AuthModalBaseProps & {
  error?: boolean;
};

export const AuthModalEnterEmail: ReactFCC<AuthModalEnterEmailProps> = (props) => {
  const { onSubmit, usePassword, changeStep, error } = props;

  const intl = useIntl();

  const wrongPasswordEmailMessage = intl.formatMessage({ id: 'validation.wrongPasswordEmail' });

  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={AuthModalEnterEmailSchema}
      validateOnMount={false}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={onSubmit}
    >
      {({ values, errors, isSubmitting, handleChange, submitForm }) => (
        <>
          <Form>
            <Modal.Body>
              <p>
                {usePassword ? (
                  <FormattedMessage id="auth.emailPasswordText" />
                ) : (
                  <FormattedMessage id="auth.emailText" />
                )}
              </p>

              <FormInput
                type="email"
                name={AuthModalEnterEmailKeys.email}
                label={intl.formatMessage({ id: 'auth.emailLabel' })}
                placeholder="example@gmail.com"
                value={values.email}
                onChange={handleChange}
                error={errors.email}
                required
              />

              {usePassword && (
                <>
                  <Space size={ESpaceSize.PIXEL_8} />
                  <FormInput
                    type="password"
                    name={AuthModalEnterEmailKeys.password}
                    label={intl.formatMessage({ id: 'auth.password' })}
                    placeholder={intl.formatMessage({ id: 'auth.password' })}
                    value={values.password}
                    onChange={handleChange}
                    error={errors.password}
                    required
                  />
                </>
              )}
            </Modal.Body>
          </Form>

          {error && usePassword && (
            <>
              <Space size={ESpaceSize.PIXEL_16} />
              <Alert sharp={true} variant={AlertVariant.Danger}>
                {wrongPasswordEmailMessage}
              </Alert>
              <Space size={ESpaceSize.PIXEL_16} />
            </>
          )}

          <Modal.Footer className={s.AuthModalEnterEmail__footer}>
            <div className={s.AuthModalEnterEmail__switchMode}>
              {usePassword ? (
                <span
                  className={s.AuthModalEnterEmail__switchMode_heading}
                  onClick={() => changeStep?.(AuthModalStep.ENTER_EMAIL)}
                >
                  <FormattedMessage id="auth.useEmailCode" />
                </span>
              ) : (
                <span
                  className={s.AuthModalEnterEmail__switchMode_heading}
                  onClick={() => changeStep?.(AuthModalStep.ENTER_EMAIL_PASSWORD)}
                >
                  <FormattedMessage id="auth.usePassword" />
                </span>
              )}
            </div>
            <Modal.Button type={'submit'} loading={isSubmitting} onClick={submitForm}>
              {usePassword ? (
                <FormattedMessage id="auth.signInButton" />
              ) : (
                <FormattedMessage id="auth.emailSubmitButton" />
              )}
            </Modal.Button>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
};
