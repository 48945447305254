import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { Modal, ModalProps } from '../../../../../common/components/Modal';
import { ButtonVariant } from '../../../../../common/components/Button';
import { useDeleteUserAvatar } from '../../hooks';
import { Text } from '../../../../../common/components/Text';
import { handleDefaultError } from '../../../../../common/utils/handleDefaultError';
import s from './DeleteUserAvatarModal.module.scss';

export interface DeleteUserAvatarModalProps extends Pick<ModalProps, 'isOpen' | 'onClose'> {
  /**
   * Дополнительный css-класс
   */
  className?: string;
}

export const DeleteUserAvatarModal: ReactFCC<DeleteUserAvatarModalProps> = (props) => {
  const { className, isOpen, onClose } = props;
  const intl = useIntl();

  const [deleteAvatar, { loading }] = useDeleteUserAvatar();

  const onClickDelete = useCallback(async () => {
    try {
      await deleteAvatar();
      onClose?.();
      toast.success(intl.formatMessage({ id: 'profile.deleteAvatar' }));
    } catch (e) {
      handleDefaultError(intl.formatMessage({ id: 'profile.avatarDeleteError' }), e);
    }
  }, [deleteAvatar, onClose]);

  return (
    <Modal
      className={clsx(s.DeleteUserAvatarModal, className)}
      title={'Delete the avatar'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Body>
        <Text>{intl.formatMessage({ id: 'profile.deleteAvatarQuestion' })}</Text>
      </Modal.Body>

      <Modal.Footer className={s.DeleteUserAvatarModal__footer}>
        <Modal.Button variant={ButtonVariant.SECONDARY} onClick={onClose}>
          Back
        </Modal.Button>

        <Modal.Button onClick={onClickDelete} loading={loading}>
          Delete
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
};
