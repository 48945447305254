import React from 'react';
import { BsBook, BsBriefcase, BsExclamationCircle } from 'react-icons/bs';
import { groupBy } from 'lodash-es';
import { ABOUT_ROUTE, HELP_ROUTE, MANUALS_ROUTE, PathBuilder, PROHIBITED_ITEMS_ROUTE } from '../../common/utils/routes';
import { ESpaceSize, Space } from '../../common/components/Space/Space';
import { ArticleMeta, useArticlesMetaQuery } from '../../store/graphql';
import { LoaderBox } from '../../common/components/Loader';
import { SupportCard } from './SupportCard/SupportCard';
import s from './SupportHub.module.scss';

export const SupportHub = () => {
  const { data: articlesMeta, loading: articlesMetaLoading } = useArticlesMetaQuery();

  const articlesData = articlesMeta?.result || [];
  const groupedArticles = groupBy(articlesData, (a: ArticleMeta) => a.type);

  if (articlesMetaLoading) {
    return <LoaderBox />;
  }
  return (
    <div className={s.SupportHub}>
      <div className={s.SupportHub__content}>
        <div className={s.SupportHub__navButtons}>
          <SupportCard
            headingId={'footer.aboutUs'}
            link={ABOUT_ROUTE}
            icon={<BsBriefcase className={s.SupportCard__badge_icon} size={25} />}
            items={groupedArticles?.Info?.map((a: ArticleMeta) => ({
              titleId: a.name,
              link: PathBuilder.getSupportAboutPath(a.slug)
            }))}
          />
          <SupportCard
            headingId={'footer.userManual'}
            link={MANUALS_ROUTE}
            icon={<BsBook className={s.SupportCard__badge_icon} size={25} />}
            items={[
              ...(groupedArticles?.Manual?.map((a: ArticleMeta) => ({
                titleId: a.name,
                link: PathBuilder.getSupportManualPath(a.slug)
              })) || []),
              {
                titleId: 'footer.linkProhibited',
                link: PROHIBITED_ITEMS_ROUTE
              }
            ]}
          />
          <SupportCard
            headingId={'footer.help'}
            link={HELP_ROUTE}
            icon={<BsExclamationCircle className={s.SupportCard__badge_icon} size={25} />}
            items={[
              ...(groupedArticles?.Help?.map((a: ArticleMeta) => ({
                titleId: a.name,
                link: PathBuilder.getSupportHelpPath(a.slug)
              })) || [])
            ]}
          />
        </div>
        <Space size={ESpaceSize.PIXEL_24} />
      </div>
    </div>
  );
};
