import React from 'react';
import { BsPencil } from 'react-icons/bs';
import { MdRefresh } from 'react-icons/md';
import { formatMoney } from 'common/utils/format';
import { ReactFCC } from 'common/utils/helperTypes';
import { Divider } from 'common/components/Divider/Divider';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { Grid } from 'common/components/Grid/Grid';
import { AnchorVariant } from 'common/components/Anchor/Anchor';
import { ProductStatusEnum, UserRoles, useShowcaseProductsQuery } from 'store/graphql';
import { Link } from 'common/components/Link/Link';
import { Pagination, usePagination } from 'common/components/Pagination';
import { LoaderBox } from 'common/components/Loader';
import { DEFAULT_PAGE_LIMIT } from '../../../../../common/components/Pagination';
import { PathBuilder } from '../../../../../common/utils/routes';
import { useUser } from '../../../../../entities/user';
import { useAuth } from '../../../../../app/providers/auth-apollo';
import { ProductBadge, ProductBadgeSize } from '../../../../../entities/product';
import s from './ShowcaseProducts.module.scss';

const placeholderImageUrl = 'https://via.placeholder.com/85';

export const ShowcaseProducts: ReactFCC = () => {
  const { loading: profileLoading } = useAuth();
  const { user, hasRole } = useUser();

  const seller = user?.seller;

  const { offset, setOffset } = usePagination();

  const { data: productsQuery, loading: productsLoading } = useShowcaseProductsQuery({
    skip: !seller?.id,
    variables: {
      sellerId: seller?.id || 0,
      limit: DEFAULT_PAGE_LIMIT,
      offset
    },
    fetchPolicy: 'cache-and-network'
  });

  const products = productsQuery?.products.entries;
  const pagination = productsQuery?.products.pagination;

  const loading = profileLoading || productsLoading;

  // useUpdatePageCount(pagination?.pagesCount);

  return (
    <div className={s.ShowcaseProducts}>
      <Divider space={ESpaceSize.PIXEL_16} />

      {loading ? (
        <LoaderBox />
      ) : (
        <>
          {products?.map((product, index) => (
            <React.Fragment key={index}>
              <Grid className={s.ShowcaseProducts__product} cols={9}>
                <Grid.GridItem cols={1}>
                  <Link to={PathBuilder.getProductPath(product.id)}>
                    <img
                      className={s.ShowcaseProducts__productImage}
                      src={product.mainPhoto?.thumbnails?.S?.url || placeholderImageUrl}
                      alt=""
                    />
                  </Link>
                </Grid.GridItem>

                <Grid.GridItem cols={{ xs: 1, lg: 7 }}>
                  <div className={s.ShowcaseProducts__productInfo}>
                    <Link
                      to={PathBuilder.getProductPath(product.id)}
                      state={{ from: window.location.pathname }}
                      variant={AnchorVariant.BODY}
                    >
                      <b>{product.name}</b>
                    </Link>
                    <Space size={ESpaceSize.PIXEL_4} />
                    <b>{formatMoney(product.price)}</b>
                  </div>
                </Grid.GridItem>

                <Grid.GridItem className={s.ShowcaseProducts__productControls} cols={1}>
                  {product.status === ProductStatusEnum.ForSale ? (
                    <Link
                      to={PathBuilder.getEditProductPath(product.id)}
                      variant={AnchorVariant.BODY}
                      leftIcon={BsPencil}
                      leftIconOptions={{ size: 16 }}
                    />
                  ) : (
                    <ProductBadge size={ProductBadgeSize.SMALL} />
                  )}
                  {hasRole(UserRoles.Manager) && (
                    <Link
                      to={PathBuilder.getRelistProductPath(product.id)}
                      variant={AnchorVariant.BODY}
                      leftIcon={MdRefresh}
                      leftIconOptions={{ size: 16 }}
                    />
                  )}
                </Grid.GridItem>
              </Grid>

              <Divider space={ESpaceSize.PIXEL_16} />
            </React.Fragment>
          ))}
        </>
      )}

      <Space size={ESpaceSize.PIXEL_24} />

      {pagination && (
        <Pagination limit={pagination.limit} offset={offset} setOffset={setOffset} totalCount={pagination.totalCount} />
      )}
    </div>
  );
};
