import { useAsync } from 'react-use';
import { useState } from 'react';
import { LOCAL_STORAGE_CART } from '../storageKeys';
import { useIsProductInCartQuery } from '../graphql';
import { useLocalStorage } from '../../common/hooks/useLocalStorage';
import { useAuth } from '../../app/providers/auth-apollo';

export function useProductInCart(productId?: number | null) {
  const { isAuthenticated } = useAuth();
  const [localProductIds] = useLocalStorage<number[]>(LOCAL_STORAGE_CART, []);

  const [isLoadingRefetch, setIsLoadingRefetch] = useState(false);

  const {
    data: isProductInCartQuery,
    loading,
    refetch
  } = useIsProductInCartQuery({
    variables: {
      productId: productId || 0
    },
    skip: !productId || !isAuthenticated,
    fetchPolicy: 'network-only'
  });

  useAsync(async () => {
    if (isAuthenticated) {
      try {
        setIsLoadingRefetch(true);
        await refetch();
      } finally {
        setIsLoadingRefetch(false);
      }
    }
  }, [localProductIds, isAuthenticated, refetch]);

  return [
    !!(isAuthenticated ? isProductInCartQuery?.isProductInCart : localProductIds?.includes(productId || 0)),
    loading || isLoadingRefetch
  ] as const;
}
