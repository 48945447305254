import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { BsCheck } from 'react-icons/bs';
import { toast } from 'react-toastify';
import {
  ArisoraOrderStatus,
  OrderPaymentTypeEnum,
  useCheckSellerRateForOrderQuery,
  useConfirmOrderDeliveringMutation,
  useDebtsQuery,
  useOrderContentQuery,
  useOrderQuery
} from 'store/graphql';
import { Divider } from 'common/components/Divider/Divider';
import { ReactFCC } from 'common/utils/helperTypes';
import { LinkBack } from 'common/components/LinkBack/LinkBack';
import { EBreakpoints, useBreakpoint } from 'common/hooks/useBreakpoint';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { Heading, HeadingSize } from 'common/components/Heading/Heading';
import { Grid } from 'common/components/Grid/Grid';
import { LoaderBox } from 'common/components/Loader/LoaderBox';
import { Button, ButtonSize, ButtonVariant } from 'common/components/Button';
import { useToggle } from 'common/hooks/useToggle';
import { Link } from 'common/components/Link/Link';
import { FormattedDate } from 'common/components/CustomFormatters';
import { getGraphqlErrorMessage } from 'common/utils/graphqlErrors';
import { handleDefaultError } from 'common/utils/handleDefaultError';
import { clearCache } from 'app/providers/auth-apollo';
import { EMDASH, tryNumber } from '@proscom/ui-utils';
import { getOrderTotalPrice, OrderStatusBadge } from '../../../entities/order';
import { ProductCard } from '../../../entities/product';
import { NOT_FOUND_ROUTE, ORDER_PAGE_PARAM, PathBuilder } from '../../../common/utils/routes';
import { Anchor } from '../../../common/components/Anchor';
import { Head } from '../../../common/components/Head';
import { useUrlParam } from '../../../common/hooks/useUrlParam';
import { useUser } from '../../../entities/user';
import { Pagination, usePagination } from '../../../common/components/Pagination';
import { PaymentCardNumber } from '../../../common/components/payment/PaymentCardNumber/PaymentCardNumber';
import { useCurrency } from '../../../store/currency';
import { formatMoney } from '../../../common/utils/format';
import { PaymentButtonProvider } from '../../../widgets/paypal';
import { CountdownRender } from '../../../common/components/CountdownRender';
import { useDownloadInvoice } from './hooks/useDownloadInvoice';
import { OrderDebtAlert, OrderHistory, OrderPageBanner, OrderPageReviewModal } from './components/';
import { OrderProgress } from './components/OrderProgress';
import { OrderReconfirmAlert } from './components/OrderReconfirm/OrderReconfirm';
import { OrderSellerTicketModal } from './components/OrderSellerTicket/OrderSellerTicketModal';
import s from './OrderPage.module.scss';

const mutationOptions = {
  refetchQueries: ['Order', 'CheckSellerRateForOrder'],
  update: clearCache(['getOrderById', 'checkSellerRateForOrder'])
};

const acceptanceMutationOptions = {
  refetchQueries: ['Order', 'SellerOrder'],
  update: clearCache(['order'])
};

export const DEFAULT_ORDER_CONTENT_LIMIT = 10;

export const OrderPage: ReactFCC = () => {
  const navigate = useNavigate();

  const [reviewModalOpen, { set: openReviewModal, unset: closeReviewModal }] = useToggle(false);
  const [sellerTickerModalOpen, { set: openSellerTicketModal, unset: closeSellerTicketModal }] = useToggle(false);

  const [contentLimit, setContentLimit] = useState<number | null>(null);

  const contentRef = useRef<HTMLDivElement>(null);

  const isMobile = useBreakpoint(EBreakpoints.LG_DOWN);

  const entityId = tryNumber(useUrlParam(ORDER_PAGE_PARAM));

  const { user } = useUser();

  const trustedSeller = user?.seller?.isTrusted;

  const { offset, setOffset } = usePagination();

  const {
    data: orderData,
    loading: orderLoading,
    stopPolling: stopOrderPolling,
    startPolling: startOrderPolling,
    refetch: refetchOrder,
    error
  } = useOrderQuery({
    skip: !entityId,
    variables: {
      input: {
        id: entityId || 0
      }
    }
  });

  const { data: orderContentData, loading: orderContentLoading } = useOrderContentQuery({
    skip: !entityId,
    fetchPolicy: 'cache-first',
    variables: {
      input: {
        id: entityId || 0,
        pagination: {
          limit: contentLimit ? contentLimit : DEFAULT_ORDER_CONTENT_LIMIT,
          offset: contentLimit ? 0 : offset
        }
      }
    }
  });

  //customer content
  const order = orderData?.order?.order.order;
  const contents = useMemo(
    () => orderContentData?.order.order?.content || [],
    [orderContentData?.order.order?.content]
  );
  const packages = orderData?.order?.order.packages;
  const pagination = orderContentData?.order?.order.pagination;

  const status = order?.status;
  const statusInternationalDelivery = status === ArisoraOrderStatus.InternationalDelivery;
  const statusDeliveryWithinJapan = status === ArisoraOrderStatus.DeliveringWithinJapan;
  const statusIsDelivered = status === ArisoraOrderStatus.Delivered;
  const statusCancelled = status === ArisoraOrderStatus.Canceled;

  const orderDeliveryCost = order?.deliveryCost || 0;
  const isPaid = order?.isPaidOrder;
  const isJapaneseOrder = order?.japaneseOrder;

  const acceptRequired = order?.status !== ArisoraOrderStatus.Canceled;
  const defaultCombine = !statusIsDelivered && !statusInternationalDelivery && !acceptRequired && !statusCancelled;
  const trustedCombine = defaultCombine && (packages !== undefined || trustedSeller);

  const contactSellerTicketId = order?.orderBuyerSellerTicketId;

  const intl = useIntl();

  const { download } = useDownloadInvoice(order?.id, order?.ID);

  useLayoutEffect(() => {
    if (!order || !user) {
      return;
    }

    if (order.customerId !== user.id) {
      navigate(NOT_FOUND_ROUTE, { replace: true });
      return;
    }
  }, [navigate, order, user]);

  const orderCost = getOrderTotalPrice(contents, orderDeliveryCost, false);
  const totalCost = getOrderTotalPrice(contents, orderDeliveryCost);
  const totalSize = contents.reduce((acc, item) => acc + item.weight, 0) || 0;
  const deliveryCost = (totalCost || 0) - (orderCost || 0);

  const commission = contents?.reduce((acc, item) => acc + item.commission, 0) || 0;

  const {
    data: debtsQueryData,
    loading: debtLoading,
    stopPolling: stopDebtPolling,
    startPolling: startDebtPolling,
    refetch: refetchDebts
  } = useDebtsQuery({
    variables: {
      orderIds: [order?.id || 0],
      paymentType: order?.paymentType!
    },
    skip: !order?.id || !order?.paymentType
  });
  const debt = debtsQueryData?.debts?.[0];

  useEffect(() => {
    if (error) {
      navigate(NOT_FOUND_ROUTE);
    }
  }, [error, navigate]);

  const { data: checkSellerRateData, loading: checkSellerRateLoading } = useCheckSellerRateForOrderQuery({
    variables: {
      orderId: order?.id || 0
    },
    skip: !order?.id
  });

  const canCustomerRate = !!checkSellerRateData?.result && statusIsDelivered;

  const [confirmOrderDeliveringMutation, { loading: confirmOrderDeliveringLoading }] =
    useConfirmOrderDeliveringMutation(mutationOptions);

  const handleConfirmOrderDelivering = async () => {
    if (!order?.id) {
      return;
    }

    try {
      await confirmOrderDeliveringMutation({
        variables: {
          orderId: order?.id || 0
        }
      });

      toast.success(intl.formatMessage({ id: 'general.incorrectFileType' }));
    } catch (e) {
      const errorMessage = getGraphqlErrorMessage(e);

      if (errorMessage) {
        handleDefaultError(errorMessage, e);
      }
    }
  };

  const { convertCurrency, currencyLoading } = useCurrency();

  useLayoutEffect(() => {
    if (!debt) {
      stopDebtPolling();
    } else {
      startDebtPolling(5000);
    }
  }, [debt, stopDebtPolling, startDebtPolling]);

  useLayoutEffect(() => {
    if (!order?.isPaidOrder) {
      startOrderPolling(5000);
    } else {
      stopOrderPolling();
    }
  }, [startOrderPolling, stopOrderPolling, order]);

  const loading = orderLoading || debtLoading || checkSellerRateLoading || currencyLoading;

  return (
    <>
      <Head title={`Order №${order?.ID} ${EMDASH}`} />

      <div className={s.OrderPage}>
        <LinkBack>
          <FormattedMessage id="general.back" />
        </LinkBack>

        <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />

        {loading ? (
          <LoaderBox />
        ) : (
          <>
            {order && contents && (
              <div className={s.OrderPage__container}>
                <div className={s.OrderPage__title}>
                  <Heading size={HeadingSize.H3}>
                    <FormattedMessage id="general.order" /> {order.ID}
                  </Heading>

                  <OrderStatusBadge status={order.status} isProblem={order.isProblem} />
                  <div className={s.OrderPage__buttonBlock}>
                    <div className={s.OrderPage__date}>
                      <Grid.GridItem cols={{ xs: 1, lg: 2 }} className={s.OrderPage__option}>
                        <FormattedMessage id="profile.orderDate" />
                      </Grid.GridItem>

                      <Grid.GridItem cols={{ xs: 1, lg: 10 }}>
                        <FormattedDate value={new Date(order.orderDate)} />
                      </Grid.GridItem>
                    </div>
                    {!order.isPaidOrder && (
                      <>
                        <CountdownRender orderTime={order.orderDate}>
                          <div>{`Please, complete payment within 3 hours or the order will be cancelled`}</div>
                        </CountdownRender>
                        {order.paymentType === OrderPaymentTypeEnum.Paypal && (
                          <div className={s.OrderPage__buttonBlock_buttons}>
                            <PaymentButtonProvider orderPrice={totalCost} order_id={order.id} />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />

                <>
                  <OrderProgress status={order.status} isProblem={order.isProblem} />

                  <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />

                  {statusInternationalDelivery ||
                    (statusDeliveryWithinJapan && isJapaneseOrder && (
                      <>
                        <OrderPageBanner>
                          <Heading size={HeadingSize.H5}>Confirm the order receipt</Heading>

                          <OrderPageBanner.Text>
                            The seller has shipped the package. Once you receive the parcel, click on the button to
                            confirm.
                          </OrderPageBanner.Text>

                          <OrderPageBanner.Actions>
                            <Button
                              variant={ButtonVariant.PRIMARY}
                              loading={confirmOrderDeliveringLoading}
                              onClick={handleConfirmOrderDelivering}
                            >
                              <FormattedMessage id={'order.confirmDelivery'} />
                            </Button>
                          </OrderPageBanner.Actions>
                        </OrderPageBanner>

                        <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />
                      </>
                    ))}

                  {statusIsDelivered && (
                    <>
                      <OrderPageBanner className={s.OrderPage__successBanner}>
                        <div className={s.OrderPage__successBannerText}>
                          <div className={s.OrderPage__checkIconContainer}>
                            <BsCheck className={s.OrderPage__checkIcon} />
                          </div>
                          The order is received
                        </div>
                      </OrderPageBanner>

                      <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />
                    </>
                  )}
                </>

                {canCustomerRate && (
                  <>
                    <OrderPageBanner>
                      <Heading className={s.OrderPage__alertTitle} size={HeadingSize.H5}>
                        Review about the seller
                      </Heading>

                      <OrderPageBanner.Text>
                        {'You have received the order. Do not forget to leave a review and rate the seller'}
                      </OrderPageBanner.Text>

                      <OrderPageBanner.Actions>
                        <Button variant={ButtonVariant.PRIMARY_OUTLINE} onClick={openReviewModal}>
                          Leave review
                        </Button>
                      </OrderPageBanner.Actions>
                    </OrderPageBanner>

                    <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />
                  </>
                )}

                {debt && (
                  <>
                    <OrderDebtAlert
                      debt={debt}
                      orderId={entityId || 0}
                      orderID={order.ID}
                      paymentType={order.paymentType}
                      onSuccess={refetchDebts}
                      refetchOrder={refetchOrder}
                      refetchDebts={refetchDebts}
                    />

                    <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />
                  </>
                )}

                {!order.isPaidOrder && order.paymentType !== OrderPaymentTypeEnum.Paypal && (
                  <>
                    <OrderReconfirmAlert
                      orderId={entityId || 0}
                      orderID={order.ID}
                      totalCost={convertCurrency(totalCost)}
                      refetchOrders={refetchOrder}
                    />
                    <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />
                  </>
                )}

                <>
                  <div className={s.OrderPage__badge} ref={contentRef}>
                    <span className={s.OrderPage__badgeAmount}>
                      <FormattedMessage id={'order.productsLabel'} />
                    </span>
                  </div>
                  <>
                    <Grid>
                      <Grid.GridItem cols={{ xs: 3, lg: 12 }} className={s.OrderPage__option}>
                        <div className={s.OrderPage__contactSeller}>
                          <div>
                            <span className={s.OrderPage__optionMarginRight}>
                              <FormattedMessage id="general.seller" />
                            </span>
                            <Link
                              to={PathBuilder.getSellerPublicPath(order.sellerId || 0)}
                              className={s.OrderPage__contactSeller_link}
                            >
                              {order.sellerNickname}
                            </Link>
                          </div>
                          {order.isPaidOrder && order.status !== ArisoraOrderStatus.Canceled && (
                            <Button
                              onClick={() =>
                                contactSellerTicketId
                                  ? navigate(PathBuilder.getTicketRequestPath(contactSellerTicketId))
                                  : openSellerTicketModal()
                              }
                              size={ButtonSize.SMALL}
                            >
                              <FormattedMessage id={'order.customerTicketButton'} />
                            </Button>
                          )}
                        </div>
                      </Grid.GridItem>
                    </Grid>
                  </>

                  <Divider space={ESpaceSize.PIXEL_12} />
                  {orderContentLoading ? (
                    <>
                      <Space size={ESpaceSize.PIXEL_24} />
                      <LoaderBox />
                      <Space size={ESpaceSize.PIXEL_24} />
                    </>
                  ) : (
                    <div className={s.OrderPage__products}>
                      {contents.map(
                        (product, index) =>
                          product && (
                            <div className={s.OrderPage__products_item}>
                              <React.Fragment key={index}>
                                <ProductCard
                                  product={{
                                    ...product,
                                    id: product.productId
                                  }}
                                  key={index}
                                />
                              </React.Fragment>
                              {!isMobile ? (
                                index !== contents?.length - 2 &&
                                index !== contents.length - 1 && <Divider space={ESpaceSize.PIXEL_12} />
                              ) : (
                                <Divider space={ESpaceSize.PIXEL_12} />
                              )}
                            </div>
                          )
                      )}
                    </div>
                  )}
                  {!isMobile && <Divider space={{ bottom: ESpaceSize.PIXEL_12 }} />}
                  {pagination && (
                    <div
                      className={
                        contentLimit !== pagination.totalCount && pagination.totalCount > DEFAULT_ORDER_CONTENT_LIMIT
                          ? s.OrderPage__products_pagination_control
                          : s.OrderPage__products_pagination_control_end
                      }
                    >
                      {contentLimit !== pagination.totalCount &&
                        pagination.totalCount > DEFAULT_ORDER_CONTENT_LIMIT && (
                          <Button
                            variant={ButtonVariant.PRIMARY_OUTLINE}
                            size={ButtonSize.SMALL}
                            onClick={() => {
                              setContentLimit(pagination?.totalCount);
                              contentRef?.current?.scrollIntoView({ block: 'center' });
                            }}
                          >
                            <FormattedMessage id={'order.showAll'} />
                          </Button>
                        )}
                      {contentLimit === pagination.totalCount &&
                        pagination.totalCount > DEFAULT_ORDER_CONTENT_LIMIT && (
                          <Button
                            variant={ButtonVariant.PRIMARY_OUTLINE}
                            size={ButtonSize.SMALL}
                            onClick={() => {
                              setContentLimit(DEFAULT_ORDER_CONTENT_LIMIT);
                              contentRef?.current?.scrollIntoView({ block: 'center' });
                            }}
                          >
                            <FormattedMessage id={'order.hide'} />
                          </Button>
                        )}
                      <Pagination
                        className={s.OrderPage__products_pagination}
                        limit={pagination.limit}
                        offset={offset}
                        setOffset={setOffset}
                        totalCount={pagination.totalCount}
                        totalCountText={intl.formatMessage(
                          { id: 'order.totalItems' },
                          { count: pagination.totalCount }
                        )}
                      />
                    </div>
                  )}
                  <Space size={ESpaceSize.PIXEL_24} />
                </>

                <>
                  <div className={s.OrderPage__badge}>
                    <span className={s.OrderPage__badgeAmount}>
                      <FormattedMessage id={'order.summary'} />
                    </span>
                  </div>
                  <>
                    <Grid className={s.OrderPage__details}>
                      <>
                        <Grid.GridItem cols={{ xs: 1, lg: 10 }} className={s.OrderPage__option}>
                          <div className={s.OrderPage__details_item}>
                            <span className={s.OrderPage__details_item_title}>
                              <FormattedMessage id={'profile.orderCost'} />
                            </span>
                            <div className={s.OrderPage__details_item_extended}>
                              <div>
                                {formatMoney(orderCost || 0)}
                                <span className={s.OrderPage__costHelper}>({convertCurrency(orderCost || 0)})</span>
                              </div>
                            </div>
                          </div>
                        </Grid.GridItem>

                        <Grid.GridItem cols={{ xs: 1, lg: 10 }} className={s.OrderPage__option}>
                          <div className={s.OrderPage__details_item}>
                            <span className={s.OrderPage__details_item_title}>
                              <FormattedMessage id={'order.shippingCost'} />
                            </span>
                            <div className={s.OrderPage__details_item_extended}>
                              <div>
                                {formatMoney(deliveryCost || 0)}
                                <span className={s.OrderPage__costHelper}>({convertCurrency(deliveryCost || 0)})</span>
                              </div>
                            </div>
                          </div>
                        </Grid.GridItem>

                        <Grid.GridItem cols={{ xs: 1, lg: 10 }} className={s.OrderPage__option}>
                          <div className={s.OrderPage__details_item}>
                            <span className={s.OrderPage__details_item_title}>
                              <FormattedMessage id={'profile.orderTotalCost'} />
                            </span>
                            <div className={s.OrderPage__details_item_extended}>
                              <div>
                                {formatMoney(totalCost || 0)}
                                <span className={s.OrderPage__costHelper}>({convertCurrency(totalCost || 0)})</span>
                              </div>
                            </div>
                          </div>
                        </Grid.GridItem>
                        <Space size={ESpaceSize.PIXEL_12} />

                        <Grid.GridItem cols={{ xs: 1, lg: 10 }} className={s.OrderPage__option}>
                          <div className={s.OrderPage__details_item}>
                            <span className={s.OrderPage__details_item_title}>
                              <FormattedMessage id={'order.invoice'} />
                            </span>
                            <div className={s.OrderPage__details_item_extended}>
                              <Anchor onClick={download}>
                                <FormattedMessage id={'general.download'} />
                              </Anchor>
                            </div>
                          </div>
                        </Grid.GridItem>
                      </>
                    </Grid>
                  </>
                  <Space size={ESpaceSize.PIXEL_24} />
                </>

                <>
                  <div className={s.OrderPage__badge}>
                    <span className={s.OrderPage__badgeAmount}>
                      <FormattedMessage id={'order.details'} />
                    </span>
                  </div>
                  <>
                    <Grid className={s.OrderPage__details}>
                      <Grid.GridItem cols={{ xs: 1, lg: 1 }} className={s.OrderPage__option}>
                        <div className={s.OrderPage__details_item}>
                          <span className={s.OrderPage__details_item_title}>
                            <FormattedMessage id={'order.contactInformation'} />
                          </span>
                          <div className={s.OrderPage__details_item_extended}>
                            <span>
                              {user?.name} {user?.secondName}
                            </span>
                            <span className={s.OrderPage__details_item_short}>{user?.email}</span>
                            <span>{order.customerPhone}</span>
                          </div>
                        </div>
                      </Grid.GridItem>

                      <Grid.GridItem cols={{ xs: 1, lg: 1 }} className={s.OrderPage__option}>
                        <div className={s.OrderPage__details_item}>
                          <span className={s.OrderPage__details_item_title}>
                            <FormattedMessage id={'payment.method'} />
                          </span>
                          {/* TODO Paypal Icon*/}
                          {order.customerLast4 && (
                            <div className={s.OrderPage__details_item_extended}>
                              <PaymentCardNumber cardLastNumbers={order?.customerLast4} />
                            </div>
                          )}
                        </div>
                      </Grid.GridItem>

                      <Grid.GridItem cols={{ xs: 1, lg: 1 }} className={s.OrderPage__option}>
                        <div className={s.OrderPage__details_item}>
                          <span className={s.OrderPage__details_item_title}>
                            <FormattedMessage id={'address.select.titleShipping'} />
                          </span>
                          <div className={s.OrderPage__details_item_extended}>
                            <span>{order?.destinationAddress?.address}</span>
                            <span>{order?.destinationAddress?.address1}</span>
                            <span className={s.OrderPage__details_item_gray}>
                              {user?.name} {user?.secondName}
                            </span>
                            <span className={s.OrderPage__details_item_gray}>
                              {order.destinationAddress?.city} {order?.destinationAddress?.country},{' '}
                              {order.destinationAddress?.zipCode}
                            </span>
                          </div>
                        </div>
                      </Grid.GridItem>

                      <Grid.GridItem cols={{ xs: 1, lg: 1 }} className={s.OrderPage__option}>
                        <div className={s.OrderPage__details_item}>
                          <span className={s.OrderPage__details_item_title}>
                            <FormattedMessage id={'address.select.titleBilling'} />
                          </span>
                          <div className={s.OrderPage__details_item_extended}>
                            <span>{order?.billingAddress?.title}</span>
                            <span>{order?.billingAddress?.address}</span>
                            <span>{order?.billingAddress?.address1}</span>
                            <span className={s.OrderPage__details_item_gray}>
                              {user?.name} {user?.secondName}
                            </span>
                            <span className={s.OrderPage__details_item_gray}>
                              {order.billingAddress?.city} {order?.billingAddress?.country},{' '}
                              {order.billingAddress?.zipCode}
                            </span>
                          </div>
                        </div>
                      </Grid.GridItem>
                    </Grid>
                  </>
                </>

                <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_32} />

                {entityId && (
                  <div className={s.OrderPage__history}>
                    <OrderHistory orderId={entityId} />
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>

      {order?.id && order?.ID && order?.sellerId && (
        <OrderSellerTicketModal
          title={intl.formatMessage({ id: 'order.customerTicketTitle' })}
          isOpen={sellerTickerModalOpen}
          onClose={closeSellerTicketModal}
          sellerId={order.sellerId}
          orderID={order.ID}
          orderId={order.id}
        />
      )}

      <OrderPageReviewModal
        orderId={order?.id || 0}
        title={`Review about the seller`}
        isOpen={reviewModalOpen}
        onClose={closeReviewModal}
        isSeller={false}
      />
    </>
  );
};
