import React, { useState } from 'react';
import { useFormik } from 'formik';
import { BsCheckCircleFill, BsPencil } from 'react-icons/bs';

import { tryNumber } from '@proscom/ui-utils';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';
import { useIsMobile } from '../../../../../common/hooks/useIsMobile';
import {
  OrderPackages,
  useConfirmDeliveryPriceMutation,
  useRecalculateDeliveryPriceMutation
} from '../../../../../store/graphql';
import { getGraphqlErrorMessage } from '../../../../../common/utils/graphqlErrors';
import { handleDefaultError } from '../../../../../common/utils/handleDefaultError';
import { FormInput } from '../../../../../common/components/inputs';
import { Button, ButtonFit, ButtonVariant } from '../../../../../common/components/Button';
import {
  ParcelDimensionsKeys,
  ParcelDimensionsSchema,
  ParcelLimitSchema,
  TParcelDimensionsValues
} from '../OrderCombineFedex/schema';
import { useToggle } from '../../../../../common/hooks/useToggle';
import { OrderCombineRecalculationModal } from '../OrderComibineRecalculationModal/OrderCombineRecalculationModal';
import { OrderDimensions, useYamatoFee } from '../../../../../features/order/yamatoFee/hooks/useYamatoFee';
import { ESpaceSize, Space } from '../../../../../common/components/Space/Space';
import { Alert, AlertVariant } from '../../../../../common/components/Alert';
import { useCurrency } from '../../../../../store/currency';
import { sellerOrderMutationOptions } from '../../SellerOrderPage';
import { YuPacketBlock } from '../../../../../features/order/yuPacket/YuPacketBlock';
import s from './OrderCombineParcelInput.module.scss';

export type OrderCombineParcelInputProps = {
  sellerAddressId: number;
  packages?: OrderPackages | null;
  orderId: number;
  isSent: boolean;
  dimensions: OrderDimensions;
  trustedOrder: boolean;
  initialShipFee: number;
  isYuPacket?: boolean;
};

export const OrderCombineParcelInput = (props: OrderCombineParcelInputProps) => {
  const { sellerAddressId, orderId, packages, dimensions, trustedOrder, isYuPacket, initialShipFee } = props;

  const isMobile = useIsMobile();
  const intl = useIntl();

  const { convertCurrency } = useCurrency();

  const [recalculationModalOpened, { set: openRecalculationModal, unset: closeRecalculationModal }] = useToggle(false);
  const [forceRecalculation, setForceRecalculation] = useState(false);
  const [recalculationSuccess, setRecalculationSuccess] = useState<boolean | null>(null);
  const [editEnabled, setEditEnabled] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [recalculateDeliveryPrice, { loading: recalculateDeliveryPriceLoading }] =
    useRecalculateDeliveryPriceMutation(sellerOrderMutationOptions);

  const [confirmDeliveryPrice, { loading: confrimDeliveryPriceLoading }] =
    useConfirmDeliveryPriceMutation(sellerOrderMutationOptions);

  const deliveryPriceAccepted =
    packages?.deliveryPriceFulfilled !== null && packages?.deliveryPriceFulfilled !== undefined;
  const deliveryPriceFulfilled = packages?.deliveryPriceFulfilled;

  const formik = useFormik<TParcelDimensionsValues>({
    initialValues: {
      weight: dimensions.weight,
      boxHeight: dimensions.boxHeight,
      boxWidth: dimensions.boxWidth,
      boxLength: dimensions.boxLength
    },
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: ParcelDimensionsSchema,
    onSubmit: async (values) => {
      await recalculateDelivery(values);
    }
  });

  const handleSizeEdit = async () => {
    setError(null);
    if (editEnabled) {
      try {
        await ParcelLimitSchema.validate(formik.values);
        await recalculateDelivery(formik.values);
        setEditEnabled(false);
        return;
      } catch (e: any) {
        setError(e.message);
      }
    }
    setEditEnabled(true);
  };

  const forceRecalculate = () => {
    setForceRecalculation(true);
    formik.submitForm();
  };

  const recalculateDelivery = async (values: TParcelDimensionsValues) => {
    try {
      if (!forceRecalculation) setRecalculationSuccess(null);
      openRecalculationModal();
      const { data: success } = await recalculateDeliveryPrice({
        variables: {
          input: {
            orderId: orderId,
            sellerAddressId: sellerAddressId,
            newSizes: values,
            shippingFee: shipmentFee,
            force: forceRecalculation,
            isTrusted: trustedOrder,
            kuronekoType: kuronekoPossibleType || undefined
          }
        }
      });
      if (success?.result !== undefined) {
        setRecalculationSuccess(success?.result);
        if (success.result || forceRecalculation) {
          closeRecalculationModal();
          setForceRecalculation(false);
        }
      }
    } catch (e) {
      closeRecalculationModal();
      const errorMessage = getGraphqlErrorMessage(e);
      if (errorMessage) {
        handleDefaultError(errorMessage, e);
      }
      throw e;
    }
  };

  const { shipmentFee, kuronekoEnabled, kuronekoPossibleType, toggleKuroneko } = useYamatoFee({
    dimensions: {
      boxHeight: formik.values.boxHeight,
      boxWidth: formik.values.boxWidth,
      boxLength: formik.values.boxLength,
      weight: formik.values.weight
    },
    isTrusted: trustedOrder,
    isYuPacket
  });

  const handleConfirmPrice = async () => {
    await confirmDeliveryPrice({
      variables: {
        input: {
          orderId,
          kuronekoType: kuronekoEnabled ? kuronekoPossibleType : undefined,
          isTrusted: trustedOrder,
          shipmentFee: shipmentFee
        }
      }
    });
  };

  const showYuPacketBlock = !!kuronekoPossibleType && !trustedOrder && !deliveryPriceAccepted;
  const finalFee = deliveryPriceAccepted && !trustedOrder ? initialShipFee : shipmentFee;

  return (
    <>
      <>
        {error && (
          <>
            <Alert variant={AlertVariant.Danger}>{error}</Alert>
            <Space size={ESpaceSize.PIXEL_8} />
          </>
        )}
        {showYuPacketBlock && <YuPacketBlock kuronekoEnabled={kuronekoEnabled} toggleKuroneko={toggleKuroneko} />}
        <div className={s.OrderCombineParcelInput__parcels}>
          <div className={s.OrderCombineParcelInput__form}>
            <FormInput
              type={'number'}
              label={intl.formatMessage({ id: 'orderCombine.weight' })}
              name={ParcelDimensionsKeys.weight}
              value={formik.values.weight}
              onChange={(e) => formik.setFieldValue(ParcelDimensionsKeys.weight, tryNumber(e.target.value))}
              unitRight={'kg'}
              placeholder={intl.formatMessage({ id: 'orderCombine.enterWeight' })}
              inputDelayMs={200}
              error={formik.errors.weight}
              disabled={deliveryPriceAccepted || !editEnabled}
              required
            />
            <FormInput
              type={'number'}
              label={intl.formatMessage({ id: 'orderCombine.height' })}
              name={ParcelDimensionsKeys.boxHeight}
              value={formik.values.boxHeight}
              onChange={(e) => formik.setFieldValue(ParcelDimensionsKeys.boxHeight, tryNumber(e.target.value))}
              placeholder={intl.formatMessage({ id: 'orderCombine.enterHeight' })}
              unitRight={'cm'}
              inputDelayMs={200}
              error={formik.errors.boxHeight}
              disabled={deliveryPriceAccepted || !editEnabled}
              required
            />
            <FormInput
              type={'number'}
              label={intl.formatMessage({ id: 'orderCombine.width' })}
              name={ParcelDimensionsKeys.boxWidth}
              value={formik.values.boxWidth}
              onChange={(e) => formik.setFieldValue(ParcelDimensionsKeys.boxWidth, tryNumber(e.target.value))}
              unitRight={'cm'}
              inputDelayMs={200}
              placeholder={intl.formatMessage({ id: 'orderCombine.enterWidth' })}
              error={formik.errors.boxWidth}
              disabled={deliveryPriceAccepted || !editEnabled}
              required
            />
            <FormInput
              type={'number'}
              label={intl.formatMessage({ id: 'orderCombine.length' })}
              name={ParcelDimensionsKeys.boxLength}
              value={formik.values.boxLength}
              onChange={(e) => formik.setFieldValue(ParcelDimensionsKeys.boxLength, tryNumber(e.target.value))}
              unitRight={'cm'}
              inputDelayMs={200}
              placeholder={intl.formatMessage({ id: 'orderCombine.enterLength' })}
              error={formik.errors.boxLength}
              disabled={deliveryPriceAccepted || !editEnabled}
              required
            />
          </div>
          <div
            className={clsx(
              s.OrderCombineParcelInput__actions,
              deliveryPriceAccepted && s.OrderCombineParcelInput__actions_success
            )}
          >
            <Button
              onClick={handleSizeEdit}
              className={s.OrderCombineParcelInput__actions_button}
              variant={deliveryPriceAccepted ? ButtonVariant.SUCCESS_OUTLINE : ButtonVariant.PRIMARY}
              fit={isMobile ? ButtonFit.FILL : ButtonFit.FIT}
              leftIcon={deliveryPriceAccepted ? BsCheckCircleFill : BsPencil}
              disabled={deliveryPriceAccepted}
            >
              {deliveryPriceAccepted
                ? intl.formatMessage({ id: 'orderCombine.priceRecalculated' })
                : editEnabled
                ? intl.formatMessage({ id: 'orderCombine.save' })
                : intl.formatMessage({ id: 'general.edit' })}
            </Button>
            {!deliveryPriceAccepted && !editEnabled && (
              <Button
                onClick={handleConfirmPrice}
                className={s.OrderCombineParcelInput__actions_button}
                variant={ButtonVariant.SUCCESS}
                fit={isMobile ? ButtonFit.FILL : ButtonFit.FIT}
                loading={confrimDeliveryPriceLoading}
                disabled={editEnabled}
              >
                {intl.formatMessage({ id: 'general.confirm' })}
              </Button>
            )}
          </div>
        </div>
        {deliveryPriceAccepted && !deliveryPriceFulfilled && (
          <>
            <Space size={ESpaceSize.PIXEL_8} />
            <FormattedMessage id={'orderCombine.additionalPayment'} />
          </>
        )}
      </>
      {finalFee > 0 && (
        <div>
          <Space size={ESpaceSize.PIXEL_16} />
          <span className={s.OrderCombineParcelInput__parcels_counter}>
            <FormattedMessage id={'profile.walletOrderShippingFee'} /> {convertCurrency(finalFee)}
          </span>
          <Space size={ESpaceSize.PIXEL_16} />
        </div>
      )}
      <OrderCombineRecalculationModal
        isOpen={recalculationModalOpened}
        onClose={closeRecalculationModal}
        loading={recalculateDeliveryPriceLoading}
        success={recalculationSuccess}
        force={forceRecalculation}
        onForce={forceRecalculate}
      />
    </>
  );
};
