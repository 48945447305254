import { InferType, object, string } from 'yup';
import { IntlShape } from 'react-intl';

import { validationMessages } from '../../../../common/utils/validate';

const createValidationObject = (intl: IntlShape) => {
  const getMessage = (id: keyof typeof validationMessages, values?: Record<string, any>) =>
    intl.formatMessage(validationMessages[id], values);

  return {
    currentPassword: string().optional(),
    newPassword: string()
      .min(8, getMessage('passwordMin'))
      .max(50, getMessage('passwordMax'))
      .matches(/(?=.*[A-Z])/, getMessage('passwordUppercase'))
      .matches(/(?=.*[a-z])/, getMessage('passwordLowercase'))
      .matches(/(?=.*\d)/, getMessage('passwordNumber'))
      .matches(/(?=.*[@$!%*?&])/, getMessage('passwordSpecial'))
      .defined(getMessage('required'))
  };
};

export const ChangePasswordSchema = (intl: IntlShape) => object(createValidationObject(intl));

export type TChangePasswordValues = InferType<ReturnType<typeof ChangePasswordSchema>>;

export const ChangePasswordKeys = Object.keys(
  createValidationObject({ formatMessage: (msg: any) => msg.defaultMessage } as IntlShape)
).reduce(
  (acc, key) => ({
    ...acc,
    [key]: key
  }),
  {}
) as Record<keyof ReturnType<typeof createValidationObject>, string>;
