import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { EMDASH } from '@proscom/ui-utils';
import { EventTypeEnum, NotificationSortDirection, useNotificationsQuery } from 'store/graphql';
import { Pagination, usePagination } from 'common/components/Pagination';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { Divider } from 'common/components/Divider/Divider';
import { LoaderBox } from 'common/components/Loader';
import { NotificationItem } from 'pages/notifications/NotificationItem/NotificationItem';
import s from './OrderHistory.module.scss';

export interface OrderHistoryProps {
  orderId: number;
}

export const OrderHistory = ({ orderId }: OrderHistoryProps) => {
  const { offset, setOffset } = usePagination({ scrollToTopOnPageChange: false });

  const { data: notificationsData, loading: notificationsLoading } = useNotificationsQuery({
    variables: {
      input: {
        filter: {
          orderIds: orderId ? [orderId] : []
        },
        sort: {
          createdAt: NotificationSortDirection.Desc
        },
        pagination: {
          // limit: DEFAULT_PAGE_LIMIT,
          limit: 25,
          offset
        }
      }
    },
    skip: !orderId
  });

  const notifications = notificationsData?.notifications.entries || [];
  const pagination = notificationsData?.notifications.pagination;

  return (
    <div className={s.OrderHistory}>
      <div className={s.OrderHistory__badge}>
        <FormattedMessage id="order.historyTitle" />
      </div>

      {notificationsLoading ? (
        <LoaderBox />
      ) : notifications.length === 0 ? (
        EMDASH
      ) : (
        <>
          {notifications.map((item, iItem) => (
            <Fragment key={item.id}>
              <NotificationItem
                className={
                  item.event === EventTypeEnum.TrustPointsAddition
                    ? s.OrderHistory__notificationTrustPoints
                    : s.OrderHistory__notificationItem
                }
                isRead={true}
                {...item}
              />
              {iItem < notifications.length && <Divider />}
            </Fragment>
          ))}

          {pagination && notifications.length > pagination.totalCount && (
            <>
              <Space size={ESpaceSize.PIXEL_32} />
              <Pagination
                limit={pagination.limit}
                offset={offset}
                setOffset={setOffset}
                totalCount={pagination.totalCount}
                showTotalCount={false}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};
