import { array, InferType, mixed, number, object, string } from 'yup';
import { IntlShape } from 'react-intl';
import { DeliverySpeedDaysEnum, ProductStatusEnum } from '../../../../store/graphql';
import { validationMessages } from '../../../../common/utils/validate';

export const productAttributeLimits = {
  price: {
    min: 700,
    max: 9999999,
    unit: '¥'
  },
  weight: {
    min: 0.1,
    max: 50,
    unit: 'kg'
  },
  size: {
    min: 1,
    max: 50,
    unit: 'cm'
  }
};

export const descriptionMaxLength = 500;

export const productNameMaxLength = 50;

export const dimensionsMaxSum = 260;

const createValidationObject = (intl: IntlShape) => {
  const getMessage = (id: keyof typeof validationMessages, values?: Record<string, any>) =>
    intl.formatMessage(validationMessages[id], values);

  return {
    categoryId: number().defined(getMessage('required')),
    subCategoryId: number().defined(getMessage('required')),
    subSubCategoryId: number().defined(getMessage('required')),
    name: string()
      .min(3, getMessage('minLength', { min: 3 }))
      .max(50, getMessage('maxLength', { max: 50 }))
      .defined(getMessage('required')),
    description: string()
      .max(descriptionMaxLength, getMessage('maxLength', { max: descriptionMaxLength }))
      .defined(getMessage('required')),
    brandId: string().optional(),
    colorId: number().optional(),
    warehouseNumber: number().optional(),
    yuPacket: string().optional(),
    condition: string().defined(getMessage('required')),
    status: string().default(ProductStatusEnum.ForSale).defined(getMessage('required')),
    specificValues: array().of(mixed().optional()).defined(),
    price: number()
      .positive(getMessage('positiveNumber'))
      .integer(getMessage('mustBeInteger'))
      .min(
        productAttributeLimits.price.min,
        getMessage('minValue', {
          value: `${productAttributeLimits.price.unit}${new Intl.NumberFormat().format(
            productAttributeLimits.price.min
          )}`
        })
      )
      .max(
        productAttributeLimits.price.max,
        getMessage('maxValue', {
          value: `${productAttributeLimits.price.unit}${new Intl.NumberFormat().format(
            productAttributeLimits.price.max
          )}`
        })
      )
      .defined(getMessage('required')),
    weight: number()
      .positive(getMessage('positiveNumber'))
      .min(
        productAttributeLimits.weight.min,
        getMessage('minValue', { value: `${productAttributeLimits.weight.min}${productAttributeLimits.weight.unit}` })
      )
      .max(
        productAttributeLimits.weight.max,
        getMessage('maxValue', { value: `${productAttributeLimits.weight.max}${productAttributeLimits.weight.unit}` })
      )
      .defined(getMessage('required')),
    boxHeight: number()
      .positive(getMessage('positiveNumber'))
      .integer(getMessage('mustBeInteger'))
      .min(
        productAttributeLimits.size.min,
        getMessage('minValue', { value: `${productAttributeLimits.size.min}${productAttributeLimits.size.unit}` })
      )
      .defined(getMessage('required')),
    boxWidth: number()
      .positive(getMessage('positiveNumber'))
      .integer(getMessage('mustBeInteger'))
      .min(
        productAttributeLimits.size.min,
        getMessage('minValue', { value: `${productAttributeLimits.size.min}${productAttributeLimits.size.unit}` })
      )
      .defined(getMessage('required')),
    boxLength: number()
      .positive(getMessage('positiveNumber'))
      .integer(getMessage('mustBeInteger'))
      .min(
        productAttributeLimits.size.min,
        getMessage('minValue', { value: `${productAttributeLimits.size.min}${productAttributeLimits.size.unit}` })
      )
      .defined(getMessage('required')),
    deliverySpeedDays: string().default(DeliverySpeedDaysEnum.OneOrTwo).defined(getMessage('required')),
    dimensions: number().optional(),
    mainPhotoIndex: number().integer().optional()
  };
};

export const CreateProductSchema = (intl: IntlShape) => object(createValidationObject(intl));

export type TCreateProductValues = InferType<ReturnType<typeof CreateProductSchema>>;

export const CreateProductSchemaKeys = Object.keys(
  createValidationObject({ formatMessage: (msg: any) => msg.defaultMessage } as IntlShape)
).reduce(
  (acc, key) => ({
    ...acc,
    [key]: key
  }),
  {}
) as Record<keyof ReturnType<typeof createValidationObject>, string>;
