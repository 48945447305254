import React, { useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoaderBox } from '../../../common/components/Loader';
import { Divider } from '../../../common/components/Divider/Divider';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import { Grid } from '../../../common/components/Grid';
import { Heading, HeadingSize } from '../../../common/components/Heading';
import { Link } from '../../../common/components/Link';

import { Button, ButtonFit, ButtonVariant, IconButton, IconButtonSize } from '../../../common/components/Button';
import { Modal } from '../../../common/components/Modal';
import { useToggle } from '../../../common/hooks/useToggle';
import { PathBuilder } from '../../../common/utils/routes';
import { Pagination } from '../../../common/components/Pagination';
import { formatMoney } from '../../../common/utils/format';
import { Head } from '../../../common/components/Head';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { useDraftData } from './hooks/useDraftData';
import s from './DraftsPage.module.scss';

export const DraftsPage = () => {
  const [isDeleteModal, { set: openDeleteModal, unset: closeDeleteModal }] = useToggle();
  const intl = useIntl();
  const isMobile = useIsMobile();

  const closeDeleteDraftModal = () => {
    closeDeleteModal();
    setDeleteDraftId(null);
  };

  const {
    productDrafts,
    paginationOptions,
    removeProductDraft,
    removeAllProductDrafts,
    removeAllDraftsLoading,
    removeDraftLoading,
    dataLoading: loading
  } = useDraftData({
    onRemoveDraftSuccess: () => closeDeleteDraftModal()
  });

  const [deleteDraftId, setDeleteDraftId] = useState<number | null>(null);

  const placeholderImageUrl = `https://via.placeholder.com/100x100`;

  return (
    <div className={s.DraftsPage}>
      <Head title={intl.formatMessage({ id: 'profile.draftsTitle' })} />
      <Grid.GridItem full>
        <Heading size={HeadingSize.H3}>
          <FormattedMessage id={'profile.draftsTitle'} />
        </Heading>

        <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />
      </Grid.GridItem>

      {loading ? (
        <LoaderBox />
      ) : (
        <>
          {!!productDrafts?.length && (
            <>
              <Button variant={ButtonVariant.PRIMARY_OUTLINE} fit={ButtonFit.FIT} onClick={() => openDeleteModal()}>
                <BsTrash size={24} />
                <FormattedMessage id="profile.draftsRemoveAllButton" />
              </Button>
              <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />
            </>
          )}
          {productDrafts &&
            productDrafts?.map((draft, index) => (
              <React.Fragment key={index}>
                <Link to={PathBuilder.getEditProductDraftPath(draft.id)} className={s.DraftsPage__draftLink}>
                  <Divider space={ESpaceSize.PIXEL_8} />
                  <div className={s.DraftsPage__product}>
                    <div className={s.DraftsPage__content}>
                      <img
                        className={s.DraftsPage__productImage}
                        src={draft?.mainPhoto?.thumbnails?.S?.url || placeholderImageUrl}
                        alt=""
                      />
                      <div className={s.DraftsPage__textContent}>
                        <b>{draft.name || '-'}</b>
                        <Space size={ESpaceSize.PIXEL_4} />
                        <b>{formatMoney(draft.price || 0)}</b>
                      </div>
                      <IconButton
                        className={s.DraftsPage__removeButton}
                        icon={BsTrash}
                        size={IconButtonSize.MEDIUM}
                        iconSize={13}
                        onClick={(e) => {
                          e.preventDefault();
                          openDeleteModal();
                          setDeleteDraftId(draft.id);
                        }}
                      />
                    </div>
                  </div>
                </Link>
              </React.Fragment>
            ))}
          {!productDrafts?.length && (
            <Grid.GridItem full>
              <FormattedMessage id={'profile.noDrafts'} />
            </Grid.GridItem>
          )}
          <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_40} />
          {paginationOptions.pagination && !!productDrafts?.length && (
            <Pagination
              limit={paginationOptions.pagination.limit}
              offset={paginationOptions.offset}
              setOffset={paginationOptions.setOffset}
              totalCount={paginationOptions.pagination.totalCount}
            />
          )}
        </>
      )}

      <Space size={ESpaceSize.PIXEL_24} />
      <Modal
        title={intl.formatMessage({ id: 'profile.deleteDraftsTitle' })}
        isOpen={isDeleteModal}
        onClose={() => closeDeleteDraftModal()}
      >
        <Modal.Body>
          {deleteDraftId ? (
            <FormattedMessage id={'profile.draftsDelete'} />
          ) : (
            <FormattedMessage id={'profile.draftsRemoveAll'} />
          )}
        </Modal.Body>

        <Modal.Footer className={s.DraftsPage__modalFooterButtons}>
          <Modal.Button
            onClick={deleteDraftId ? () => removeProductDraft(deleteDraftId) : () => removeAllProductDrafts()}
            loading={removeDraftLoading || removeAllDraftsLoading}
          >
            <FormattedMessage id="general.delete" />
          </Modal.Button>
          <Modal.Button
            variant={ButtonVariant.SECONDARY}
            onClick={() => closeDeleteDraftModal()}
            loading={removeDraftLoading || removeAllDraftsLoading}
          >
            <FormattedMessage id="general.back" />
          </Modal.Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
