import React, { MouseEventHandler, useLayoutEffect, useRef } from 'react';
import { Container, Form } from 'react-bootstrap';
import { BsListUl, BsSearch, BsX } from 'react-icons/bs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { Key } from 'ts-key-enum';
import { tryNumber } from '@proscom/ui-utils';
import { useUi } from '../../../../store/ui';
import { Grid } from '../../../../common/components/Grid';
import { GoogleTagEvents, googleTagSendDefaultEvent } from '../../../../features/analytics';
import { isKey } from '../../../../common/utils/isKey';
import {
  CATEGORY_PAGE_PARAM,
  HOME_ROUTE,
  PathBuilder,
  SUB_CATEGORY_PAGE_PARAM,
  SUB_SUB_CATEGORY_PAGE_PARAM
} from '../../../../common/utils/routes';
import { useUrlParam } from '../../../../common/hooks/useUrlParam';
import { HeaderNavLink } from '../HeaderNavLink';
import { HeaderNavLang } from '../HeaderNavLang';
import { UserPanel } from '../UserPanel';
import { MobileNavbar } from '../MobileNavbar';
import { useIsDesktop } from '../../../../common/hooks/useIsDesktop';
import { Navigation } from '../Navigation';
import { HeaderNavCurrency } from '../HeaderNavCurrency';
import logoSrc from './assets/logo.svg';
import s from './Header.module.scss';

export interface NavbarProps {
  className?: string;
  showTopNav: boolean;
  showMobileNav: boolean;
}

export const Header: React.FC<NavbarProps> = ({ className, showTopNav, showMobileNav }) => {
  const intl = useIntl();

  const { catalogOpen: catalogOpened, setCatalogOpen: setCatalogOpened } = useUi();
  const CatalogIcon = catalogOpened ? BsX : BsListUl;

  const isDesktop = useIsDesktop();

  const navigate = useNavigate();
  const { search } = useLocation();

  const desktopInputRef = useRef<HTMLInputElement>(null);
  const mobileInputRef = useRef<HTMLInputElement>(null);

  const categoryId = tryNumber(useUrlParam(CATEGORY_PAGE_PARAM), 0);
  const subCategoryId = tryNumber(useUrlParam(SUB_CATEGORY_PAGE_PARAM), 0);
  const subSubCategoryId = tryNumber(useUrlParam(SUB_SUB_CATEGORY_PAGE_PARAM), 0);

  const processSearch = () => {
    const value = (desktopInputRef.current?.value || mobileInputRef.current?.value || '').trim();
    let restParams: string | undefined = undefined;
    if (search.includes('?q')) {
      if (search.includes('&')) {
        restParams = search.substring(search.indexOf('&') + 1);
      }
    } else if (search.includes('?') && !search.includes('?q')) {
      restParams = search.substring(search.indexOf('?') + 1);
    }

    /**
     * Сначала пушим в историю роут только с категорией, далее добавляем строку поиска,
     * чтобы при нажатии кнопки "назад" браузера мы остались в категории
     */
    navigate(
      PathBuilder.getCatalogPath({
        categoryId,
        subCategoryId,
        subSubCategoryId,
        restParams
      })
    );

    if (value) {
      setTimeout(() => {
        navigate(
          PathBuilder.getCatalogPath({
            categoryId,
            subCategoryId,
            subSubCategoryId,
            searchQuery: value,
            restParams
          })
        );
      }, 0);
    }

    googleTagSendDefaultEvent(GoogleTagEvents.search, {
      search_term: value
    });
    setCatalogOpened(false);
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (isKey(e, Key.Enter)) {
      processSearch();
      mobileInputRef.current?.blur();
    }
  };

  const openMobileSearch = () => {
    if (!catalogOpened) {
      setCatalogOpened(true);
    }

    setTimeout(() => {
      if (mobileInputRef.current) {
        mobileInputRef.current.focus();
      }
    });
  };

  const handleLogoClick: MouseEventHandler = (e) => {
    if (!showTopNav) {
      e.preventDefault();
    }
    setCatalogOpened(false);
  };

  const { pathname } = useLocation();

  useLayoutEffect(() => {
    if (!pathname.includes('catalog')) {
      if (desktopInputRef.current) {
        desktopInputRef.current.value = '';
      }

      if (mobileInputRef.current) {
        mobileInputRef.current.value = '';
      }
    }
  }, [pathname]);

  const logo = (
    <Link className={s.Header__logo} to={HOME_ROUTE} onClick={handleLogoClick} reloadDocument>
      <img src={logoSrc} alt="" />
    </Link>
  );

  const catalogButton = (
    <HeaderNavLink className={s.Header__navLinkCatalog} onClick={() => !catalogOpened && setCatalogOpened(true)}>
      <CatalogIcon size={20} className="me-2" />
      {isDesktop && (
        <span>
          <FormattedMessage id="header.catalog" />
        </span>
      )}
    </HeaderNavLink>
  );

  return (
    <>
      <div className={clsx(s.Header, className)}>
        <Container className={s.Header__container}>
          {isDesktop && (
            <div className={s.Header__nav}>
              <div className={s.Header__nav_left}>
                <div className={s.Header__logCatBox}>
                  {logo}
                  {showTopNav && catalogButton}
                </div>
              </div>

              {showTopNav && (
                <>
                  <div className={s.Header__nav_mid}>
                    <div className={s.Header__search}>
                      <Form.Control
                        type="search"
                        placeholder={intl.formatMessage({
                          id: categoryId ? 'header.searchInCategoryPlaceholder' : 'header.searchPlaceholder'
                        })}
                        className={s.Header__searchInput}
                        aria-label="Search"
                        onKeyDown={onKeyDown}
                        ref={desktopInputRef}
                        maxLength={500}
                      />

                      <BsSearch className={s.Header__searchIcon} onClick={processSearch} />
                    </div>
                  </div>

                  <div className={s.Header__nav_right}>
                    <div className={s.Header__ext}>
                      <UserPanel className={s.Header__userPanel} lang={true} currency={true} />
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {!isDesktop && (
            <Grid className={s.Header__nav_mobile} cols={{ xs: 3 }}>
              <Grid.GridItem className={s.Header__nav_left} cols={{ lg: 1 }}>
                {showTopNav && catalogButton}
              </Grid.GridItem>

              <Grid.GridItem className={s.Header__nav_mid} cols={{ lg: 1 }}>
                {logo}
              </Grid.GridItem>

              <Grid.GridItem className={s.Header__nav_right} cols={{ lg: 1 }}>
                {showTopNav && (
                  <>
                    <BsSearch size={20} className={s.Header__searchButton} onClick={openMobileSearch} />
                    <HeaderNavLang
                      className={s.Header__lang}
                      children={<HeaderNavCurrency className={s.Header__lang} />}
                    />
                  </>
                )}
              </Grid.GridItem>
            </Grid>
          )}
        </Container>
      </div>

      {showMobileNav && !isDesktop && <MobileNavbar />}

      <Navigation
        open={catalogOpened}
        onClose={() => setCatalogOpened(false)}
        searchRef={mobileInputRef}
        desktopRef={desktopInputRef}
        onKeyDown={onKeyDown}
      />
    </>
  );
};
