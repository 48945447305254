import { defineMessages, IntlShape } from 'react-intl';

export const validateEmail = (v: string) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v);

export const validateNickname = (v: string) => /^[A-Za-z0-9]+([A-Za-z0-9]*|[_.]?[A-Za-z0-9]+)*$/.test(v);

export const validationMessages = defineMessages({
  required: { id: 'validation.required', defaultMessage: 'Required field' },
  wrongEmail: { id: 'validation.wrongEmail', defaultMessage: 'E-mail entered incorrectly' },
  wrongNickname: { id: 'validation.wrongNickname', defaultMessage: 'Must contain only letters, numbers, "_" or "."' },
  wrongPhone: { id: 'validation.wrongPhone', defaultMessage: 'Phone number is incorrect' },
  positiveNumber: { id: 'validation.positiveNumber', defaultMessage: 'Must be greater than 0' },
  mustBeInteger: { id: 'validation.mustBeInteger', defaultMessage: 'Should be an integer' },
  minLength: { id: 'validation.minLength', defaultMessage: 'Must be at least {min} characters long' },
  maxLength: { id: 'validation.maxLength', defaultMessage: 'Must be maximum {max} characters long' },
  minValue: { id: 'validation.minValue', defaultMessage: 'Can’t be less than {value}' },
  maxValue: { id: 'validation.maxValue', defaultMessage: 'Can’t be greater than {value}' },
  minFiles: { id: 'validation.minFiles', defaultMessage: 'Please attach exactly {value} files.' },
  katakana: {
    id: 'validation.katakana',
    defaultMessage: 'The input must consist only of full-width Katakana characters.'
  },
  japaneseLatin: {
    id: 'validation.japaneseLatin',
    defaultMessage: 'The input must contain only Japanese or Latin characters.'
  },
  passwordMin: {
    id: 'validation.passwordMin',
    defaultMessage: 'Password must be at least 8 characters long'
  },
  passwordMax: {
    id: 'validation.passwordMax',
    defaultMessage: 'Password cannot be longer than 50 characters'
  },
  passwordUppercase: {
    id: 'validation.passwordUppercase',
    defaultMessage: 'Password must contain at least one uppercase letter'
  },
  passwordLowercase: {
    id: 'validation.passwordLowercase',
    defaultMessage: 'Password must contain at least one lowercase letter'
  },
  passwordNumber: {
    id: 'validation.passwordNumber',
    defaultMessage: 'Password must contain at least one number'
  },
  passwordSpecial: {
    id: 'validation.passwordSpecial',
    defaultMessage: 'Password must contain at least one special character'
  }
});

export const getValidationMessage = (intl: IntlShape, messageId: keyof typeof validationMessages): string => {
  return intl.formatMessage(validationMessages[messageId]);
};

export const validateErrors = {
  required: 'Required field',
  wrongEmail: 'E-mail entered incorrectly',
  wrongNickname: 'Must contains only letters, numbers and "_" or "."',
  positiveNumber: 'Must be greater than 0'
};

export class Validator {
  static errors = {
    required: 'Required field',
    wrongEmail: 'E-mail entered incorrectly',
    wrongNickname: 'Must contains only letters, numbers and "_" or "."',
    positiveNumber: 'Must be greater than 0',
    maxLength: (length = 255) => `Must be maximum ${length} characters long`
  };

  static validateEmail = (v: string) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v);

  static validateNickname = (v: string) => /^[A-Za-z0-9]+([A-Za-z0-9]*|[_.]?[A-Za-z0-9]+)*$/.test(v);
}
