import React, { useRef } from 'react';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { SellerVerificationEnum, useRemoveProfileMutation } from 'store/graphql';
import { useUi } from 'store/ui/useUi';
import { LoaderBox } from 'common/components/Loader/LoaderBox';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { Heading, HeadingSize } from 'common/components/Heading/Heading';
import { ReactFCC } from 'common/utils/helperTypes';
import { usePayment } from 'store/payment/usePayment';
import { Loader, LoaderSize } from 'common/components/Loader/Loader';
import { PaymentAddButton } from 'common/components/payment/PaymentAddButton/PaymentAddButton';
import { PaymentCardNumber } from 'common/components/payment/PaymentCardNumber/PaymentCardNumber';
import { Anchor, AnchorVariant } from 'common/components/Anchor/Anchor';
import { BsLock, BsPencil, BsPhone, BsTrash } from 'react-icons/bs';
import { AddressLayoutWithForm, getAddressInformationFromUser } from '../../../widgets/address';
import { Head } from '../../../common/components/Head';
import { UserCard } from '../../../widgets/user/UserCard';
import { TrustPointsNotification } from '../trustPoints/components/TrustPointsNotification';
import { useAuth } from '../../../app/providers/auth-apollo';
import { useUser } from '../../../entities/user';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { useIsDesktop } from '../../../common/hooks/useIsDesktop';
import { HOME_ROUTE, PERSONAL_INFORMATION_ROUTE, PROFILE_ROUTE } from '../../../common/utils/routes';
import { useToggle } from '../../../common/hooks/useToggle';
import { PhoneVerificationModal } from '../../../features/user/phoneVerification';
import { Grid } from '../../../common/components/Grid';
import { LinkBack } from '../../../common/components/LinkBack';
import { Modal } from '../../../common/components/Modal';
import { handleDefaultError } from '../../../common/utils/handleDefaultError';
import { FormInput } from '../../../common/components/inputs';
import { keyWordToDeleteProfile } from '../../../common/constants/profile';
import { ChangePasswordModal } from '../../../widgets/auth/components/ChangePasswordModal/ChangePasswordModal';
import s from './ProfilePage.module.scss';

const fieldNotFilled = '—';

export const ProfilePage: ReactFCC = () => {
  const { loading, logout } = useAuth();
  const { user } = useUser();

  const { paymentCardLastNumbers, addPaymentMethod, addPaymentLoading, detachPaymentMethod, detachPaymentLoading } =
    usePayment();
  const paymentLoading = addPaymentLoading || detachPaymentLoading;

  const location = useLocation();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const { toggleUserDataModal } = useUi();
  const intl = useIntl();
  const navigate = useNavigate();

  const [phoneVerificationModalOpen, { set: openPhoneVerificationModal, unset: closePhoneVerificationModal }] =
    useToggle(false);
  const [deleteModalOpen, { set: deleteModalSet, unset: deleteModalUnset }] = useToggle(false);
  const [removeProfile, { loading: removeProfileLoading }] = useRemoveProfileMutation();

  const seller = user?.sellerVerification === SellerVerificationEnum.Verified ? user.seller : undefined;

  const inputRef = useRef<HTMLInputElement>(null);

  const [changePasswordModalOpened, { set: openChangePasswordModal, unset: closeChangePasswordModal }] =
    useToggle(false);

  const handleAddPaymentMethod = () => {
    return addPaymentMethod({
      successPath: location.pathname,
      cancelPath: location.pathname
    });
  };

  const onClickRemoveProfile = async () => {
    try {
      if (inputRef.current?.value !== keyWordToDeleteProfile) {
        handleDefaultError(intl.formatMessage({ id: 'general.requiredField' }));
        return;
      }

      const { data, errors } = await removeProfile();
      const removed = !!data?.response;

      if (errors) {
        handleDefaultError('Something went wrong', errors);
        return;
      }

      if (!removed) {
        handleDefaultError(intl.formatMessage({ id: 'profile.deleteError' }));
        return;
      }
      await logout(true);
      navigate(HOME_ROUTE, { replace: true });
    } catch (e) {
      handleDefaultError('Something went wrong', e);
    }
  };

  const editButton = (
    <Anchor component={'button'} className={s.ProfilePage__sideAction} onClick={() => toggleUserDataModal(true)}>
      <BsPencil size={16} />
      <FormattedMessage id="profile.edit" />
    </Anchor>
  );

  const editNumberButton = (
    <Anchor component={'button'} className={s.ProfilePage__sideAction} onClick={() => openPhoneVerificationModal()}>
      {user?.phoneNumber ? (
        <>
          <BsPhone size={16} />
          <FormattedMessage id="profile.edit" />
        </>
      ) : (
        <>
          <BsPhone size={16} />
          Add
        </>
      )}
    </Anchor>
  );

  const deletePaymentButton = (
    <Anchor
      component={'button'}
      variant={AnchorVariant.DANGER}
      className={clsx(s.ProfilePage__sideAction)}
      onClick={paymentLoading ? undefined : detachPaymentMethod}
    >
      {paymentLoading ? (
        <Loader size={LoaderSize.SMALL} />
      ) : (
        <>
          <BsTrash size={16} />
          <FormattedMessage id="payment.deleteMethod" />
        </>
      )}
    </Anchor>
  );

  const addPaymentButton = (
    <PaymentAddButton loading={paymentLoading} onClick={handleAddPaymentMethod} variant={AnchorVariant.SECONDARY} />
  );

  const paymentMethodComp = <PaymentCardNumber cardLastNumbers={paymentCardLastNumbers} />;

  const userName = user && user.name && user.secondName ? `${user.name} ${user.secondName}` : undefined;
  const userNameHiragana =
    seller && seller.nameHiragana && seller.secondNameHiragana
      ? `${seller.nameHiragana} ${seller.secondNameHiragana}`
      : undefined;

  if (loading || !user) {
    return <LoaderBox />;
  }

  return (
    <>
      <Head title={'My profile'} />

      {isMobile && location.pathname === PROFILE_ROUTE && (
        <div className={s.ProfilePage__mobileAvatarContent}>
          <div className={s.ProfilePage__mobileFiller}></div>
          <UserCard user={user} isOwnPage={true} isAvatarUpdated />
        </div>
      )}

      {!isMobile && (
        <div className={s.ProfilePage}>
          <>
            {isDesktop && location.pathname !== PERSONAL_INFORMATION_ROUTE && (
              <>
                <Heading size={HeadingSize.H3}>
                  <FormattedMessage id="profile.personalInformationTitle" />
                </Heading>
                <Space size={ESpaceSize.PIXEL_32} />
                <div className={s.ProfilePage__badge}>
                  <span className={s.ProfilePage__badgeValue}>
                    <FormattedMessage id="profile.personalInformationTitle" />
                  </span>
                </div>

                <div className={s.ProfilePage__noHeading}>{editButton}</div>

                <div className={s.ProfilePage__details}>
                  <UserCard user={user} isOwnPage={true} isAvatarUpdated />
                  <>
                    <Grid className={s.ProfilePage__information}>
                      <Grid.GridItem cols={{ xs: 1, lg: 1 }} className={s.OrderPage__option}>
                        <div className={s.ProfilePage__details_item}>
                          <div className={s.ProfilePage__details_item_extended}>
                            <span className={s.ProfilePage__details_item_title}>
                              <FormattedMessage id={'auth.nameLabel'} />
                            </span>
                            {userNameHiragana ? (
                              <span>
                                {userName} ({userNameHiragana})
                              </span>
                            ) : (
                              <span>{userName}</span>
                            )}
                          </div>
                        </div>
                      </Grid.GridItem>
                      <Grid.GridItem cols={{ xs: 1, lg: 1 }} className={s.OrderPage__option}>
                        <div className={s.ProfilePage__details_item}>
                          <div className={s.ProfilePage__details_item_extended}>
                            <span className={s.ProfilePage__details_item_title}>
                              <FormattedMessage id={'address.form.email'} />
                            </span>
                            <span>{user?.email}</span>
                          </div>
                        </div>
                      </Grid.GridItem>

                      <Grid.GridItem cols={{ xs: 1, lg: 1 }} className={s.OrderPage__option}>
                        <div className={s.ProfilePage__details_item}>
                          <div className={s.ProfilePage__details_item_extended}>
                            <span className={s.ProfilePage__details_item_title}>
                              <FormattedMessage id={'auth.nicknameLabel'} />
                            </span>
                            <span>{user?.nickname}</span>
                          </div>
                        </div>
                      </Grid.GridItem>

                      <Grid.GridItem cols={{ xs: 1, lg: 1 }} className={s.OrderPage__option}>
                        <div className={s.ProfilePage__details_item}>
                          <div className={s.ProfilePage__details_item_extended}>
                            <span className={s.ProfilePage__details_item_title}>
                              <FormattedMessage id={'profile.verificationNumber'} />
                            </span>
                            <span>{user?.phoneNumber}</span>
                          </div>
                          <span className={s.ProfilePage__editButton}> {editNumberButton}</span>
                        </div>
                      </Grid.GridItem>
                    </Grid>
                  </>
                </div>
                {user?.seller?.isTrusted !== undefined && (
                  <>
                    <Space size={ESpaceSize.PIXEL_32} />
                    <TrustPointsNotification trustedSeller={user.seller.isTrusted} />
                  </>
                )}
                <Space size={ESpaceSize.PIXEL_32} />
                <div className={s.ProfilePage__badge}>
                  <span className={s.ProfilePage__badgeValue}>
                    <FormattedMessage id={'address.title'} />
                  </span>
                </div>
                <div className={s.ProfilePage__addressRow}>
                  <AddressLayoutWithForm
                    className={s.ProfilePage__addresses}
                    noHeading={true}
                    classes={{
                      item: s.ProfilePage__address
                    }}
                    addresses={user.addresses}
                    personalInformation={getAddressInformationFromUser(user)}
                  />
                </div>
                <Space size={ESpaceSize.PIXEL_32} />
                <div className={s.ProfilePage__badge}>
                  <span className={s.ProfilePage__badgeValue}>
                    <FormattedMessage id={'footer.linkPaymentMethods'} />
                  </span>
                </div>
                <div className={!paymentCardLastNumbers ? s.ProfilePage__noHeading : s.ProfilePage__heading}>
                  {paymentCardLastNumbers && (
                    <span className={s.ProfilePage__details_item_title}>
                      <FormattedMessage id={'payment.bankCard'} />
                    </span>
                  )}
                  {paymentCardLastNumbers ? deletePaymentButton : addPaymentButton}
                </div>
                <Space size={ESpaceSize.PIXEL_12} />
                <div
                  className={clsx({
                    [s.ProfilePage__paymentRow_hasMethod]: !!paymentCardLastNumbers
                  })}
                >
                  <div className={s.ProfilePage__infoValue}>{paymentCardLastNumbers && paymentMethodComp}</div>
                </div>
                <Space size={ESpaceSize.PIXEL_40} />
                <div className={s.ProfilePage__bottom}>
                  <div className={s.ProfilePage__actions}>
                    <Anchor
                      component={'button'}
                      variant={AnchorVariant.BODY}
                      className={s.ProfileLayout__button}
                      onClick={openChangePasswordModal}
                    >
                      <BsLock />
                      <FormattedMessage id="profile.changePassword" />
                    </Anchor>
                    <Anchor
                      component={'button'}
                      variant={AnchorVariant.DANGER}
                      className={clsx(s.ProfileLayout__button, s.ProfileLayout__button_red)}
                      onClick={deleteModalSet}
                    >
                      <BsTrash />
                      <FormattedMessage id="profile.delete" />
                    </Anchor>
                  </div>
                </div>
              </>
            )}
          </>
        </div>
      )}

      {(isMobile || isDesktop) && location.pathname === PERSONAL_INFORMATION_ROUTE && (
        <>
          <>
            <Space size={ESpaceSize.PIXEL_32} />
            <LinkBack to={PROFILE_ROUTE}>Back to user profile</LinkBack>
            <Space size={ESpaceSize.PIXEL_32} />
            <Heading size={HeadingSize.H3}>
              <FormattedMessage id="profile.personalInformationTitle" />
            </Heading>
          </>
          <div className={s.ProfilePage__info}>
            <div className={s.ProfilePage__heading}>
              <Heading size={isMobile ? HeadingSize.H6 : HeadingSize.H5}>
                <FormattedMessage id="auth.nameLabel" />
              </Heading>
              {editButton}
            </div>
            <div className={s.ProfilePage__row}>
              <span className={s.ProfilePage__infoName}>
                <FormattedMessage id={'profile.personalInfoHiragana'} />
              </span>

              <span className={s.ProfilePage__infoValue}>{userNameHiragana || fieldNotFilled}</span>
            </div>
            <div className={s.ProfilePage__row}>
              <span className={s.ProfilePage__infoName}>
                <FormattedMessage id={'profile.personalInfoKanjiLatin'} />
              </span>
              <span className={s.ProfilePage__infoValue}>{userName || fieldNotFilled}</span>
            </div>
            <div className={s.ProfilePage__row}>
              <span className={s.ProfilePage__infoName}>
                <FormattedMessage id="auth.nicknameLabel" />
              </span>

              <span className={s.ProfilePage__infoValue}>{user.nickname || fieldNotFilled}</span>
            </div>
          </div>
          <div className={s.ProfilePage__info}>
            <div className={s.ProfilePage__heading}>
              <Heading size={isMobile ? HeadingSize.H6 : HeadingSize.H5}>
                <FormattedMessage id="auth.emailLabel" />
              </Heading>
            </div>

            <div className={s.ProfilePage__row}>
              <span className={s.ProfilePage__infoName}>
                <FormattedMessage id="auth.emailLabel" />
              </span>

              <span className={s.ProfilePage__infoValue}>{user.email}</span>
            </div>
          </div>
          <>
            <div className={s.ProfilePage__info}>
              <div className={s.ProfilePage__heading}>
                <Heading size={isMobile ? HeadingSize.H6 : HeadingSize.H5}>
                  <FormattedMessage id={'profile.verificationNumber'} />
                </Heading>
                {editNumberButton}
              </div>

              <div className={s.ProfilePage__row}>
                <span className={s.ProfilePage__infoName}>
                  <FormattedMessage id={'profile.verificationNumber'} />
                </span>

                <span className={s.ProfilePage__infoValue}>{user.phoneNumber || ''}</span>
              </div>
            </div>
          </>
          <div className={s.ProfilePage__info}>
            <div className={s.ProfilePage__addressRow}>
              <AddressLayoutWithForm
                className={s.ProfilePage__addresses}
                classes={{
                  item: s.ProfilePage__address
                }}
                addresses={user.addresses}
                personalInformation={getAddressInformationFromUser(user)}
              />
            </div>
          </div>
          <div className={s.ProfilePage__info}>
            <div className={s.ProfilePage__heading}>
              <Heading size={isMobile ? HeadingSize.H6 : HeadingSize.H5}>
                <FormattedMessage id={'payment.method'} />
              </Heading>
              {paymentCardLastNumbers ? deletePaymentButton : addPaymentButton}
            </div>
            <div
              className={clsx({
                [s.ProfilePage__paymentRow_hasMethod]: !!paymentCardLastNumbers
              })}
            >
              <div className={s.ProfilePage__infoValue}>{paymentCardLastNumbers && paymentMethodComp}</div>
            </div>
          </div>
          <Space size={ESpaceSize.PIXEL_32} />
          <div className={s.ProfilePage__actions}>
            <Anchor
              component={'button'}
              variant={AnchorVariant.BODY}
              className={s.ProfileLayout__button}
              onClick={openChangePasswordModal}
            >
              <BsLock />
              <FormattedMessage id="profile.changePassword" />
            </Anchor>
            <Anchor
              component={'button'}
              variant={AnchorVariant.DANGER}
              className={clsx(s.ProfileLayout__button, s.ProfileLayout__button_red)}
              onClick={deleteModalSet}
            >
              <BsTrash />
              <FormattedMessage id="profile.delete" />
            </Anchor>
          </div>
          <Space size={ESpaceSize.PIXEL_48} />
        </>
      )}
      {/* Модальное окно, открывающееся в случае редактирования номера */}
      <PhoneVerificationModal isOpen={phoneVerificationModalOpen} onClose={closePhoneVerificationModal} />
      <ChangePasswordModal isOpen={changePasswordModalOpened} onClose={closeChangePasswordModal} />
      <Modal title={intl.formatMessage({ id: 'profile.delete' })} isOpen={deleteModalOpen} onClose={deleteModalUnset}>
        <Modal.Body>
          <FormattedMessage id="profile.deleteText" />
          <Space size={ESpaceSize.PIXEL_12} />
          <FormInput
            label={intl.formatMessage({ id: 'profile.deleteTextConfirm' }, { keyword: keyWordToDeleteProfile })}
            className={s.PhoneVerification__input}
            ref={inputRef}
          />
        </Modal.Body>

        <Modal.Footer>
          <Modal.Button onClick={onClickRemoveProfile} loading={removeProfileLoading}>
            <FormattedMessage id="general.delete" />
          </Modal.Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
