import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { CountdownRenderProps } from 'react-countdown/dist/Countdown';
import { Alert, AlertVariant } from '../Alert';
import s from './CountdownRender.module.scss';

export interface TimerProps {
  orderTime: Date;
  children?: React.ReactElement;
}

export const CountdownTemplate = ({ hours, minutes, seconds, completed, props }: CountdownRenderProps) => {
  if (!completed) {
    return (
      <Alert variant={AlertVariant.Warning}>
        <div>{props.children}</div>
        <div className={s.Countdown}>
          {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </div>
      </Alert>
    );
  }
  return;
};

export const CountdownRender = ({ orderTime, children }: TimerProps) => {
  return (
    <Countdown
      date={new Date(orderTime).getTime() + 1000 * 60 * 60 * 3}
      renderer={CountdownTemplate}
      children={children}
    />
  );
};
