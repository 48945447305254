import { useLocation } from 'react-router-dom';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import { ImageWithFallback } from '../../../common/components/ImageWithFallback';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { ABOUT_ROUTE, STATIC_CONTENT_PARAM } from '../../../common/utils/routes';
import { useUrlParam } from '../../../common/hooks/useUrlParam';
import { useArticleQuery } from '../../../store/graphql';
import { LoaderBox } from '../../../common/components/Loader';
import { ArticleRenderer } from '../../../common/components/ArticleRenderer/ArticleRenderer';
import logo from './assets/logo512.png';
import s from './AboutPage.module.scss';

export const AboutPage = () => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const slug = useUrlParam(STATIC_CONTENT_PARAM);

  const { data: articleData, loading: articleLoading } = useArticleQuery({
    skip: !slug,
    variables: {
      input: {
        slug: slug || ''
      }
    }
  });

  const article = articleData?.result?.body;

  if (isMobile && location.pathname === ABOUT_ROUTE) {
    return (
      <>
        <Space size={ESpaceSize.PIXEL_24} />
      </>
    );
  }

  if (articleLoading) {
    return <LoaderBox />;
  }

  return (
    <>
      <div className={s.AboutPage__content}>
        {slug === 'about-company' && <ImageWithFallback src={logo} className={s.AboutPage__logo}></ImageWithFallback>}
        {article && <ArticleRenderer htmlContent={article} />}
      </div>
    </>
  );
};
