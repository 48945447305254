import React, { useMemo } from 'react';
import { compareDesc } from 'date-fns';
import { OrderContent, useCustomerOrdersQuery, useDebtsQuery } from '../../../../store/graphql';
import { DEFAULT_PAGE_LIMIT, Pagination, usePagination } from '../../../../common/components/Pagination';
import { ReactFCC } from '../../../../common/utils/helperTypes';
import { LoaderBox } from '../../../../common/components/Loader';
import { DebtOrderType, OrderDebtVariant } from '../../../../common/constants/debt';
import { OrderCard } from '../../../../entities/order';
import { Head } from '../../../../common/components/Head';
import { OrderDebts } from '../OrderDebts/OrderDebts';
import { PaymentButtonProvider } from '../../../../widgets/paypal';
import s from './CustomerOrders.module.scss';

export const CustomerOrders: ReactFCC = () => {
  const { offset, setOffset } = usePagination();

  const {
    data,
    loading: ordersLoading,
    refetch: refetchOrders
  } = useCustomerOrdersQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        limit: DEFAULT_PAGE_LIMIT,
        offset
      }
    }
  });

  const orders = useMemo(() => data?.orders.entries || [], [data?.orders.entries]);
  const pagination = data?.orders.pagination;

  const orderIds = useMemo(() => {
    if (!orders) return [];
    return orders.map((item) => item.order.id);
  }, [orders]);

  const { data: debtsQueryData, loading: debtsLoading } = useDebtsQuery({
    variables: {
      orderIds
    },
    skip: !orderIds.length
  });
  const debts = debtsQueryData?.debts;

  const paymentDebtOrders: DebtOrderType[] = useMemo(() => {
    if (!orders?.length || !debts?.length) return [];
    const debtedOrdersIds = debts.map((d) => d.order_id);
    return orders
      .filter((item) => debtedOrdersIds.includes(item.order.id))
      .map(({ order }) => ({
        entityId: order.id,
        orderId: order.ID,
        variant: OrderDebtVariant.Payment
      }));
  }, [orders, debts]);

  const loading = ordersLoading || debtsLoading;

  return (
    <>
      <Head title={'My orders'} />

      <div className={s.CustomerOrdersPage}>
        {loading ? (
          <LoaderBox />
        ) : (
          <>
            {!!paymentDebtOrders?.length && (
              <div className={s.CustomerOrdersPage__debts}>
                <OrderDebts debtOrders={paymentDebtOrders} />
              </div>
            )}
            <div className={s.CustomerOrdersPage__orders}>
              <PaymentButtonProvider className={s.CustomerOrdersPage__orders}>
                {orders
                  ?.slice()
                  .sort((a, b) => compareDesc(new Date(a.order.orderDate), new Date(b.order.orderDate)))
                  .map(({ order, contents }, index) => (
                    <OrderCard
                      key={index}
                      id={order.ID}
                      entityId={order.id}
                      date={order.orderDate}
                      status={order.status}
                      isProblem={order.isProblem}
                      contents={contents as OrderContent[]}
                      sellerId={order.sellerId || 0}
                      sellerNickname={order.sellerNickname || ''}
                      deliveryCost={order.deliveryCost || 0}
                      debt={debts?.find((d) => d.order_id === order.id)}
                      isPaidOrder={order.isPaidOrder}
                      paymentType={order.paymentType}
                      refetchOrders={refetchOrders}
                    />
                  ))}
              </PaymentButtonProvider>
            </div>
          </>
        )}

        {pagination && (
          <Pagination
            className={s.CustomerOrdersPage__pagination}
            limit={pagination.limit}
            offset={offset}
            setOffset={setOffset}
            totalCount={pagination.totalCount}
          />
        )}
      </div>
    </>
  );
};
