import React from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { BsBell, BsCamera, BsCart2, BsChat, BsHouse, BsPerson } from 'react-icons/bs';
import { EMDASH } from '@proscom/ui-utils';
import { useUi } from '../../../../store/ui';
import { useCart } from '../../../../store/cart';
import { useNotifications } from '../../../../store/notifications/useNotifications';
import { HeaderNavLang } from '../HeaderNavLang';
import { HeaderNavLink } from '../HeaderNavLink';
import {
  CART_ROUTE,
  CREATE_PRODUCT_ROUTE,
  HOME_ROUTE,
  NOTIFICATIONS_ROUTE,
  PROFILE_ROUTE,
  TICKETS_ROUTE
} from '../../../../common/utils/routes';
import { Loader, LoaderSize, LoaderVariant } from '../../../../common/components/Loader';
import { useUser } from '../../../../entities/user';
import { useAuth } from '../../../../app/providers/auth-apollo';
import { SellerVerificationEnum } from '../../../../store/graphql';
import { useIsDesktop } from '../../../../common/hooks/useIsDesktop';
import { HeaderNavCurrency } from '../HeaderNavCurrency';
import s from './UserPanel.module.scss';

export interface UserPanelProps {
  className?: string;
  home?: boolean;
  lang?: boolean;
  currency?: boolean;
}

export const UserPanel: React.FC<UserPanelProps> = ({ className, home, lang, currency }) => {
  const { isAuthenticated, loading } = useAuth();
  const { user } = useUser();

  const isVerifiedSeller = user?.sellerVerification === SellerVerificationEnum.Verified;

  const isDesktop = useIsDesktop();
  const { toggleAuthModal } = useUi();
  const { cartLength } = useCart();
  const { unreadCount: notificationsCount } = useNotifications();
  const intl = useIntl();

  return (
    <div className={clsx(className, s.UserPanel)}>
      {lang && <HeaderNavLang className={s.UserPanel__lang} />}
      {currency && <HeaderNavCurrency className={s.UserPanel__lang} />}
      {home && <HeaderNavLink className={s.UserPanel__link} to={HOME_ROUTE} icon={BsHouse} end />}
      {isAuthenticated && (
        <HeaderNavLink
          tooltipEnabled
          tooltipText={intl.formatMessage({ id: 'tooltip.notifications' })}
          className={s.UserPanel__link}
          to={NOTIFICATIONS_ROUTE}
          icon={BsBell}
        >
          {!!notificationsCount && <span className={s.UserPanel__badge}>{notificationsCount}</span>}
        </HeaderNavLink>
      )}
      {isDesktop && isAuthenticated && (
        <HeaderNavLink
          tooltipEnabled
          tooltipText={intl.formatMessage({ id: 'tooltip.support' })}
          className={s.UserPanel__link}
          href={TICKETS_ROUTE}
          icon={BsChat}
        />
      )}
      {isVerifiedSeller && (
        <HeaderNavLink
          tooltipEnabled
          tooltipText={intl.formatMessage({ id: 'tooltip.add_product' })}
          className={s.UserPanel__link}
          to={CREATE_PRODUCT_ROUTE}
          icon={BsCamera}
        />
      )}
      <HeaderNavLink
        tooltipEnabled
        tooltipText={intl.formatMessage({ id: 'tooltip.cart' })}
        className={s.UserPanel__link}
        to={CART_ROUTE}
        icon={BsCart2}
      >
        {!!cartLength && <span className={s.UserPanel__badge}>{cartLength}</span>}
      </HeaderNavLink>
      {isAuthenticated ? (
        <HeaderNavLink
          tooltipEnabled
          tooltipText={intl.formatMessage({ id: 'tooltip.open_profile' })}
          className={s.UserPanel__link}
          to={PROFILE_ROUTE}
          icon={BsPerson}
        >
          {loading ? (
            <Loader size={LoaderSize.SMALL} variant={LoaderVariant.light} />
          ) : isDesktop ? (
            <div className={s.UserPanel__link_userName}>{user?.nickname || EMDASH}</div>
          ) : (
            <></>
          )}
        </HeaderNavLink>
      ) : (
        <HeaderNavLink
          tooltipEnabled
          tooltipText={'Login'}
          className={s.UserPanel__link}
          onClick={() => toggleAuthModal(true)}
        >
          <div className={s.UserPanel__link_userName}>{intl.formatMessage({ id: 'auth.signInButton' })}</div>
        </HeaderNavLink>
      )}
    </div>
  );
};
