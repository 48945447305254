import { InferType, object, string } from 'yup';
import { validateErrors } from '../../../../../common/utils/validate';

export const firstMessageMaxLength = 200;

const validateObject = {
  firstMessage: string()
    .min(15)
    .max(firstMessageMaxLength, `Must be maximum ${firstMessageMaxLength} characters long`)
    .defined(validateErrors.required)
};

export const OrderSellerTicketSchema = object(validateObject);

export type TOrderSellerTicketValues = InferType<typeof OrderSellerTicketSchema>;

export const OrderSellerTicketKeys = Object.fromEntries(Object.keys(validateObject).map((key) => [key, key])) as Record<
  keyof TOrderSellerTicketValues,
  string
>;
