import { ReactFCC } from 'common/utils/helperTypes';
import clsx from 'clsx';
import { ArisoraOrderStatus, DebtType, IntentsEnum, OrderContent, OrderPaymentTypeEnum } from 'store/graphql';
import { useNavigate } from 'react-router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedDate } from '../../../../common/components/CustomFormatters';
import { Link } from '../../../../common/components/Link';
import { Anchor } from '../../../../common/components/Anchor';
import { OrderStatusBadge } from '../OrderStatusBadge';
import { Divider } from '../../../../common/components/Divider/Divider';
import { getOrderTotalPrice } from '../../utils';
import { Button, ButtonVariant } from '../../../../common/components/Button';
import { ProductCard } from '../../../product';
import { ImageWithFallback } from '../../../../common/components/ImageWithFallback';
import { PathBuilder } from '../../../../common/utils/routes';
import { useToggle } from '../../../../common/hooks/useToggle';
import { CustomerServiceModal } from '../../../../features/order/customerService/components/CustomerServiceModal';
import { useCurrency } from '../../../../store/currency';
import { OrderContextMenu } from '../../../../features/order/customerService';
import { PaymentButton } from '../../../../widgets/paypal';
import { CountdownRender } from '../../../../common/components/CountdownRender';
import { OrderReconfirmAlert } from '../../../../pages/profile/order/components/OrderReconfirm/OrderReconfirm';
import s from './OrderCard.module.scss';

type OrderCardContentRequiredProps =
  | 'id'
  | 'name'
  | 'productId'
  | 'price'
  | 'colorName'
  | 'status'
  | 'specificValues'
  | 'mainPhoto';
type OrderCardContentType = Pick<OrderContent, OrderCardContentRequiredProps> &
  Partial<Omit<OrderContent, OrderCardContentRequiredProps>>;

export interface OrderCardProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * ID заказа из Airtable
   */
  id: string;
  /**
   * ID сущности заказа
   */
  entityId: number;
  /**
   * Дата создания заказа
   */
  date: Date;
  /**
   * Статус заказа
   */
  status: ArisoraOrderStatus;
  /**
   * Наличие ошибки в заказе
   */
  isProblem?: boolean;
  /**
   * ID продавца
   */
  sellerId?: number;
  /**
   * Имя продавца
   */
  sellerNickname?: string;
  /**
   * ID покупателя
   */
  customerId?: number;
  /**
   * Имя покупателя
   */
  customerNickname?: string;
  /**
   * Содержимое заказа
   */
  contents: OrderCardContentType[];
  /**
   * Детализированное отображение товаров
   * todo возможно лучше детализированное отображение вынести в отдельный компонент
   *  ибо становится очень много логики, завязанной на этом флаге
   */
  isDetailed?: boolean;
  /**
   * Отображение заказа в списке продавца (влияет на генерацию ссылки на заказ)
   */
  isSeller?: boolean;
  /**
   * Цена доставки
   */
  deliveryCost?: number;
  /**
   * Объект задолженности
   */
  debt?: DebtType;
  /**
   * Опциональный левый блок в хедере
   */
  headerLeft?: React.ReactNode;
  /**
   * Опциональный флаг для того, чтобы сделать карточку компактной
   */
  compact?: boolean;
  /**
   * Опциональный флаг оплаты заказа
   */
  isPaidOrder?: boolean;
  /**
   * Тип платёжной системы заказа
   */
  paymentType?: OrderPaymentTypeEnum;
  refetchOrders?: () => void;
}

export const OrderCard: ReactFCC<OrderCardProps> = (props) => {
  const {
    className,
    id,
    entityId,
    date,
    status,
    isProblem,
    sellerId,
    sellerNickname,
    customerId,
    customerNickname,
    contents,
    isDetailed,
    isSeller,
    deliveryCost,
    headerLeft,
    compact,
    isPaidOrder,
    paymentType,
    refetchOrders
  } = props;

  const navigate = useNavigate();

  const totalPrice = getOrderTotalPrice(contents, deliveryCost);

  const { convertCurrency, currency } = useCurrency();

  const productImageSize = parseFloat(s.productImageSize);
  const productImageGap = parseFloat(s.productImageGap);

  const contentRef = useRef<HTMLDivElement>(null);
  const [maxItems, setMaxItems] = useState(0);

  const [customerServiceModalOpen, { set: openCustomerServiceModal, unset: closeCustomerServiceModal }] =
    useToggle(false);

  const processMaxItems = useCallback(() => {
    if (contentRef.current) {
      const width = contentRef.current.clientWidth - 100;
      let newMaxItems = 0;
      const p = new Array(20).fill(null);
      for (let i of p) {
        const itemsWidth = (newMaxItems + 1) * productImageSize + newMaxItems * productImageGap;
        if (itemsWidth < width) {
          newMaxItems += 1;
        } else {
          newMaxItems -= 1;
          break;
        }
      }
      setMaxItems(newMaxItems);
    }
  }, [productImageGap, productImageSize]);

  useEffect(processMaxItems, [processMaxItems]);
  useEffect(() => {
    window.addEventListener('resize', processMaxItems);

    return () => window.removeEventListener('resize', processMaxItems);
  }, [processMaxItems]);

  return (
    <div className={clsx(s.OrderCard, isDetailed && s.Order_noborder, className)}>
      <div className={s.OrderCard__header}>
        {headerLeft && <div className={s.OrderCard__headerLeft}>{headerLeft}</div>}
        {!compact && (
          <div className={s.OrderCard__headerStatus}>
            <OrderStatusBadge status={status} isProblem={isProblem} />

            {/* TODO: включить, когда на бэке появится дата доставки */}
            {/*<div className={s.OrderCard__headerStatusDate}>*/}
            {/*  <BsBox />*/}
            {/*  <span>*/}
            {/*    Will be delivered on <b>{getDeliveryDate(deliverySpeedDays)}</b>*/}
            {/*  </span>*/}
            {/*</div>*/}
          </div>
        )}
        {!compact && <OrderContextMenu onClick={() => openCustomerServiceModal()} />}
      </div>

      {contents && (
        <div className={s.OrderCard__body}>
          {isDetailed ? (
            <>
              {contents.map(
                (product, index) =>
                  product && (
                    <React.Fragment key={index}>
                      {index !== 0 && <Divider />}
                      {/* todo вынести детализацию в отдельный компонент, т.к. не можем быть зависимостей у сущностей на одном уровне */}
                      <ProductCard product={product} deliveryCost={deliveryCost} />
                    </React.Fragment>
                  )
              )}
            </>
          ) : (
            <div className={s.OrderCard__productThumbnails} ref={contentRef}>
              <>
                <div className={s.OrderCard__orderContent_info}>
                  <div className={s.OrderCard__headerInfoTitle}>
                    <span>
                      <FormattedMessage id="general.order" /> {id}
                    </span>
                  </div>

                  {sellerNickname && (
                    <div className={s.OrderCard__headerInfoSeller}>
                      <FormattedMessage id="general.seller" />{' '}
                      {sellerId ? (
                        <Link to={PathBuilder.getSellerPublicPath(sellerId)}>{sellerNickname}</Link>
                      ) : (
                        <Anchor component="span">{sellerNickname}</Anchor>
                      )}
                    </div>
                  )}

                  {customerNickname && (
                    <div className={s.OrderCard__headerInfoSeller}>
                      <FormattedMessage id="general.buyer" />{' '}
                      {customerId ? (
                        <Link to={PathBuilder.getCustomerPublicPath(customerId)}>{customerNickname}</Link>
                      ) : (
                        <Anchor component="span">{customerNickname}</Anchor>
                      )}
                    </div>
                  )}
                  <span className={s.OrderCard__headerInfoDate}>
                    {date && <FormattedDate value={new Date(date)} />}
                  </span>
                </div>
                {contents.slice(0, maxItems).map((product, index) => (
                  <div className={s.OrderCard__orderContent}>
                    <Link to={PathBuilder.getProductPath(product.productId)} key={index}>
                      <ImageWithFallback src={product.mainPhoto?.thumbnails?.M?.url ?? undefined} size={85} />
                    </Link>
                  </div>
                ))}
                {maxItems < contents.length && (
                  <div
                    className={s.OrderCard__productThumbnailMore}
                    onClick={() => navigate(PathBuilder.getOrderPath(entityId, isSeller))}
                  >
                    +{contents.length - maxItems}
                  </div>
                )}
              </>
            </div>
          )}
          {!isPaidOrder && !isSeller && (
            <div className={s.OrderCard__buttonBlock}>
              <CountdownRender orderTime={date}>
                {/*TODO translations*/}
                {isSeller ? (
                  <div>{`Customer should pay within 3 hours`}</div>
                ) : (
                  <div>{`Please, complete payment within 3 hours or the order will be cancelled`}</div>
                )}
              </CountdownRender>

              {paymentType === OrderPaymentTypeEnum.Paypal ? (
                <div className={s.OrderCard__buttonBlock_buttons}>
                  <PaymentButton
                    orderPrice={totalPrice}
                    order_id={entityId}
                    currency={currency}
                    intent={IntentsEnum.Authorize}
                  />
                </div>
              ) : (
                <OrderReconfirmAlert
                  orderId={entityId}
                  orderID={id}
                  totalCost={convertCurrency(totalPrice)}
                  refetchOrders={refetchOrders}
                />
              )}
            </div>
          )}
        </div>
      )}

      {!compact && (
        <div className={s.OrderCard__footer}>
          <div className={s.OrderCard__footerTotal}>
            <span className={s.OrderCard__footerTotalValue}>{convertCurrency(totalPrice)}</span>

            <span className={s.OrderCard__footerTotalText}>
              <FormattedMessage id="profile.orderTotalCost" />
            </span>
          </div>
          {!isDetailed && (
            <Button
              variant={ButtonVariant.PRIMARY_OUTLINE}
              onClick={() => navigate(PathBuilder.getOrderPath(entityId, isSeller))}
            >
              <FormattedMessage id="profile.viewOrder" />
            </Button>
          )}
        </div>
      )}
      <CustomerServiceModal
        isOpen={customerServiceModalOpen}
        onClose={() => closeCustomerServiceModal()}
        orderID={id}
      />
    </div>
  );
};
