import { useCallback, useMemo, useState } from 'react';
import {
  FileActionsEnum,
  GetAttachmentsResult,
  LocalAttachmentType,
  SortEnum,
  useCreateUploadUrl_V2Mutation,
  usePrivateAttachmentsQuery
} from '../../../store/graphql';
import { FileAction, useMFUpload } from '../../msfiles-client';
import { extname } from '../utils/extname';
import { DEFAULT_UPLOAD_MAX_SIZE, DEFAULT_UPLOAD_MAX_FILES } from '../constants';
import { UploadStage } from './types';
import { extensionActionMap, RemovableAttachmentType, UseUploadMutationProps, UseUploadProps } from './useUpload';

type UsePrivateUploadProps = {
  bucket: string;
} & UseUploadProps;

export type UsePrivateUploadReturn = [
  mutation: (props: UseUploadMutationProps) => Promise<LocalAttachmentType | null>,
  options: {
    data?: UsePrivateUploadData;
    reset: () => void;
    refetch: () => Promise<void>;
    remove: (attachment: RemovableAttachmentType) => void;
    loading: boolean;
    stage: UploadStage;
    previousData: UsePrivateUploadData;
  }
];

const defaultPagination = {
  limit: 10,
  offset: 0
};

const defaultSort = {
  created_at: SortEnum.Asc
};

export interface UsePrivateUploadData {
  privateAttachments: GetAttachmentsResult;
}

export const usePrivateUpload = (props: UsePrivateUploadProps): UsePrivateUploadReturn => {
  const {
    multiple,
    initialUids = [],
    pagination = defaultPagination,
    sort = defaultSort,
    mutationOptions = {},
    maxSize = DEFAULT_UPLOAD_MAX_SIZE,
    params = {},
    allowedActions = [FileAction.UploadFile, FileAction.UploadImage, FileAction.UploadVideo],
    maxFiles = DEFAULT_UPLOAD_MAX_FILES,
    bucket
  } = props;

  const [currentUid, setCurrentUid] = useState<string[]>(initialUids);

  const {
    data,
    previousData,
    loading: fetchLoading,
    error,
    refetch: getPrivateAttachments
  } = usePrivateAttachmentsQuery({
    fetchPolicy: 'network-only',
    skip: currentUid.length === 0,
    variables: {
      pagination,
      sort,
      filter: {
        msfilesUidList: currentUid
      }
    }
  });

  const [createUploadUrl, { loading: createUploadUrlLoading }] = useCreateUploadUrl_V2Mutation();
  const { mutate: uploadImage, isMutating } = useMFUpload(mutationOptions);

  const upload = useCallback(
    async (props: UseUploadMutationProps) => {
      if (props.file.size > maxSize) {
        throw new Error('Maximum file size exceeded');
      }

      const ext = extname(props.file.name);

      if (!ext || !(ext in extensionActionMap)) {
        throw new Error('Invalid file extension');
      }

      const action = extensionActionMap[ext];

      if (!allowedActions.includes(action)) {
        throw new Error('This type of file is not allowed');
      }

      if (
        maxFiles > 0 &&
        (currentUid.length === maxFiles || (props.amount && currentUid.length + props.amount > maxFiles))
      ) {
        throw new Error(`Maximum files amount of ${maxFiles} exceeded`);
      }

      const createUploadUrlData = await createUploadUrl({
        variables: {
          input: {
            action: action as unknown as FileActionsEnum,
            bucket: bucket
          }
        }
      });
      const key = createUploadUrlData.data?.createUploadUrlV2.code;

      if (!key) {
        throw new Error('There is no upload key');
      }

      const response = await uploadImage({
        key,
        action,
        ...params[action],
        ...props
      });

      if (!response) {
        return null;
      }

      const nextUid = multiple && currentUid.length ? [...currentUid, response.uid] : [response.uid];

      setCurrentUid((currentUidData) => [...currentUidData, response.uid]);

      const { data } = await getPrivateAttachments({
        pagination,
        sort,
        filter: {
          msfilesUidList: nextUid
        }
      });
      const attachment = data?.privateAttachments.entries[0];

      if (!attachment) {
        return null;
      }

      return attachment as LocalAttachmentType;
    },
    [
      allowedActions,
      createUploadUrl,
      currentUid,
      getPrivateAttachments,
      maxSize,
      multiple,
      pagination,
      params,
      sort,
      uploadImage,
      maxFiles
    ]
  );

  const reset = useCallback(() => {
    setCurrentUid([]);
  }, []);

  const refetch = useCallback(async () => {
    await getPrivateAttachments();
  }, [getPrivateAttachments]);

  const remove = useCallback((attachment: RemovableAttachmentType) => {
    setCurrentUid((uid) => {
      return uid.filter((i) => i !== attachment.msfiles_uid);
    });
  }, []);

  const stage = useMemo(() => {
    if (fetchLoading) {
      return UploadStage.fetching;
    } else if (createUploadUrlLoading) {
      return UploadStage.preparing;
    } else if (isMutating) {
      return UploadStage.mutating;
    } else {
      return UploadStage.idle;
    }
  }, [createUploadUrlLoading, fetchLoading, isMutating]);

  return [
    upload,
    {
      data: (fetchLoading || error ? previousData : data) as UsePrivateUploadData,
      loading: fetchLoading || createUploadUrlLoading || isMutating,
      stage,
      reset,
      refetch,
      remove,
      previousData: previousData as UsePrivateUploadData
    }
  ];
};
