import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import clsx from 'clsx';

import { Form } from 'react-bootstrap';
import { FormControlProps } from 'react-bootstrap/FormControl';
import { useLiveInput } from '@proscom/ui-react';
import { InputLayout, InputLayoutProps, InputLayoutClasses } from '../InputLayout/InputLayout';
import s from './FormInput.module.scss';

export interface FormInputClasses extends InputLayoutClasses {
  input?: string;
}

export interface FormInputSelfProps extends InputLayoutProps {
  className?: string;
  classes?: FormInputClasses;
  unitRight?: string;
  unitLeft?: string;
  contentBefore?: React.ReactNode;
  contentAfter?: React.ReactNode;
  inputDelayMs?: number;
  precision?: number;
  onBlurCustom?: React.FocusEventHandler<HTMLInputElement>;
}

export type FormInputProps = React.InputHTMLAttributes<HTMLInputElement> & FormControlProps & FormInputSelfProps;

const FormInputCmp: React.ForwardRefRenderFunction<HTMLInputElement, FormInputProps> = (props, ref) => {
  const {
    children,
    className,
    classes,
    label,
    required,
    error,
    help,
    unitRight,
    unitLeft,
    space,
    value,
    onChange,
    disabled,
    contentBefore,
    contentAfter,
    innerRef,
    type,
    inputDelayMs,
    precision,
    loading,
    success,
    onBlurCustom,
    ...rest
  } = props;

  const [inputValue, setInputValue] = useState(value);
  const [_, submit] = useLiveInput<ChangeEvent<HTMLInputElement>>((e) => onChange?.(e), inputDelayMs || 0);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (type === 'number') {
        e.target.value = e.target.value.normalize('NFKC');
        let value =
          e.target.value
            ?.toString()
            .replace(/[^0-9.]/g, '')
            .replace(/\.{2,}/g, '.') || '';

        if (precision !== undefined && value && new RegExp(`^\\d+\\.\\d{${precision},}$`, 'g').test(value)) {
          value = parseFloat(value).toFixed(precision);
        }

        e.target.value = value;
      }

      setInputValue(e.target.value);
      submit(e, !!inputDelayMs);
    },
    [type, submit, inputDelayMs, precision]
  );

  // todo подумать, как можно перенести это в валидацию onChange
  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (type === 'number') {
        e.target.value = e.target.value.normalize('NFKC');
        e.target.value = e.target.value.match(/^\d+(\.\d+)?/)?.[0] || '';
      }
      setInputValue(e.target.value);
      submit(e, false);
      onBlurCustom?.(e);
    },
    [submit, type, onBlurCustom]
  );

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <InputLayout
      className={clsx(s.FormInput, className, classes?.root)}
      classes={classes}
      label={label}
      required={required}
      error={error}
      space={space}
      success={success}
      help={help}
      disabled={disabled}
      innerRef={innerRef}
      loading={loading}
    >
      {contentBefore}

      <div className={clsx('input-group', s.FormInput__inputGroup)}>
        {unitLeft && (
          <span
            className={clsx(s.FormInput__inputText, s.FormInput__inputText_left, 'input-group-text', {
              [s.FormInput__inputText_error]: !!error,
              [s.FormInput__inputText_disabled]: disabled
            })}
          >
            {unitLeft}
          </span>
        )}

        <Form.Control
          type={type !== 'number' ? type : 'text'}
          className={clsx(classes?.input, s.FormInput__input, {
            [s.FormInput__input_withUnitRight]: unitRight,
            [s.FormInput__input_withUnitLeft]: unitLeft,
            [s.FormInput__input_disabled]: disabled
          })}
          isInvalid={!!error}
          value={inputValue}
          onBlur={onBlur}
          onInput={handleChange}
          ref={ref}
          disabled={disabled}
          {...rest}
        ></Form.Control>

        {unitRight && (
          <span
            className={clsx(s.FormInput__inputText, s.FormInput__inputText_right, 'input-group-text', {
              [s.FormInput__inputText_error]: !!error,
              [s.FormInput__inputText_disabled]: disabled
            })}
          >
            {unitRight}
          </span>
        )}
      </div>

      {contentAfter}
    </InputLayout>
  );
};

export const FormInput = React.forwardRef(FormInputCmp);
