import { ReactFCC } from 'common/utils/helperTypes';
import { OrderPaymentTypeEnum } from 'store/graphql';
import cardsLogo from '../assets/cards.png';
import { ReactComponent as PaypalLogo } from '../assets/paypal.svg';

export type dynamicLogoProps = {
  ps: OrderPaymentTypeEnum;
};
export const logoMap = {
  Stripe: () => <img src={cardsLogo} height={40} alt={'credit-cards'} />,
  Paypal: () => <PaypalLogo />
};
export const DynamicLogo: ReactFCC<dynamicLogoProps> = ({ ps }) => {
  const SelectedComponent = logoMap[ps] || null;
  return SelectedComponent ? <SelectedComponent /> : <></>;
};
