import { array, InferType, number, object, string } from 'yup';
import { validationMessages, Validator } from 'common/utils/validate';
import { IntlShape } from 'react-intl';
import { DEFAULT_VERIFICATION_MAX_FILES } from '../../../../../lib/file-uploader/constants';

export const nameMaxLength = 30; // todo уточнить

export const isFullWidthKatakana = (value: string | undefined) => {
  return value ? /^[\u30A0-\u30FF]+$/g.test(value) : false;
};

export function isJapaneseInput(input: string): boolean {
  if (!input) {
    return false;
  }
  return /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/.test(input);
}

export function isLatinJapaneseAndWhitespace(input: string | undefined): boolean {
  return input
    ? /^[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbfA-Za-z\s]*$/.test(input) &&
        /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbfA-Za-z]/.test(input)
    : false;
}

export const katakanaValidation = {
  name: 'is-full-width',
  error: '全角カタカナを入力してください',
  validator: isFullWidthKatakana
};

export const japaneseOrLatinValidation = {
  name: 'is-japanese-or-latin',
  error: '日本語またはラテン文字のみ使用可能',
  validator: isLatinJapaneseAndWhitespace
};

const createValidationObject = (intl: IntlShape) => {
  const getMessage = (id: keyof typeof validationMessages, values?: Record<string, any>) =>
    intl.formatMessage(validationMessages[id], values);

  return {
    gender: string().required(getMessage('required')),
    name: string()
      .max(nameMaxLength, getMessage('maxValue', { value: `${nameMaxLength}` }))
      .test(japaneseOrLatinValidation.name, getMessage('japaneseLatin'), japaneseOrLatinValidation.validator)
      .required(getMessage('required')),
    secondName: string()
      .max(nameMaxLength, getMessage('maxValue', { value: `${nameMaxLength}` }))
      .test(japaneseOrLatinValidation.name, getMessage('japaneseLatin'), japaneseOrLatinValidation.validator)
      .required(getMessage('required')),
    nameHiragana: string()
      .max(nameMaxLength, getMessage('maxValue', { value: `${nameMaxLength}` }))
      .test(katakanaValidation.name, getMessage('katakana'), katakanaValidation.validator)
      .required(getMessage('required')),
    secondNameHiragana: string()
      .max(nameMaxLength, getMessage('maxValue', { value: `${nameMaxLength}` }))
      .test(katakanaValidation.name, getMessage('katakana'), katakanaValidation.validator)
      .required(getMessage('required')),
    birthdate: string().required(getMessage('required')),
    contactNumber: string()
      .min(7, getMessage('wrongPhone'))
      .max(20, getMessage('wrongPhone'))
      .required(getMessage('required')),
    addressId: number().required(getMessage('required')),
    attachments: array()
      .length(DEFAULT_VERIFICATION_MAX_FILES, getMessage('minFiles', { value: 2 }))
      .required(Validator.errors.required),
    smsCode: string()
      .max(6, getMessage('maxValue', { value: 6 }))
      .nullable(true)
  };
};

export const VerificationSchema = (intl: IntlShape) => object(createValidationObject(intl));

export type VerificationValues = InferType<ReturnType<typeof VerificationSchema>>;

export const VerificationSchemaKeys = Object.keys(
  createValidationObject({ formatMessage: (msg: any) => msg.defaultMessage } as IntlShape)
).reduce(
  (acc, key) => ({
    ...acc,
    [key]: key
  }),
  {}
) as Record<keyof ReturnType<typeof createValidationObject>, string>;
