import { useLocation } from 'react-router-dom';
import { ESpaceSize, Space } from '../../../common/components/Space/Space';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { MANUALS_ROUTE, STATIC_CONTENT_PARAM } from '../../../common/utils/routes';
import { useUrlParam } from '../../../common/hooks/useUrlParam';
import { useArticleQuery } from '../../../store/graphql';
import { LoaderBox } from '../../../common/components/Loader';
import { ArticleRenderer } from '../../../common/components/ArticleRenderer/ArticleRenderer';
import s from './ManualPage.module.scss';

export const ManualPage = () => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const slug = useUrlParam(STATIC_CONTENT_PARAM);

  const { data: articleData, loading: articleLoading } = useArticleQuery({
    skip: !slug,
    variables: {
      input: {
        slug: slug || ''
      }
    }
  });

  const article = articleData?.result?.body;

  if (isMobile && location.pathname === MANUALS_ROUTE) {
    return (
      <>
        <Space size={ESpaceSize.PIXEL_24} />
      </>
    );
  }

  if (articleLoading) {
    return <LoaderBox />;
  }

  return (
    <>
      <div className={s.ManualPage__content}>{article && <ArticleRenderer htmlContent={article} />}</div>
    </>
  );
};
