import { ApolloClient, InMemoryCache } from '@apollo/client';
import { BrandSearchInput, PaginationInput } from '../../../../../store/graphql';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getDefaultDeliveryPrice: { keyArgs: [] },
        brands: {
          keyArgs: [],
          merge(existing, incoming, { args }) {
            const input: BrandSearchInput = args?.input;

            const offset = input.pagination?.offset || 0;

            const merged = existing ? existing.entries.slice(0) : [];

            for (let i = 0; i < incoming.entries.length; ++i) {
              merged[offset + i] = incoming.entries[i];
            }

            return {
              entries: merged,
              pagination: incoming.pagination,
              totalUnfilteredBrands: incoming.totalUnfilteredBrands
            };
          }
        }
      }
    },
    Product: {
      keyFields: ['id'],
      fields: {
        comments: {
          keyArgs: [],
          merge(existing, incoming, { args }) {
            const pagination: PaginationInput = args?.input;
            const offset = pagination?.offset || 0;

            const merged = existing ? existing.entries.slice(0) : [];

            for (let i = 0; i < incoming.entries.length; ++i) {
              merged[offset + i] = incoming.entries[i];
            }

            return {
              entries: merged,
              pagination: incoming.pagination
            };
          }
        }
      }
    }
  }
});

export const apolloClient = new ApolloClient({
  cache
});
