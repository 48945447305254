import { IconType } from 'react-icons';
import {
  BsBugFill,
  BsCardChecklist,
  BsCartFill,
  BsCreditCardFill,
  BsFlagFill,
  BsLightbulbFill,
  BsPersonBadge,
  BsPersonFill,
  BsThreeDots,
  BsTruck
} from 'react-icons/bs';
import { ElementType } from 'react';
import clsx from 'clsx';
import { TicketStatusEnum, TicketTopicEnum } from '../../../../../../store/graphql';
import { Badge, BadgeVariant } from '../../../../../../common/components/Badge';
import { Link } from '../../../../../../common/components/Link';
import { PathBuilder } from '../../../../../../common/utils/routes';
import { AnchorVariant } from '../../../../../../common/components/Anchor';
import { useIsMobile } from '../../../../../../common/hooks/useIsMobile';
import { formatDateDependent } from '../../../../../../common/utils/date';
import { TicketMessagesCount } from '../../../../../../widgets/tickets/components/TicketMessagesCount/TicketMessagesCount';
import s from './Ticket.module.scss';

type TicketProps = {
  id: number;
  name: string;
  topic: TicketTopicEnum;
  status: TicketStatusEnum;
  unreadCount: number;
  updatedAt: string;
};
export const Ticket = (props: TicketProps) => {
  const { id, name, topic, status, unreadCount, updatedAt } = props;
  const isMobile = useIsMobile();
  const topicIcons: Record<TicketTopicEnum, IconType> = {
    [TicketTopicEnum.Account]: BsPersonFill,
    [TicketTopicEnum.Order]: BsCardChecklist,
    [TicketTopicEnum.Report]: BsFlagFill,
    [TicketTopicEnum.Other]: BsThreeDots,
    [TicketTopicEnum.Payment]: BsCreditCardFill,
    [TicketTopicEnum.Product]: BsCartFill,
    [TicketTopicEnum.Shipment]: BsTruck,
    [TicketTopicEnum.Technical]: BsBugFill,
    [TicketTopicEnum.Suggestion]: BsLightbulbFill,
    [TicketTopicEnum.BuyerSeller]: BsPersonBadge
  };

  const IconComponent = topicIcons[topic] as ElementType;
  const ticketClosed = status === TicketStatusEnum.Closed;

  return (
    <Link className={s.Ticket} to={PathBuilder.getTicketRequestPath(id)} variant={AnchorVariant.BODY}>
      <div className={s.Ticket__latest}>
        <Badge className={s.Ticket__badge} variant={BadgeVariant.gray}>
          <IconComponent
            className={clsx(
              s.Ticket__icon,
              s[`Ticket__icon_${topic.toLowerCase()}`],
              ticketClosed && s.Ticket__icon_incative
            )}
            size={22}
          />
          <TicketMessagesCount className={s.Ticket__unreadCount} count={unreadCount} />
        </Badge>
        <span className={s.Ticket__name}>{name}</span>
      </div>
      <span className={s.Ticket__date}>{formatDateDependent(new Date(updatedAt), isMobile)}</span>
    </Link>
  );
};
