import React from 'react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { toLower } from 'lodash-es';
import clsx from 'clsx';
import { LoaderBox } from '../../../../common/components/Loader';
import { IntentsEnum, useGetPaypalOptionsQuery } from '../../../../store/graphql';
import { PaymentButton } from '../PaymentButton';
import s from './PaymentButtonProvider.module.scss';

export interface PaymentButtonProviderProps {
  orderPrice?: number;
  currency?: string;
  intent?: IntentsEnum;
  order_id?: number;
  debtId?: number;
  productIds?: number[];
  deliveryPrice?: number;
  buyNow?: boolean;
  shippingAddressId?: number;
  billingAddressId?: number;
  children?: React.ReactNode;
  onSuccess?: () => void;
  className?: string;
}

export const PaymentButtonProvider = ({
  orderPrice,
  currency,
  intent,
  order_id,
  debtId,
  productIds,
  deliveryPrice,
  buyNow,
  shippingAddressId,
  billingAddressId,
  children,
  onSuccess,
  className
}: PaymentButtonProviderProps) => {
  const { data: paypalOptions, loading } = useGetPaypalOptionsQuery();
  const currencyResult = currency || paypalOptions?.getPaypalOptions.currency || 'JPY';
  const intentResult = intent || (paypalOptions?.getPaypalOptions.intent as IntentsEnum);

  return (
    <div className={clsx(s.PaymentButtonProvider, className)}>
      {loading ? (
        <LoaderBox />
      ) : (
        <PayPalScriptProvider
          options={{
            clientId: paypalOptions?.getPaypalOptions.client_id || '',
            intent: toLower(intent || paypalOptions?.getPaypalOptions.intent),
            currency: currency || paypalOptions?.getPaypalOptions.currency
          }}
        >
          {children || (
            <PaymentButton
              orderPrice={orderPrice || 0}
              order_id={order_id}
              debtId={debtId}
              productIds={productIds}
              deliveryPrice={deliveryPrice}
              buyNow={buyNow}
              shippingAddressId={shippingAddressId}
              billingAddressId={billingAddressId}
              intent={intentResult}
              currency={currencyResult}
              onSuccess={onSuccess}
            />
          )}
        </PayPalScriptProvider>
      )}
    </div>
  );
};
