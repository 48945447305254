import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { ArisoraOrderStatus, OrderContent, useSellerOrdersQuery } from '../../../../store/graphql';
import { ReactFCC } from '../../../../common/utils/helperTypes';
import { DEFAULT_PAGE_LIMIT, Pagination, usePagination } from '../../../../common/components/Pagination';
import { OrderDebtVariant } from '../../../../common/constants/debt';
import { ESpaceSize, Space } from '../../../../common/components/Space/Space';
import { LoaderBox } from '../../../../common/components/Loader';
import { OrderDebts } from '../OrderDebts/OrderDebts';
import { OrderCard } from '../../../../entities/order';
import s from './SellerOrders.module.scss';

export const SellerOrders: ReactFCC = () => {
  const { offset, setOffset } = usePagination();

  const { data, loading } = useSellerOrdersQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        pagination: {
          limit: DEFAULT_PAGE_LIMIT,
          offset
        }
      }
    }
  });

  const orders = useMemo(() => data?.orders.seller?.orders?.entries || [], [data?.orders.seller?.orders?.entries]);
  const pagination = data?.orders.seller?.orders?.pagination;

  const actionDebtOrders = useMemo(() => {
    if (!orders.length) {
      return [];
    }

    return orders
      ?.filter((order) => order.order.status === ArisoraOrderStatus.Preparing)
      .map(({ order }) => ({
        entityId: order.id,
        orderId: order.ID,
        variant: OrderDebtVariant.Action
      }));
  }, [orders]);

  return (
    <div className={s.SellerOrders}>
      <Space size={ESpaceSize.PIXEL_16} />

      {loading ? (
        <LoaderBox />
      ) : (
        <>
          {!!actionDebtOrders?.length && (
            <>
              <div className={s.SellerOrders__hint}>
                <FormattedMessage id="profile.showcaseSellersOrdersAwaiting" />:{' '}
                <span className={s.SellerOrders__hintValue}>{actionDebtOrders.length}</span>
              </div>
              <div className={s.SellerOrders__debts}>
                <OrderDebts debtOrders={actionDebtOrders} isSeller />
              </div>
            </>
          )}

          <div className={s.SellerOrders__content}>
            {orders?.map(({ order, contents }, index) => (
              <OrderCard
                key={index}
                id={order.ID}
                entityId={order.id}
                date={order.orderDate}
                status={order.status}
                isProblem={order.isProblem}
                contents={contents as OrderContent[]}
                customerId={order.customerId || 0}
                customerNickname={order.customerNickname || ''}
                isPaidOrder={order.isPaidOrder}
                isSeller
              />
            ))}
          </div>
        </>
      )}

      <Space size={ESpaceSize.PIXEL_40} />

      {pagination && (
        <Pagination limit={pagination.limit} offset={offset} setOffset={setOffset} totalCount={pagination.totalCount} />
      )}
    </div>
  );
};
