import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Heading, HeadingSize } from 'common/components/Heading/Heading';
import { Button, ButtonFit, ButtonVariant } from 'common/components/Button/Button/Button';
import { Alert } from 'common/components/Alert/Alert';
import { usePayment } from 'store/payment/usePayment';
import { PaymentAddButton } from 'common/components/payment/PaymentAddButton/PaymentAddButton';
import { Modal } from 'common/components/Modal';
import { useToggle } from 'common/hooks/useToggle';
import { CustomerServiceModal } from '../../../../../features/order/customerService/components/CustomerServiceModal';
import { useIsMobile } from '../../../../../common/hooks/useIsMobile';
import { ESpaceSize, Space } from '../../../../../common/components/Space/Space';
import { useHandlePaidOrderMutation } from '../../../../../store/graphql';
import s from './OrderReconfirm.module.scss';

export interface OrderReconfirmProps {
  orderId: number;
  orderID: string;
  totalCost: string;
  customerService?: boolean;
  refetchOrders?: () => void;
}

export const OrderReconfirmAlert = ({
  orderId,
  orderID,
  totalCost,
  customerService,
  refetchOrders
}: OrderReconfirmProps) => {
  const location = useLocation();
  const intl = useIntl();
  const isMobile = useIsMobile();

  const [modalOpen, { set: openModal, unset: closeModal }] = useToggle();
  const [handlePaidOrderMutation, { loading: handlePaidOrderLoading }] = useHandlePaidOrderMutation();

  const [customerServiceModalOpen, { set: openCustomerServiceModal, unset: closeCustomerServiceModal }] =
    useToggle(false);

  const { hasPaymentMethod, addPaymentMethod, addPaymentLoading, reconfirmLoading, reconfirm } = usePayment();

  const handleAddPaymentMethod = () => {
    return addPaymentMethod({
      successPath: location.pathname,
      cancelPath: location.pathname
    });
  };

  const handlePaidOrder = async () => {
    try {
      const result = await handlePaidOrderMutation({
        variables: {
          orderId: orderId
        }
      });
      if (result?.data?.result) {
        refetchOrders?.();
      }
    } catch (e) {}
  };

  useEffect(() => {
    handlePaidOrder();
  }, []);

  return (
    <Alert className={s.OrderReconfirm}>
      <div className={s.OrderReconfirm__title}>
        <Heading size={HeadingSize.H6}>
          <FormattedMessage id={'order.reconfirmTitle'} />
        </Heading>
      </div>
      <Space size={ESpaceSize.PIXEL_8} />
      <div className={s.OrderReconfirm__actions}>
        {hasPaymentMethod ? (
          <Button
            variant={ButtonVariant.PRIMARY}
            fit={isMobile ? ButtonFit.FILL : ButtonFit.FIT}
            loading={handlePaidOrderLoading}
            onClick={openModal}
          >
            <FormattedMessage id={'order.reconfirm'} />
          </Button>
        ) : (
          <PaymentAddButton onClick={handleAddPaymentMethod} loading={addPaymentLoading || handlePaidOrderLoading} />
        )}
        {customerService && (
          <Button
            variant={ButtonVariant.SECONDARY}
            fit={isMobile ? ButtonFit.FILL : ButtonFit.FIT}
            onClick={openCustomerServiceModal}
          >
            <FormattedMessage id={'customerService.title'} />
          </Button>
        )}
      </div>

      <CustomerServiceModal
        isOpen={customerServiceModalOpen}
        onClose={() => closeCustomerServiceModal()}
        orderID={orderID}
      />

      <Modal title={intl.formatMessage({ id: 'order.alertPaymentDebtTitle' })} isOpen={modalOpen} onClose={closeModal}>
        <Modal.Body>
          <FormattedMessage id={'order.reconfirmAmount'} values={{ amount: totalCost }} />
        </Modal.Body>

        <Modal.Footer>
          <Modal.Button variant={ButtonVariant.ERROR} onClick={() => reconfirm(orderId)} loading={reconfirmLoading}>
            <FormattedMessage id={'order.alertPaymentDebtAction'} />
          </Modal.Button>
        </Modal.Footer>
      </Modal>
    </Alert>
  );
};
