import React from 'react';

type ArticleRendererProps = {
  htmlContent: string;
};

// TODO safe plugin
export const ArticleRenderer = ({ htmlContent }: ArticleRendererProps) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};
