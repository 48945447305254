import { setDownloadLink } from '../../../../common/utils/setDownloadLink';
import { handleDefaultError } from '../../../../common/utils/handleDefaultError';
import { useFetchWithTokenRefresh } from '../../../../common/hooks/useFetchWithTokenRefresh';
import { externalApiUrl } from '../../../../config';

export type DownloadInvoice = {
  download: () => Promise<void>;
};

export const useDownloadInvoice = (orderId: number = 0, orderNumber: string = ''): DownloadInvoice => {
  const { fetchWithTokenRefresh } = useFetchWithTokenRefresh();

  const requestBody = {
    orderId
  };

  const download = async (): Promise<void> => {
    try {
      const response = await fetchWithTokenRefresh(`${externalApiUrl}/invoice`, requestBody);
      if (!response.ok) {
        return handleDefaultError(`${response.statusText}. Try again later!`);
      }
      await setDownloadLink(response, `invoice-${orderNumber}.pdf`);
    } catch (error) {
      handleDefaultError('Something went wrong. Try again later!');
    }
  };

  return { download };
};
