import React from 'react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { RatingInput, RatingValueEnum, useRateCustomerMutation, useRateSellerMutation } from 'store/graphql';
import { isKeyDownEnter } from 'common/utils/isKeyDownEnter';
import { FormInput } from 'common/components/inputs';
import { EBreakpoints, useBreakpoint } from 'common/hooks/useBreakpoint';
import { clearCache } from 'app/providers/auth-apollo';
import { getGraphqlErrorMessage } from 'common/utils/graphqlErrors';
import { handleDefaultError } from 'common/utils/handleDefaultError';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { Modal, ModalProps } from 'common/components/Modal/Modal';
import { ReactFCC } from 'common/utils/helperTypes';
import { ratings, TRating } from 'common/constants/rating';
import { useIntl } from 'react-intl';
import { commentaryMaxLength, OrderReviewKeys, OrderReviewSchema, TOrderReviewValues } from './schema';
import s from './OrderPageReviewModal.module.scss';

export type TOrderPageReviewModal = ModalProps & {
  isSeller: boolean;
  orderId: number;
};

const mutationOptions = {
  refetchQueries: ['CheckCustomerRateForOrder', 'CheckSellerRateForOrder'],
  update: clearCache(['checkCustomerRateForOrder', 'checkSellerRateForOrder'])
};

export const OrderPageReviewModal: ReactFCC<TOrderPageReviewModal> = (props) => {
  const { isSeller, onClose, orderId } = props;
  const intl = useIntl();

  const isMobile = useBreakpoint(EBreakpoints.LG_DOWN);

  const [rateSellerMutation, { loading: rateSellerLoading }] = useRateSellerMutation(mutationOptions);

  const [rateCustomerMutation, { loading: rateCustomerLoading }] = useRateCustomerMutation(mutationOptions);

  const formik = useFormik<TOrderReviewValues>({
    initialValues: {
      ratingValue: '',
      commentary: ''
    },
    validationSchema: OrderReviewSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, formikHelpers) => {
      const rateInput: RatingInput = {
        ratingValue: values.ratingValue as RatingValueEnum,
        commentary: values.commentary,
        orderId
      };

      try {
        if (isSeller) {
          await rateCustomerMutation({
            variables: {
              rateInput
            }
          });
        } else {
          await rateSellerMutation({
            variables: {
              rateInput
            }
          });
        }

        formikHelpers.resetForm();

        onClose?.();

        toast.success(intl.formatMessage({ id: 'order.reviewSubmitted' }));
      } catch (e) {
        const errorMessage = getGraphqlErrorMessage(e);

        if (errorMessage) {
          handleDefaultError(errorMessage, e);
        }
      }
    }
  });

  const loading = rateSellerLoading || rateCustomerLoading;

  return (
    <Modal {...props}>
      <Modal.Body>
        {isSeller ? (
          <div>Once you send a review, it will be displayed on the buyer's public page</div>
        ) : (
          <div>Once you send a review, it will be displayed on the seller's public page</div>
        )}

        <Space size={ESpaceSize.PIXEL_16} />

        <div>Rate the {isSeller ? 'buyer' : 'seller'}</div>

        <Space size={ESpaceSize.PIXEL_8} />

        <div className={s.OrderPageReviewModal__buttons}>
          {ratings.map((rating, index) => (
            <div
              className={clsx(
                s.OrderPageReviewModal__button,
                rating.variant === formik.values.ratingValue && s.OrderPageReviewModal__button_active
              )}
              onClick={() => formik.setFieldValue(OrderReviewKeys.ratingValue, rating.variant)}
              onKeyDownCapture={(e) => {
                if (isKeyDownEnter(e)) {
                  formik.setFieldValue(OrderReviewKeys.ratingValue, rating.variant);
                }
              }}
              tabIndex={0}
              key={index}
            >
              <IconFactory
                active={rating.variant === formik.values.ratingValue}
                defaultIcon={rating.defaultIcon}
                activeIcon={rating.activeIcon}
                variant={rating.variant}
              />
            </div>
          ))}
        </div>

        <Space size={ESpaceSize.PIXEL_8} />

        {formik.errors.ratingValue && <div className="text-danger">{formik.errors.ratingValue}</div>}

        <FormInput
          as={'textarea'}
          name={OrderReviewKeys.commentary}
          label={`Tell about the ${isSeller ? 'buyer' : 'seller'}`}
          placeholder={`Tell about the ${isSeller ? 'buyer' : 'seller'}`}
          onChange={formik.handleChange}
          value={formik.values.commentary}
          error={formik.errors.commentary}
          space={{ top: isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16 }}
          help={`${formik.values.commentary?.length || 0} / ${commentaryMaxLength}`}
          maxLength={commentaryMaxLength}
        />
      </Modal.Body>

      <Modal.Footer>
        <Modal.Button onClick={formik.submitForm} loading={loading}>
          Leave review
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
};

type TIconFactory = TRating & {
  active: boolean;
};

const IconFactory: ReactFCC<TIconFactory> = ({ active, defaultIcon: IconDefault, activeIcon: IconActive, variant }) => {
  const className = clsx(
    s.OrderPageReviewModal__buttonIcon,
    s[`OrderPageReviewModal__buttonIcon_${variant.toLowerCase()}`]
  );

  return active ? (
    <IconActive className={clsx(className, s.OrderPageReviewModal__buttonIcon_active)} />
  ) : (
    <IconDefault className={className} />
  );
};
