import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Heading, HeadingSize } from 'common/components/Heading';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { Grid, GridGap } from 'common/components/Grid/Grid';
import {
  ProductStatusEnum,
  Rating as RatingType,
  RatingValueEnum,
  Review,
  ReviewsUserRoleEnum,
  useReviewsCountQuery,
  useReviewsQuery,
  useSellerProductsQuery
} from 'store/graphql';
import { ReactFCC } from 'common/utils/helperTypes';

import { FormattedMessage } from 'react-intl';
import { LoaderBox } from '../../../common/components/Loader';
import { TProductProductsType } from '../../product/content/products/ProductProducts';
import { Button, ButtonFit, ButtonVariant } from '../../../common/components/Button';
import { ProductsGrid } from '../../../widgets/product';
import { useIsMobile } from '../../../common/hooks/useIsMobile';
import { UserReview } from './review/UserReview';
import { ReviewFilters } from './review/reviewFilters/ReviewFilters';
import s from './UserPageContent.module.scss';

export type TReviews =
  | Pick<
      Review,
      | 'commentary'
      | 'customerNickname'
      | 'sellerNickname'
      | 'sellerId'
      | 'customerId'
      | 'value'
      | 'createdAt'
      | 'reviewFrom'
    >[]
  | null;

export type TUserPageContent = {
  nickname: string;
  customerRating?: RatingType;
  sellerRating?: RatingType;
  id: number;
  isSeller?: boolean;
  isTrusted?: boolean;
  avatarUrl?: string;
  reviews?: TReviews;
  reviewsLoading?: boolean;
  ratingTypes?: RatingValueEnum[];
  reviewer?: ReviewsUserRoleEnum | null;
  chooseRatingTypes?: (ratingTypes: RatingValueEnum) => void;
  chooseReviewer?: (reviewer: ReviewsUserRoleEnum) => void;
};
export const DEFAULT_PUBLIC_PAGE_LIMIT = 4;
export const DEFAULT_PUBLIC_PAGE_REVIEWS_LIMIT = 100;
export const UserPageContent: ReactFCC<TUserPageContent> = (props) => {
  const { isSeller, id } = props;

  const isMobile = useIsMobile();
  const [ratingTypes, setRatingTypes] = useState<RatingValueEnum[]>([]);
  const [reviewFrom, setReviewFrom] = useState<ReviewsUserRoleEnum[]>([]);
  const [productsLimit, setProductsLimit] = useState<number>(DEFAULT_PUBLIC_PAGE_LIMIT);
  const [reviewsLimit, setReviewsLimit] = useState<number>(DEFAULT_PUBLIC_PAGE_LIMIT);
  const [filteredReviews, setFilteredReviews] = useState<TReviews | undefined>([]);

  const baseReviewsFilters = useMemo(
    () => ({
      userId: !isSeller ? id : null,
      sellerId: isSeller ? id : null
    }),
    [isSeller, id]
  );

  const baseReviewsCountFilters = useMemo(
    () => ({
      userId: !isSeller ? id : null,
      sellerId: isSeller ? id : null
    }),
    [isSeller, id]
  );

  const baseProductsInput = useMemo(
    () => ({
      filters: {
        sellerId: id,
        status: ProductStatusEnum.ForSale
      }
    }),
    [id]
  );

  const {
    data: reviewsQuery,
    loading: reviewsLoading,
    fetchMore: fetchMoreReviews
  } = useReviewsQuery({
    skip: !id,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
    variables: {
      input: {
        filters: {
          ...baseReviewsFilters
        },
        pagination: {
          limit: DEFAULT_PUBLIC_PAGE_REVIEWS_LIMIT,
          offset: 0
        }
      }
    }
  });

  const { data: reviewsCountQuery } = useReviewsCountQuery({
    skip: !id,
    fetchPolicy: 'cache-first',
    variables: {
      input: {
        filters: {
          ...baseReviewsCountFilters
        }
      }
    }
  });

  const {
    data: sellerProductsQuery,
    loading: sellerProductsLoading,
    fetchMore: fetchMoreProducts
  } = useSellerProductsQuery({
    skip: !id,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        ...baseProductsInput,
        pagination: {
          limit: DEFAULT_PUBLIC_PAGE_LIMIT,
          offset: 0
        }
      }
    }
  });

  useLayoutEffect(() => {
    if (productsLimit !== DEFAULT_PUBLIC_PAGE_LIMIT) {
      fetchMoreProducts({
        variables: {
          input: {
            ...baseProductsInput,
            pagination: {
              limit: productsLimit,
              offset: 0
            }
          }
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prevResult;
          return {
            products: {
              entries: [...fetchMoreResult.products.entries],
              pagination: fetchMoreResult.products.pagination
            }
          };
        }
      });
    }
  }, [baseProductsInput, productsLimit, fetchMoreProducts]);

  const reviews = reviewsQuery?.reviews.reviews?.entries;
  const reviewsCount = reviewsCountQuery?.count.reviews?.totalReviewsByFilter;
  const reviewsPagination = reviewsQuery?.reviews.reviews?.pagination;
  const sellerProducts = sellerProductsQuery?.products?.entries;
  const sellerProductsPagination = sellerProductsQuery?.products.pagination;

  const chooseRatingTypes = (ratingType: RatingValueEnum) => {
    const ratingEnabled = ratingTypes.includes(ratingType);
    if (ratingEnabled) {
      setRatingTypes((prev) => prev.filter((rating) => rating !== ratingType));
    } else {
      setRatingTypes((prev) => [...prev, ratingType]);
    }
  };

  const chooseReviewers = (newReviewer: ReviewsUserRoleEnum) => {
    const reviewerEnabled = reviewFrom.includes(newReviewer);
    if (reviewerEnabled) {
      setReviewFrom((prev) => prev.filter((reviewer) => reviewer !== newReviewer));
    } else {
      setReviewFrom((prev) => [...prev, newReviewer]);
    }
  };

  useLayoutEffect(() => {
    if (reviewsPagination && reviewsPagination.totalCount < DEFAULT_PUBLIC_PAGE_REVIEWS_LIMIT) {
      fetchMoreReviews({
        variables: {
          input: {
            filters: {
              ...baseReviewsFilters
            },
            pagination: {
              limit: reviewsLimit,
              offset: 0
            }
          }
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (fetchMoreResult.reviews?.reviews) {
            return {
              reviews: {
                reviews: {
                  entries: fetchMoreResult.reviews.reviews.entries,
                  pagination: fetchMoreResult.reviews.reviews.pagination,
                  totalReviewsByFilter: fetchMoreResult.reviews.reviews.totalReviewsByFilter
                }
              }
            };
          }
          return prevResult;
        }
      });
    }
  }, [baseReviewsFilters, reviewsLimit, fetchMoreReviews, reviewsPagination]);

  useEffect(() => {
    setFilteredReviews(reviews);
    if (ratingTypes && ratingTypes.length > 0) {
      setFilteredReviews((prev) => prev?.filter((review) => ratingTypes.includes(review.value)));
    }
    if (reviewFrom && reviewFrom.length > 0) {
      setFilteredReviews((prev) => prev?.filter((review) => reviewFrom.includes(review.reviewFrom)));
    }
  }, [reviews, ratingTypes, reviewFrom]);

  useLayoutEffect(() => {
    if (
      isSeller &&
      ratingTypes &&
      ratingTypes.length > 0 &&
      [RatingValueEnum.Neutral, RatingValueEnum.Negative, RatingValueEnum.Positive].every((value) =>
        ratingTypes.includes(value)
      ) &&
      reviewFrom &&
      reviewFrom.length > 0 &&
      [ReviewsUserRoleEnum.Customer, ReviewsUserRoleEnum.Seller].every((role) => reviewFrom.includes(role))
    ) {
      setRatingTypes([]);
      setReviewFrom([]);
    }
  }, [reviewFrom, ratingTypes, isSeller]);

  useLayoutEffect(() => {
    if (
      !isSeller &&
      ratingTypes &&
      ratingTypes.length > 0 &&
      [RatingValueEnum.Neutral, RatingValueEnum.Negative, RatingValueEnum.Positive].every((value) =>
        ratingTypes.includes(value)
      )
    ) {
      setRatingTypes([]);
    }
  }, [ratingTypes, isSeller]);

  const overallCount = useMemo(() => {
    if (reviewsCount) {
      return reviewsCount.negative + reviewsCount.neutral + reviewsCount.positive;
    }
  }, [reviewsCount]);

  const [currentCount, setCurrentCount] = useState(0);
  useLayoutEffect(() => {
    setCurrentCount(overallCount || 0);
    if (reviewsCount) {
      let count = 0;
      if (ratingTypes.length > 0) {
        for (let rating of ratingTypes) {
          count += reviewsCount[rating.toLowerCase() as keyof typeof reviewsCount]
            ? +reviewsCount[rating.toLowerCase() as keyof typeof reviewsCount]!
            : 0;
        }
        setCurrentCount(count);
      }
      if (!ratingTypes.length && reviewFrom.length > 0) {
        for (let reviewer of reviewFrom) {
          count += reviewsCount[reviewer.toLowerCase() as keyof typeof reviewsCount]
            ? +reviewsCount[reviewer.toLowerCase() as keyof typeof reviewsCount]!
            : 0;
        }
        setCurrentCount(count);
      }
      if (ratingTypes.length > 0 && reviewFrom.length > 0 && filteredReviews) {
        setCurrentCount(filteredReviews?.length);
      }
    }
  }, [currentCount, ratingTypes, reviewFrom, setCurrentCount, filteredReviews, reviewsCount, overallCount]);

  return (
    <div className={s.UserPageContent}>
      <Heading size={HeadingSize.H4}>
        <FormattedMessage id={'reviews.title'} />
      </Heading>

      <Space size={isMobile ? ESpaceSize.PIXEL_16 : ESpaceSize.PIXEL_24} />

      {reviewsCount && (
        <ReviewFilters
          ratingTypes={ratingTypes}
          reviewFrom={reviewFrom}
          chooseRatingTypes={chooseRatingTypes}
          chooseReviewer={chooseReviewers}
          reviewsCount={reviewsCount}
          isSeller={isSeller}
        />
      )}

      <Space size={ESpaceSize.PIXEL_24} />

      <Grid gap={GridGap.SMALL}>
        {filteredReviews && filteredReviews?.length > 0 && reviews && reviews.length > 0 ? (
          filteredReviews?.slice(0, reviewsLimit).map((review, index) => (
            <Grid.GridItem cols={{ xs: 2, lg: 6 }} key={index}>
              <UserReview
                role={review.reviewFrom}
                comment={{
                  authorId: review.reviewFrom === ReviewsUserRoleEnum.Seller ? review.sellerId! : review.customerId!,
                  author:
                    review.reviewFrom === ReviewsUserRoleEnum.Seller
                      ? review.sellerNickname!
                      : review.customerNickname!,
                  date: review.createdAt,
                  text: review.commentary || '',
                  value: review.value
                }}
              />
            </Grid.GridItem>
          ))
        ) : reviewsLoading ? (
          <Grid.GridItem>
            <LoaderBox />
          </Grid.GridItem>
        ) : (
          <Grid.GridItem cols={{ xs: 2, lg: 6 }}>
            <span className={s.UserPageContent__noReviewsHeading}>
              <FormattedMessage id={'reviews.empty'} />
            </span>
          </Grid.GridItem>
        )}
      </Grid>

      <Space size={ESpaceSize.PIXEL_24} />

      {filteredReviews &&
        reviewsPagination &&
        currentCount > filteredReviews.slice(0, reviewsLimit).length &&
        reviewsPagination.totalCount > reviewsLimit && (
          <Button
            variant={ButtonVariant.PRIMARY_OUTLINE}
            fit={isMobile ? ButtonFit.FILL : ButtonFit.FIT}
            loading={reviewsLoading}
            onClick={() => setReviewsLimit((prev) => prev + 10)}
          >
            <FormattedMessage id={'reviews.showMoreReviews'} />
          </Button>
        )}

      <Space size={ESpaceSize.PIXEL_24} />

      {isSeller && !!sellerProducts?.length && (
        <>
          <Heading size={HeadingSize.H4}>
            <FormattedMessage id={'product.productsTitle'} />
          </Heading>
          <Space size={ESpaceSize.PIXEL_24} />
          {!!sellerProducts.length ? (
            <ProductsGrid products={sellerProducts as TProductProductsType[]} />
          ) : (
            <LoaderBox />
          )}
          <Space size={ESpaceSize.PIXEL_24} />

          {sellerProducts &&
            sellerProductsPagination &&
            sellerProductsPagination?.totalCount > sellerProducts?.length && (
              <Button
                variant={ButtonVariant.PRIMARY_OUTLINE}
                fit={isMobile ? ButtonFit.FILL : ButtonFit.FIT}
                onClick={() => setProductsLimit((prev) => prev + 10)}
                loading={sellerProductsLoading}
              >
                <FormattedMessage id={'reviews.showMoreProducts'} />
              </Button>
            )}
        </>
      )}
    </div>
  );
};
