import { RouteObject } from 'react-router-dom';
import React from 'react';
import { NotFoundPage } from '../notFound/NotFoundPage';
import { ABOUT_DYNAMIC_ROUTE, ABOUT_ROUTE, LAW_ROUTE, withRoutes } from '../../common/utils/routes';
import { AboutLayout } from './_layout/AboutLayout';

import { LawPage } from './law/LawPage';
import { AboutPage } from './about/AboutPage';
const routes: RouteObject[] = [
  {
    element: <AboutLayout />,
    children: [
      {
        index: true,
        element: <AboutPage />
      },
      {
        path: ABOUT_DYNAMIC_ROUTE.replace(ABOUT_ROUTE, ''),
        element: <AboutPage />
      },
      {
        path: LAW_ROUTE.replace(ABOUT_ROUTE, ''),
        element: <LawPage />
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
    ]
  }
];

export const AboutRoutes = withRoutes(routes);
