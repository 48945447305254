import React from 'react';
import { useFormik } from 'formik';
import { useUpdateEffect } from 'react-use';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';
import { isMobile } from 'react-device-detect';
import clsx from 'clsx';
import { Form } from 'react-bootstrap';
import { FormInput, FormSelect } from '../../../../../common/components/inputs';
import { ProductQuery, useComplaintTypesQuery, useCreateProductComplaintMutation } from '../../../../../store/graphql';
import { ExtractArray, ReactFCC } from '../../../../../common/utils/helperTypes';
import { getGraphqlErrorMessage } from '../../../../../common/utils/graphqlErrors';
import { Modal } from '../../../../../common/components/Modal';
import { ESpaceSize } from '../../../../../common/components/Space/Space';
import { handleDefaultError } from '../../../../../common/utils/handleDefaultError';
import { ProductComplaintCard, TProductComplaintType } from '../../../../../entities/product';
import { Divider, EDividerVariant } from '../../../../../common/components/Divider/Divider';
import { ButtonVariant } from '../../../../../common/components/Button';
import { maxComplaintCommentLength } from '../../../../../widgets/auth/components';
import { useAuth } from '../../../../../app/providers/auth-apollo';
import { ComplaintModalKeys, ComplaintModalSchema, TComplaintModalValues } from './complaintModalSchema';
import s from './ComplaintModal.module.scss';

export interface TComplaintModal {
  /**
   * Состояние открытого/закрытого модального окна
   */
  isOpen: boolean;
  /**
   * Действие при закрытии модального окна
   */
  onClose: () => void;
  /**
   * Товар передаваемый в компонент для отрисовки карточки товара внутри
   */
  product: ExtractArray<ProductQuery['marketPlace']['products']['entries']>;
  /**
   * Начальные значения в инпутах
   */
  initialValues?: TComplaintModalValues;
  /**
   * Опциональный дополнительный класс на компонент
   */
  className?: string;
}

export const ComplaintModal: ReactFCC<TComplaintModal> = (props: TComplaintModal) => {
  const { isOpen, onClose, initialValues, product, className } = props;

  const productForCard: TProductComplaintType = {
    name: product.name,
    price: product.price,
    mainPhotoUrl: product.mainPhoto?.thumbnails?.M?.url || undefined
  };

  const intl = useIntl();

  const { isAuthenticated } = useAuth();

  const [createProductComplaint] = useCreateProductComplaintMutation();

  const { data: complaintsQuery, loading: complaintsLoading } = useComplaintTypesQuery();
  const reasons = complaintsQuery?.complaintTypes.productComplaintTypes.map(({ uid, name }) => ({
    title: (
      <div>
        <span>{name}</span>
      </div>
    ),
    value: uid
  }));

  const formik = useFormik<TComplaintModalValues>({
    initialValues: {
      reasonId: initialValues?.reasonId || '',
      commentary: initialValues?.commentary || ''
    },
    validationSchema: ComplaintModalSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, helpers) => {
      try {
        if (!isAuthenticated) {
          return handleDefaultError(intl.formatMessage({ id: 'product.complaintModalSignInError' }));
        }

        const success = await createProductComplaint({
          variables: {
            input: {
              complaintTypeUid: values.reasonId,
              commentary: values.commentary,
              productId: product.id
            }
          }
        });
        helpers.resetForm();
        if (success) {
          toast.success(intl.formatMessage({ id: 'product.complaintModalSuccess' }));
          closeForm();
        }
      } catch (e: any) {
        const errorText = getGraphqlErrorMessage(e);
        if (errorText) {
          handleDefaultError(errorText);
        }
      }
    }
  });

  useUpdateEffect(() => {
    if (initialValues) {
      formik.setValues(initialValues);
    }
  }, [initialValues]);

  const closeForm = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Modal
      className={clsx(className)}
      title={intl.formatMessage({ id: 'product.complaintModalTitle' })}
      isOpen={isOpen}
      onClose={closeForm}
    >
      <Modal.Body>
        <ProductComplaintCard product={productForCard} />
        <Divider variant={EDividerVariant.DEFAULT} />
        <FormSelect
          name={ComplaintModalKeys.reasonId}
          label={intl.formatMessage({ id: 'product.complaintModalReason' })}
          defaultOption={{
            title: intl.formatMessage({ id: 'product.complaintModalDefaultOption' })
          }}
          classes={{
            option: s.ComplaintModal__reasonOption,
            root: s.ComplaintModal__reasonDefaultOption,
            label: s.ComplaintModal__reasonLabel
          }}
          options={reasons || []}
          onItemSelect={(option) => formik.setFieldValue(ComplaintModalKeys.reasonId, option.value)}
          value={formik.values.reasonId}
          error={formik.errors.reasonId}
          space={isMobile ? ESpaceSize.PIXEL_8 : ESpaceSize.PIXEL_16}
          loading={complaintsLoading}
          required
        />

        <FormInput
          as={'textarea'}
          name={ComplaintModalKeys.commentary}
          label={intl.formatMessage({ id: 'product.complaintModalCommentLabel' })}
          placeholder={intl.formatMessage({ id: 'product.complaintModalCommentPlaceholder' })}
          classes={{ input: s.ComplaintModal__textArea }}
          value={formik.values.commentary}
          onChange={formik.handleChange}
          error={formik.errors.commentary}
          space={{ bottom: ESpaceSize.PIXEL_6 }}
          maxLength={maxComplaintCommentLength}
        />
        <Form.Text className={s.ComplainModal__formTip} muted>
          {formik.values.commentary?.length} / {maxComplaintCommentLength}
        </Form.Text>
      </Modal.Body>

      <Modal.Footer className={s.ComplaintModal__footer}>
        <Modal.Button onClick={formik.submitForm} loading={formik.isSubmitting}>
          <FormattedMessage id={'product.complaintModalSendButton'} />
        </Modal.Button>
        <Modal.Button onClick={closeForm} variant={ButtonVariant.SECONDARY}>
          <FormattedMessage id={'general.back'} />
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
};
