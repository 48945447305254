import React from 'react';
import { useFormik } from 'formik';
import { CreateChatWithSellerInput, useCreateChatWithSellerMutation } from 'store/graphql';
import { FormInput } from 'common/components/inputs';
import { EBreakpoints, useBreakpoint } from 'common/hooks/useBreakpoint';
import { getGraphqlErrorMessage } from 'common/utils/graphqlErrors';
import { handleDefaultError } from 'common/utils/handleDefaultError';
import { Modal, ModalProps } from 'common/components/Modal/Modal';
import { ReactFCC } from 'common/utils/helperTypes';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { PathBuilder } from '../../../../../common/utils/routes';
import {
  firstMessageMaxLength,
  OrderSellerTicketKeys,
  OrderSellerTicketSchema,
  TOrderSellerTicketValues
} from './schema';

import s from './OrderSellerTicketModal.module.scss';

export type TOrderSellerTicketModal = ModalProps & {
  sellerId: number;
  orderID: string;
  orderId: number;
};

export const OrderSellerTicketModal: ReactFCC<TOrderSellerTicketModal> = (props) => {
  const { onClose, orderID, sellerId, orderId } = props;
  const intl = useIntl();

  const isMobile = useBreakpoint(EBreakpoints.LG_DOWN);

  const navigate = useNavigate();

  const [createChatWithSeller, { loading: createChatWithSellerLoading }] = useCreateChatWithSellerMutation();

  const formik = useFormik<TOrderSellerTicketValues>({
    initialValues: {
      firstMessage: ''
    },
    validationSchema: OrderSellerTicketSchema,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, formikHelpers) => {
      const chatInput: CreateChatWithSellerInput = {
        firstMessage: values.firstMessage,
        sellerId: sellerId,
        orderID: orderID,
        orderId: orderId
      };

      try {
        const result = await createChatWithSeller({
          variables: {
            input: chatInput
          }
        });

        if (result?.data?.ticket) {
          navigate(PathBuilder.getTicketRequestPath(result?.data?.ticket));
          formikHelpers.resetForm();
          onClose?.();
        }
      } catch (e) {
        const errorMessage = getGraphqlErrorMessage(e);

        if (errorMessage) {
          handleDefaultError(errorMessage, e);
        }
      }
    }
  });

  return (
    <Modal {...props}>
      <Modal.Body>
        <FormInput
          as={'textarea'}
          classes={{ input: s.OrderSellerTicketModal__input }}
          name={OrderSellerTicketKeys.firstMessage}
          label={intl.formatMessage({ id: 'order.customerTicketDesc' })}
          placeholder={intl.formatMessage({ id: 'order.customerTicketDesc' })}
          onChange={formik.handleChange}
          value={formik.values.firstMessage}
          error={formik.errors.firstMessage}
          help={`${formik.values.firstMessage?.length || 0} / ${firstMessageMaxLength}`}
          maxLength={firstMessageMaxLength}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.Button onClick={formik.submitForm} loading={createChatWithSellerLoading}>
          <FormattedMessage id={'general.send'} />
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
};
