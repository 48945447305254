import React from 'react';
import { format } from 'date-fns';
import { ja, enUS } from 'date-fns/locale';
import { useLang } from '../../../../store/intl';
import { AvailableLanguagesEnum } from '../../../../store/graphql';
export interface FormattedDateProps {
  value?: string | number | Date;
  format?: string;
}

const defaultFormat = 'yyyy.MM.dd';

export const formatArisoraLocales = {
  [AvailableLanguagesEnum.English]: enUS,
  [AvailableLanguagesEnum.Japanese]: ja
};

export const FormattedDate: React.FC<FormattedDateProps> = (props) => {
  const { value: valueProp, format: formatProp = defaultFormat } = props;
  const { language } = useLang();
  let formatSpec = formatProp;

  const value = typeof valueProp === 'string' ? new Date(valueProp) : valueProp;

  if (!value) {
    return null;
  }

  if (formatProp === 'LLL dd Y, HH:mm' && language === AvailableLanguagesEnum.Japanese) {
    formatSpec = 'Yo LLL do, HH:mm';
  }

  return <>{format(value, formatSpec, { locale: formatArisoraLocales[language] })}</>;
};
