import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Pagination, usePagination } from 'common/components/Pagination';
import { LoaderBox } from 'common/components/Loader/LoaderBox';
import { ESpaceSize, Space } from 'common/components/Space/Space';
import { Divider, EDividerVariant } from 'common/components/Divider/Divider';
import { useWalletTransactionsQuery } from 'store/graphql';
import { DEFAULT_PAGE_LIMIT } from 'common/components/Pagination';
import { WalletOperationsCell, WalletOperationsGrid, WalletOperationsRow } from '../WalletOperationsGrid';
import { WalletNoItems } from '../WalletNoItems';
import { Transaction } from './WalletTransaction';
import s from './WalletTransactions.module.scss';

export interface WalletTransactionsProps {
  isMobile: boolean;
}

export const WalletTransactions = ({ isMobile }: WalletTransactionsProps) => {
  const intl = useIntl();

  const { offset, setOffset } = usePagination();

  const { data, loading } = useWalletTransactionsQuery({
    variables: {
      input: {
        pagination: {
          limit: DEFAULT_PAGE_LIMIT,
          offset
        }
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const transactions = data?.transactions.data;
  const pagination = data?.transactions.pagination;

  // useUpdatePageCount(pagination?.pagesCount);

  return loading ? (
    <LoaderBox />
  ) : transactions?.length !== 0 ? (
    <>
      <WalletOperationsGrid className={s.WalletTransactions}>
        {!isMobile && (
          <>
            <WalletOperationsRow className={s.WalletTransactions__row} head>
              <WalletOperationsCell>
                <FormattedMessage id="profile.walletOpDate" />
              </WalletOperationsCell>
              <WalletOperationsCell>
                <FormattedMessage id="profile.walletOpTime" />
              </WalletOperationsCell>
              <WalletOperationsCell>
                <FormattedMessage id="profile.walletOpType" />
              </WalletOperationsCell>
              <WalletOperationsCell>
                <FormattedMessage id="profile.walletOpText" />
              </WalletOperationsCell>
              <WalletOperationsCell smallPadding>
                <FormattedMessage id="profile.walletOpTransactionAmount" />
              </WalletOperationsCell>
              <WalletOperationsCell smallPadding>
                <FormattedMessage id="profile.walletOpBalance" />
              </WalletOperationsCell>
            </WalletOperationsRow>
            <Divider variant={EDividerVariant.LIGHT} />
          </>
        )}

        {transactions?.map((item, iItem) => (
          <Transaction key={iItem} rowClassName={s.WalletTransactions__row} {...item} isMobile={isMobile} />
        ))}
      </WalletOperationsGrid>

      <Space size={isMobile ? ESpaceSize.PIXEL_24 : ESpaceSize.PIXEL_48} />

      {pagination && (
        <Pagination
          limit={pagination.limit}
          offset={offset}
          setOffset={setOffset}
          totalCount={pagination.totalCount}
          totalCountText={intl.formatMessage(
            { id: 'profile.walletTotalTransactionsCount' },
            { count: pagination.totalCount }
          )}
        />
      )}
    </>
  ) : (
    <WalletNoItems>{intl.formatMessage({ id: 'wallet.noTransactions' })}</WalletNoItems>
  );
};
