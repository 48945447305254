import { useNavigationQuery } from '../../../../../store/graphql';

export interface NavigationCategoryItem {
  id: number;
  name: string;
  subCategories: {
    id: number;
    name: string;
    subSubCategories: {
      id: number;
      name: string;
    }[];
  }[];
}

export const useNavigationItems = (): NavigationCategoryItem[] | undefined => {
  // todo переделать квери и логику, чтобы вложенные категории запрашивались
  //  только при выборе родительской
  const { data } = useNavigationQuery();

  const categories = data?.marketPlace.categories || [];

  return categories.map((category) => ({
    id: category.id,
    name: category.name,
    subCategories:
      category.children?.map((subCategory) => ({
        id: subCategory.id,
        name: subCategory.name,
        subSubCategories:
          subCategory.children?.map((subSubCategory) => ({
            id: subSubCategory.id,
            name: subSubCategory.name
          })) || []
      })) || []
  }));
};
