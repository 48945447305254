import { ReactFCC } from 'common/utils/helperTypes';
import { useCatalogColorsQuery } from 'store/graphql';
import { useIntl } from 'react-intl';
import { BaseCatalogFilter, BaseCatalogFilterProps } from '../../CatalogFilter';

export type CatalogFilterColorProps = Pick<BaseCatalogFilterProps, 'className' | 'classes' | 'active' | 'setActive'>;

export const CatalogFilterColor: ReactFCC<CatalogFilterColorProps> = (props) => {
  const intl = useIntl();

  const { data } = useCatalogColorsQuery();

  const items =
    data?.colors.map((c) => ({
      id: c.id.toString(),
      name: c.name,
      colorCode: c.colorCode
    })) || [];

  return <BaseCatalogFilter title={intl.formatMessage({ id: 'catalog.color' })} items={items} {...props} />;
};
